<template>
  <router-link v-slot="{ href, navigate, isActive, isExactActive }" :to="to">
    <li aria-haspopup="true" class="menu-item"
      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
      <a :href="href" class="menu-link" @click="navigate">
        <span v-if="icon && !hasDot" class="svg-icon menu-icon">
          <inline-svg :src="`/media/svg/icons/${icon}`" />
        </span>
        <i v-if="hasDot" class="menu-bullet menu-bullet-dot"><span></span></i>
        <span class="menu-text" :class="{ '': vertical }">{{ label }}</span>
      </a>
    </li>
  </router-link>
</template>

<style lang="scss" scoped>
.menu-nav-label {
  font-size: 0.9rem;
}
</style>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    menuToggle: {
      type: String,
      default: "hover",
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    hasDot: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    whiteIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
