var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wizard-step",
    attrs: {
      "data-wizard-type": "step"
    }
  }, [_c('div', {
    staticClass: "wizard-label"
  }, [_c('i', {
    staticClass: "wizard-icon",
    class: _vm.icon
  }), _c('h3', {
    staticClass: "wizard-title"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('i', {
    staticClass: "wizard-arrow flaticon2-next"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }