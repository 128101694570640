<template>
  <div :class="{ 'form-group row': parentClass, validated: !valid }">
    <label v-if="label" class="label-text col-form-label"
      :class="{ 'col-xl-3 col-lg-3': horizontal, 'col-12': !horizontal, 'disabled': disabled }">
      <slot name="label">
        {{ label }}
      </slot>
      <span v-if="helpText" data-toggle="tooltip" class="ml-2" :title=helpText>
        <i class="fa fa-sm fa-info-circle text-primary"></i>
      </span>
    </label>
    <div :class="{ 'col-lg-9 col-xl-9': horizontal, 'col-12': !horizontal }">
      <div class="d-flex align-items-center">
        <slot name="prepend"><span v-if="prependText" class="mr-2">{{ prependText }}</span></slot>
        <input v-model="vModel" :type="type" class="form-control form-control-solid input-text"
          :class="{ 'is-invalid': !valid, 'fake-input': fakeInput, ...className }" :placeholder="placeholder"
          :step="step" :min="min" :max="max" :readOnly="fakeInput" :disabled="disabled" :maxlength="maxlength" />
        <slot name="append"><span v-if="appendText" class="ml-2">{{ appendText }}</span></slot>
      </div>
      <div v-if="!valid" class="invalid-feedback d-block my-2" v-text="showErrors"></div>
      <span v-if="hint" class="form-text text-muted">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  name: "EuroInput",

  mixins: [BFormMixin],

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: Object,
      default: () => ({}),
    },
    debounce: {
      type: Number,
      default: 0,
    },
    parentClass: {
      type: Boolean,
      default: true,
    },
    parentClassName: {
      type: String,
      default: "",
    },
    fakeInput: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: "",
    },
    step: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    appendText: {
      type: String,
      default: "",
    },
    prependText: {
      type: String,
      default: "",
    },
  },

  computed: {
    showErrors() {
      let errors = [];
      this.errorMessages.map((error) => {
        errors.push(error);
      });

      return errors.join("\n");
    },
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.fake-input {
  background-color: white;
  border: none;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-style: italic;

  &:focus {
    background-color: white;
  }
}

input.input-text.form-control.form-control-solid:disabled,
label.label-text.col-form-label.disabled {
  opacity: .6;
  color: #3f425470;
}
</style>