import PostCategoryService from "@/core/services/post/post-category.service";

function getItemsWithChildren(arr) {
  const itemsMap = new Map();

  arr.forEach(item => {
    item.children = [];
    itemsMap.set(item.id, item);
  });

  arr.forEach(item => {
    if (item.parent_id) {
      const parentItem = itemsMap.get(item.parent_id);
      if (parentItem) {
        parentItem.children.push(item);
      }
    }
  });

  return arr.filter(item => !item.parent_id);
}

const categories = {
  namespaced: true,
  state: {
    categoriesTree: [],
    categoriesRaw: []
  },
  mutations: {

  },
  actions: {
    async getCategories({
      state
    }) {
      await PostCategoryService.getAllNotPaginated({
        tree_view: true
      }).then(res => {
        state.categoriesRaw = res
        state.categoriesTree = getItemsWithChildren(res)
      }).catch(err => {
        console.log(err)
      })
    },
  },
  getters: {
    getCategoriesTree(state) {
      return state.categoriesTree
    },
    getCategoriesRaw(state) {
      return state.categoriesRaw
    }
  }
}

export default categories