var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "inline-block",
    attrs: {
      "id": _vm.btnRef
    }
  }, [_c('b-button', {
    ref: _vm.btnRef,
    staticClass: "btn-hover-primary btn-icon",
    attrs: {
      "size": _vm.size,
      "disabled": _vm.disabled,
      "pressed": _vm.pressed,
      "squared": _vm.squared
    },
    on: {
      "click": _vm.onBtnClick
    }
  }, [_c('i', {
    class: _vm.iconClasses
  })]), _vm.tooltip ? _c('b-tooltip', {
    attrs: {
      "target": _vm.btnRef,
      "noninteractive": ""
    }
  }, [_vm._v(_vm._s(_vm.tooltip))]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }