var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols": _vm.horizontal ? 3 : null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    staticClass: "form-control form-control-solid h-auto",
    attrs: {
      "type": "text",
      "placeholder": "YYYY-MM-DD",
      "autocomplete": "off"
    },
    model: {
      value: _vm.vModel,
      callback: function callback($$v) {
        _vm.vModel = $$v;
      },
      expression: "vModel"
    }
  }), _c('b-input-group-append', [_c('b-form-datepicker', {
    attrs: {
      "button-only": "",
      "right": "",
      "locale": "en-US",
      "min": _vm.minDate,
      "max": _vm.maxDate
    },
    on: {
      "context": _vm.onContext
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('i', {
          staticClass: "flaticon2-calendar-4"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.vModel,
      callback: function callback($$v) {
        _vm.vModel = $$v;
      },
      expression: "vModel"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }