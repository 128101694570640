var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('google-maps-autocomplete', _vm._b({
    attrs: {
      "error-messages": _vm.errorMessages,
      "autocomplete-options": {
        types: ['administrative_area_level_3', 'locality'],
        componentRestrictions: _vm.restriction
      }
    },
    model: {
      value: _vm.place,
      callback: function callback($$v) {
        _vm.place = $$v;
      },
      expression: "place"
    }
  }, 'google-maps-autocomplete', {
    hint: _vm.hint,
    label: _vm.label,
    'valid-feedback': _vm.validFeedback,
    state: _vm.state,
    'invalid-feedback': _vm.invalidFeedback,
    horizontal: _vm.horizontal
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }