var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "label-align": "right",
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols": _vm.horizontal ? 6 : null
    }
  }, [_c('b-form-checkbox', {
    staticClass: "d-flex align-items-center text-right",
    attrs: {
      "value": _vm.checkedValue,
      "unchecked-value": _vm.uncheckedValue,
      "switch": _vm.isSwitch,
      "disabled": _vm.disabled,
      "size": _vm.size
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', $event);
      }
    },
    model: {
      value: _vm.vModel,
      callback: function callback($$v) {
        _vm.vModel = $$v;
      },
      expression: "vModel"
    }
  }, [_vm._t("text", function () {
    return [_vm._v(" " + _vm._s(_vm.text) + " ")];
  }, {
    "text": _vm.text
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }