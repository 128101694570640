<template>
  <div class="wizard-step" data-wizard-type="step">
    <div class="wizard-label">
      <i class="wizard-icon" :class="icon"></i>
      <h3 class="wizard-title">{{ title }}</h3>
    </div>
    <i class="wizard-arrow flaticon2-next"></i>
  </div>
</template>

<style lang="scss" scoped>
.wizard-step:last-child i.wizard-arrow {
  display: none;
}
</style>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
