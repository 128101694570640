const notification = {
  namespaced: true,

  state: {
    totalUnread: 0,
    activityFeeds: [],
    notifications: [],
  },

  mutations: {
    SET_NOTIFICATION(state, notification) {
      // Se trova l'elemento nelle notifiche allora non deve inserirlo
      if (state.notifications.find(el => el.data.id == notification.data.id))
        return

      state.notifications.unshift(notification);
    },

    SET_NOTIFICATION_READ(state, id) {
      let notifyList = state.notifications.map(element => {
        if (element.data.id == id) {
          element.data.unread = false;
        }
        return element;
      });

      state.notifications = notifyList;
    },

    SET_ACTIVITY_FEED(state, activityFeed) {
      state.activityFeeds.push(activityFeed);
    },

    SET_TOTAL_UNREAD(state, totalUnread) {
      state.totalUnread = totalUnread;
    },
    SET_ALL_NOTIFICATIONS_READ(state) {
      let notifyList = state.notifications.map(element => {
        element.data.unread = false;
        return element;
      });

      state.notifications = notifyList;
    },
    reset: () => {}
  },

  actions: {
    setNotification({
      commit
    }, notification) {
      commit("SET_NOTIFICATION", notification);
    },

    setNotificationRead({
      commit
    }, id) {
      commit("SET_NOTIFICATION_READ", id);
    },

    setActivityFeed({
      commit
    }, activityFeed) {
      commit("SET_ACTIVITY_FEED", activityFeed);
    },

    setTotalUnread({
      commit
    }, totalUnread) {
      commit("SET_TOTAL_UNREAD", totalUnread);
    },

    setAllNotificationsRead({
      commit
    }) {
      commit("SET_ALL_NOTIFICATIONS_READ")
    }
  },

  getters: {
    getTotalUnread(state) {
      return state.totalUnread > 99 ? 99 : state.totalUnread;
    },

    hasNotification(state) {
      return state.totalUnread > 0;
    },

    getRiskLevel: state => limit => {
      let feeds = state.activityFeeds.filter(el => el.data.target_content_type_model == "country");
      if (limit) {
        return feeds.slice(0, limit);
      }
      return feeds;
    },
  },
};

export default notification;