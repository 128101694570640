import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import en from "@/core/config/i18n/en.json";
import it from "@/core/config/i18n/it.json";
import es from "@/core/config/i18n/es.json";
import fr from "@/core/config/i18n/fr.json";

Vue.use(VueI18n);

let messages = {
  en,
  it,
  es,
  fr,
};

// get current selected language
const lang = localStorage.getItem("language") || "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: "en",
  messages, // set locale messages
  silentTranslationWarn: true,
  separator: "::"
});

export default i18n;