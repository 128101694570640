<template>
  <b-form-group
    :description="hint"
    :label="label"
    label-align="right"
    :valid-feedback="validFeedback"
    :state="state"
    :invalid-feedback="invalidFeedback"
    :label-cols="horizontal ? 6 : null"
  >
    <b-form-checkbox
      v-model="vModel"
      :value="checkedValue"
      :unchecked-value="uncheckedValue"
      :switch="isSwitch"
      :disabled="disabled"
      :size="size"
      class="d-flex align-items-center text-right"
      @change="$emit('change', $event)"
    >
      <slot name="text" :text="text">
        {{ text }}
      </slot>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  name: "EuroCheckbox",

  mixins: [BFormMixin],

  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checkedValue: {
      type: [String, Number, Boolean],
      default: true,
    },
    uncheckedValue: {
      type: [String, Number, Boolean],
      default: false,
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
  },

  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
