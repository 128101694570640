import api from "../api.service";
import store from "@/core/services/store";

export default {
  get PATH() {
    return "notifications";
  },

  async getAll({
    perPage,
    page,
    sortBy = null,
    sortDesc = null,
    search = null,
    omit = null,
    dashboard = undefined,
    unread = false,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      omit,
      dashboard: dashboard != undefined,
      unread: unread
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  },

  async getReportsMap({
    perPage,
    page,
    sortBy,
    sortDesc,
    search,
    omit
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      omit
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    const res = await api.get(`${this.PATH}/`, {
      params,
    });

    return res.data;
  },

  async update(body, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, body);
    return res.data;
  },

  async options() {
    const res = await api.options(`${this.PATH}/`);
    store.commit("constants/SET_API_VERSION", res.headers["api-version"]);
    return res;
  },
};