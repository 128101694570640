import api from "../api.service";

class NationLanguageService {
  async getAll() {
    return await this.getPaginated(1000, 0);
  }

  async getAllByCountriesSpoken(countriesSpoken) {
    const res = await api.get("nations-language/", {
      params: {
        limit: 10000,
        offset: 0,
        countries_spoken: countriesSpoken
      },
    });
    return res.data;
  }

  async getPaginated(limit, offset) {
    const res = await api.get("nations-language/", {
      params: {
        limit,
        offset
      },
    });
    return res.data;
  }
}

export default new NationLanguageService();