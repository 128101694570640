var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wizard-step",
    attrs: {
      "data-wizard-type": "step"
    }
  }, [_c('div', {
    staticClass: "wizard-wrapper"
  }, [_vm.icon ? _c('div', {
    staticClass: "wizard-icon"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-2x"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/".concat(_vm.icon)
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "wizard-label"
  }, [_c('h3', {
    staticClass: "wizard-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.desc ? _c('div', {
    staticClass: "wizard-desc"
  }, [_vm._v(_vm._s(_vm.desc))]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }