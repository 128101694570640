export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: [String, Array],
      default: null,
    },
    validFeedback: {
      type: String,
      default: "",
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    state() {
      if (this.errorMessages === null || !this.errorMessages.length) {
        return null;
      }

      return false;
    },
    valid() {
      if (this.errorMessages === null || !this.errorMessages.length) {
        return true;
      }

      return false;
    },
    invalidFeedback() {
      if (!this.errorMessages || !this.errorMessages.length) return "";

      if (Array.isArray(this.errorMessages) && this.errorMessages.length) {
        return this.errorMessages[0];
      }

      return this.errorMessages;
    },
  },
};