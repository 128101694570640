import AddressService from "@/core/services/address.service";
const addresses = {
  namespaced: true,

  state: {
    apiAddresses: {},
  },

  mutations: {
    setLoading(state, val) {
      state.loading = val;
    },
    setApiAddress(state, val) {
      state.apiAddresses[val?.place_id] = val;
    },
    reset: () => {}
  },

  actions: {
    async getApiAddress({ commit }, id, loadFromService = true) {
      let apiAddr = id;
      if(loadFromService) {
        apiAddr = await AddressService.getByID(id);
      }
      const googleAddress = await AddressService.getGooglePlaceFromApi(apiAddr);
      commit("setApiAddress", googleAddress);
    },

    async getGoogleAddressFromApi({ commit }, address) {
      const googleAddress = await AddressService.getGooglePlaceFromApi(address);
      commit("setApiAddress", googleAddress);
    },
  },
};

export default addresses;
