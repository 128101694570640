import _ from "lodash";
import CompanyService from "../company/company.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import PhysicalPersonService from "../physical-person/physical-person.service";
import PhysicalPersonAddressService from "../physical-person/physical-person-address.service";
import EmployeeService from "../employee/employee.service";
import addressService from "../address.service";

const ClientEnums = {
  Company: {
    Service: CompanyService,
    AddressService: CompanyAddressService,
    AddressField: "companyaddress_set",
  },
  PhysicalPerson: {
    Service: PhysicalPersonService,
    AddressService: PhysicalPersonAddressService,
    AddressField: "physicalpersonaddress_set",
  },
};

const wizard = {
  namespaced: true,

  state: {
    secondmentDraft: {},
    secondmentEdit: {},
    resumeStep: null,
    isLoading: false,
    secondmentId: null
  },

  mutations: {
    setDraft(state, value) {
      state.secondmentDraft = value;
    },

    setEdit(state, value) {
      state.secondmentEdit = value;
    },

    resetEdit(state) {
      state.secondmentEdit = {};
    },

    resetDraft(state) {
      state.secondmentDraft = {};
    },

    setResumeStep(state, value) {
      state.resumeStep = value;
    },

    SET_EDIT_SECONDMENT_CLIENT(state, value) {
      state.secondmentEdit.secondmentClient = _.cloneDeep(value);
    },

    SET_EDIT_SECONDMENT_EMPLOYEES(state, value) {
      state.secondmentEdit.secondmentEmployees = _.cloneDeep(value);
    },

    SET_EDIT_SECONDMENT_SUPPLIERS(state, value) {
      state.secondmentEdit.secondmentSuppliers = _.cloneDeep(value);
    },

    SET_EDIT_SECONDMENT_TRAVEL_PLANS(state, value) {
      state.secondmentEdit.travelPlans = _.cloneDeep(value);
    },

    SET_EDIT_SECONDMENT_ACTIVITIES(state, value) {
      state.secondmentEdit.selectedNacecodes = value;
    },

    SET_EDIT_LOADING_STATE(state, value) {
      state.isLoading = value;
    },
    reset: () => {},
    setSecondmentId(state, value) {
      state.secondmentId = value
    }
  },

  actions: {
    resetEdit({
      state
    }) {
      state.secondmentEdit = {}
    },

    resetAll({
      state
    }) {
      (state.secondmentDraft = {}), (state.secondmentEdit = {});
    },

    async setEdit({
      commit,
      state
    }, secondment) {
      state.isLoading = true;
      commit("resetEdit");
      state.secondmentEdit.country = secondment.country;
      state.secondmentEdit.id = secondment.id;
      state.secondmentEdit.service = secondment.clients[0].activities[0].type;
      state.secondmentEdit.description = secondment.description;
      state.secondmentEdit.status = secondment.status;
      state.secondmentEdit.past = secondment.past;
      state.secondmentEdit.dateEnd = secondment.date_end;
      state.secondmentEdit.dateStart = secondment.date_start;
      state.secondmentEdit.products = secondment.products?.items;
      state.secondmentEdit.travelPlans = secondment.travel_plans.map((tp) => {
        tp.travel_days = tp.travel_days.map((td) => {
          td.places = td.places.map((p) => {
            p.place = p.place.id;
            return p;
          });
          return td;
        });
        return tp;
      });
      state.secondmentEdit.clientKind = secondment.clients[0].kind;

      //client setup
      const clientKind = state.secondmentEdit.clientKind;

      const response = await ClientEnums[clientKind].Service.getOne(secondment.clients[0].client_object_id);
      const selectedClient = response.data;
      //client addresses setup
      selectedClient.addresses = selectedClient[ClientEnums[clientKind].AddressField].map((ad) => ({
        id: ad.address.id,
        formatted: ad.address.formatted,
        latitude: ad.address.latitude,
        longitude: ad.address.longitude,
      }));

      const addressInCompany = selectedClient.addresses.find((ad) => ad.id === secondment.clients[0].address);

      const notInCompany = await addressService.getByID(secondment.clients[0].address);

      if (!addressInCompany) {
        selectedClient.addresses.push(notInCompany);
      }

      //employees setup
      const employeesObjects = await Promise.all(
        secondment.employees.map((emp) => EmployeeService.getOne(emp.employee, null, 'persona,company,employeecontract_set,employeecontract_set.national_contract_level,agency,salary_adjustment,availability_data', null, secondment.country, secondment.date_start, secondment.date_end)),
      );

      const selectedEmployees = employeesObjects.map((emp) => ({...emp.data, employee: emp.data.id}));

      state.secondmentEdit.selectedClient = selectedClient;
      state.secondmentEdit.selectedClient.kind = secondment.clients[0].kind
      state.secondmentEdit.selectedEmployees = selectedEmployees;

      const selectedNaceCodes = secondment.clients[0].activities[0].activities.map((nc) => nc.id);

      //suppliers setup
      const selectedSuppliers = secondment.clients[0].partners.map((prt) => ({
        ...prt,
        id: prt.partner_object_id,
        content_type: prt.partner_content_type,
      }));

      //contractor setup
      state.secondmentEdit.selectedSuppliers = selectedSuppliers;
      commit("SET_EDIT_SECONDMENT_ACTIVITIES", selectedNaceCodes);
      commit("SET_EDIT_SECONDMENT_CLIENT", _.cloneDeep(secondment.clients[0]));
      commit("SET_EDIT_SECONDMENT_EMPLOYEES", _.cloneDeep(secondment.employees));
      commit("SET_EDIT_SECONDMENT_SUPPLIERS", _.cloneDeep(secondment.clients[0].partners));

      const contractor = secondment.clients[0]?.contractors[0];
      if (contractor) {
        state.secondmentEdit.selectedContractor = {
          id: contractor.contractor_object_id,
          content_type: contractor.contractor_content_type,
          kind: contractor.kind,
          name: contractor.name,
          addresses: contractor.registered_office
        };
      }
    },
  },

  setSecondmentId({
    commit
  }, id) {
    commit("setSecondmentId", id)
  }
};

export default wizard;