var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row"
  }, [_vm.label ? _c('label', {
    staticClass: "col-form-label",
    class: {
      'col-xl-3 col-lg-3': _vm.horizontal,
      'col-12': !_vm.horizontal
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    class: {
      'col-xl-9 col-lg-6': _vm.horizontal,
      'col-12': !_vm.horizontal
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vModel,
      expression: "vModel"
    }],
    staticClass: "form-control form-control-solid",
    class: {
      'is-invalid': !_vm.valid,
      className: _vm.className
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.vModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.vModel = $event.target.value;
      }
    }
  }), !_vm.valid ? _c('div', {
    staticClass: "invalid-feedback d-block",
    domProps: {
      "textContent": _vm._s(_vm.invalidFeedback)
    }
  }) : _vm._e(), _vm.hint ? _c('span', {
    staticClass: "form-text text-muted"
  }, [_vm._v(_vm._s(_vm.hint))]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }