<template>
  <div class="form-group row">
    <label v-if="label" for="uniqueId" class="col-form-label col-12">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="col-12">
      <div class="d-flex">
        <input :id="uniqueId" type="text" :value="inputValue" readonly :class="computedClasses" :disabled="disabled" />
        <AirbnbStyleDatepicker v-if="!disabled" :trigger-element-id="uniqueId" :mode="'range'" :date-one="selectedFrom"
          :date-two="selectedTo" :min-date="minDate" :end-date="maxDate" :customized-dates="customizedDates"
          :enabled-dates="enabledDates" :show-shortcuts-menu-trigger="false" class="datepicker-position"
          @closed="onClosed" @apply="onApply" @date-one-selected="onDateFromSelected"
          @date-two-selected="onDateToSelected" />
        <span v-if="enableClean" v-b-tooltip.hover :title="$t('Clear')" class="d-inline-block px-1 ml-2 mt-4"
          @click="cleanDates">
          <i :class="icons.close"></i>
        </span>
      </div>
      <p v-if="errorMessages.length" class="text-danger">{{ errorMessages[0] }}</p>
    </div>
  </div>
</template>

<style scoped>
.datepicker-position {
  right: auto !important;
  left: 0 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import icons from "@/core/config/icons";

export default {
  props: {
    dateFrom: {
      type: String,
      default: "",
    },
    dateTo: {
      type: String,
      default: "",
    },
    inputValueFormatter: {
      type: Function,
      default: null,
    },
    customizedDates: {
      type: Array,
      default: () => [],
    },
    enabledDates: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    className: {
      type: Object,
      default: () => { }
    },
    enableClean: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select the secondment dates",
    },
  },
  data() {
    return {
      icons,
      selectedFrom: "",
      selectedTo: "",
    };
  },
  computed: {
    ...mapGetters("user", ["dateFormat"]),

    computedClasses() {
      const classes = this.className;
      return {
        ...classes,
        'form-control': true,
        'form-control-solid': true,
        'text-muted': this.inputValue === this.placeholder
      }
    },
    uniqueId() {
      return `datepicker-trigger-${this._uid}`;
    },

    inputValue() {
      if (this.inputValueFormatter) {
        return this.inputValueFormatter(this.selectedFrom, this.selectedTo);
      }

      return this.defaultinputValueFormatter(this.selectedFrom, this.selectedTo);
    },
  },
  watch: {
    dateFrom(val) {
      this.selectedFrom = val
    },
    dateTo(val) {
      this.selectedTo = val
    }
  },
  mounted() {
    this.selectedFrom = this.dateFrom;
    this.selectedTo = this.dateTo;
  },
  methods: {
    cleanDates() {
      if (this.enableClean) {
        this.$emit("remove-date-selected")
      }
    },
    onApply() {
      this.$emit("date-from-to-selected", { from: this.selectedFrom, to: this.selectedTo });
    },
    onDateFromSelected(val) {
      this.selectedFrom = val;
    },

    onDateToSelected(val) {
      this.selectedTo = val;
    },

    onClosed() {
      this.selectedFrom = this.dateFrom
      this.selectedTo = this.dateTo
      this.$emit("closed");
    },

    defaultinputValueFormatter(dateFrom, dateTo) {
      const from = dateFrom ?? `from ${dateFrom}`;
      const to = dateTo ?? `to ${dateTo}`;
      return from && to ? `${DateService.format(from, this.dateFormat)}  ➡  ${DateService.format(to, this.dateFormat)}`
        : this.placeholder;
    },
  },
};
</script>
