import UsersService from "../users.service";
import CompanyService from "../company/company.service";
import {backendErrorSwal} from "@/core/helpers/swal";

async function loadCompanyData(companyId) {
  const expand = "company_group.companies";
  const fields = "companyaddress_set,country_of_establishment,focussetting_set,company_group";
  return await CompanyService.getOne(companyId, expand, fields).then(res => {
    return CompanyService.updateCompanyAddress(res.data);
  }).then(results => {
    const {
      companyaddress_set: companyAddresses,
      country_of_establishment: countryOfEstablishment,
      company_group: companyGroup,
      focussetting_set: focusSettings
    } = results;

    return {
      companyAddresses,
      countryOfEstablishment,
      companyGroup,
      focusSettings
    };
  }).catch(err => {
    console.error('@loadCompanyData user', err.response.data);
  });
}

function getExpandedMainAddress(addresses) {
  if (!addresses.length) return;

  let address = addresses.find(addr => addr.main);
  if (!address && addresses.length) {
    address = addresses[0];
  }
  return address;
}

const user = {
  namespaced: true,

  state: {
    user: null,
    expandedMainAddress: null,
    countryOfEstablishment: "",
    companyGroup: [],
    focusSettings: [],
  },

  actions: {
    async loadUserInfo({
      commit,
      dispatch
    }) {
      const userAccess = JSON.parse(localStorage.getItem("ep_user"));
      await commit("SET_USER", userAccess.user);

      await dispatch("loadFocusCompanyData");

      return true
    },

    async loadFocusCompanyData({
      commit,
      getters
    }) {
      const userAccess = JSON.parse(localStorage.getItem("ep_user"));
      commit("SET_USER", userAccess.user);

      const {
        companyAddresses,
        countryOfEstablishment,
        companyGroup,
        focusSettings
      } = await loadCompanyData(
        getters.getCurrentManagedCompany.company,
      );

      commit("SET_COUNTRY_OF_ESTABLISHMENT", countryOfEstablishment);

      commit("SET_COMPANY_GROUPS", companyGroup);

      const address = getExpandedMainAddress(companyAddresses);
      commit("SET_EXPANDED_MAIN_ADDRESS", address);
      commit("SET_FOCUS_SETTINGS", focusSettings);

      return true
    },

    async setUserDateFormat({
      state /*commit*/
    }, format) {
      let separator = "/";
      let splitted = format.split(separator);
      if (splitted.length < 3) {
        separator = "-";
        splitted = format.split(separator);
      }
      if (splitted.length === 3) {
        let [firstParam, secondParam, thirdParam] = splitted;
        const one = firstParam[0];
        const two = secondParam[0];
        const three = thirdParam[0];

        const arr = ["%", one, two, three].map(el => {
          return el !== "Y" ? el.toLowerCase() : el;
        });

        const formatToApi = arr.join("%").slice(1);

        try {
          await UsersService.editUser(state.user.id, {
            date_format: formatToApi,
            date_separator: separator,
          });
        } catch (err) {
          backendErrorSwal(err, "Unable to change the date format, please retry later")
        }
      }
    },

    async setUserCompanyAddress({
      commit,
      getters
    }) {
      const {
        companyAddresses
      } = await loadCompanyData(getters.getCurrentManagedCompany.company);
      const address = getExpandedMainAddress(companyAddresses);
      commit("SET_EXPANDED_MAIN_ADDRESS", address);
    },

    async setFocusCompany({
      commit,
      dispatch
    }, id) {
      await commit("setFocusCompany", id);
      await dispatch("loadFocusCompanyData");
    },

    async setUserManagedCompanies({
      commit
    }, companies) {
      await commit("setUserManagedCompanies", companies);
    },

    async setFocusSettings({
      commit
    }, setting) {
      commit("SET_FOCUS_SETTINGS", setting);
    },
    

    setAcceptedCondition({commit}, acceptedCondition) {
      commit("SET_USER_ACCEPTED_CONDITION", acceptedCondition);
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_USER_ACCEPTED_CONDITION(state, status) {
      state.user.accepted_condition = status;

      // Update user managedcompany_set
      if (state.user.managedcompany_set.length) {
        let companies = []
        for (const managedCompany of state.user.managedcompany_set) {
          if (managedCompany.company == state.user.selected_company) {
            managedCompany.accepted_condition = status;
          }
          companies.push(managedCompany)
        }
        state.user.managedcompany_set = companies
      }

      // Get data from ep_user localStorage
      const userAccess = JSON.parse(localStorage.getItem("ep_user"));

      // Update user in ep_user localStorage
      localStorage.setItem("ep_user", JSON.stringify({
        'access': userAccess.access,
        'refresh': userAccess.refresh,
        'user': state.user
      }));
    },

    SET_EXPANDED_MAIN_ADDRESS(state, address) {
      state.expandedMainAddress = address;
    },

    SET_COUNTRY_OF_ESTABLISHMENT(state, countryOfEstablishment) {
      state.countryOfEstablishment = countryOfEstablishment;
    },
    SET_COMPANY_GROUPS(state, companyGroup) {
      state.companyGroup = companyGroup;
    },
    SET_FOCUS_SETTINGS(state, focusSettings) {
        state.focusSettings = focusSettings;
    },
    reset: () => {},

    setFocusCompany(state, id) {
      // Update selected_company in user state
      state.user.selected_company = id

      // Get data from ep_user localStorage
      const userAccess = JSON.parse(localStorage.getItem("ep_user"));

      // Update user in ep_user localStorage
      localStorage.setItem("ep_user", JSON.stringify({
        'access': userAccess.access,
        'refresh': userAccess.refresh,
        'user': state.user
      }));
    },

    setUserManagedCompanies(state, companies) {
      state.user.managedcompany_set = companies;

      // Get data from ep_user localStorage
      const userAccess = JSON.parse(localStorage.getItem("ep_user"));

      // Update user in ep_user localStorage
      localStorage.setItem("ep_user", JSON.stringify({
        'access': userAccess.access,
        'refresh': userAccess.refresh,
        'user': state.user
      }));
    },
  },

  getters: {
    hasLoadedUserInfo(state) {
      if (state.user !== null) {
        return true;
      }
      return false;
    },

    isApStaff(state) {
      return state.user.is_ap_staff;
    },

    isInApFocus(state) {
      return state.user.selected_company == 1;
    },

    getUserID(state) {
      return state.user.id;
    },

    getUserFullName(state) {
      return `${state.user?.first_name} ${state.user?.last_name}`;
    },

    getUserEmail(state) {
      return state.user.email;
    },

    getCurrentManagedCompany(state, getters) {
      return getters.getUserManagedCompanies.find(el => el.company === state.user.selected_company);
    },

    getCurrentManagedCompanyId(_, getters) {
      return getters.getCurrentManagedCompany.company;
    },

    getUserManagedCompanies(state) {
      return state.user.managedcompany_set;
    },

    getUserExpandedMainAddressCountryIso(state) {
      return state.expandedMainAddress?.address?.locality.state.country.code;
    },

    getUserCountryOfEstablishment(state) {
      return state.countryOfEstablishment;
    },

    getCompanyGroups(state) {
      if (!state.companyGroup.length) {
        return [];
      }
      return state.companyGroup[0]?.companies;
    },

    getFocusSettings(state) {
        return state.focusSettings;
    },
    
    getSelectedCompany(state) {
      return state.user.selected_company;
    },

    /**
     * @deprecated only used for backwards compatibility. Use dateFormat instead.
     */
    date_format(state) {
      if (state.user.date_format && state.user.date_separator) {
        const appFormat = state.user.date_format.split("%");
        const newFormat = appFormat
          .map(el => {
            return el === "Y" ? el + el + el + el : el + el;
          })
          .join(state.user.date_separator)
          .slice(1)
          .toUpperCase();
        return newFormat;
      }

      return "YYYY-MM-DD";
    },

    timezone(state) {
      return state.user.timezone
    },

    dateFormat(_, getters) {
      return getters.date_format;
    },

    isOnlyMaps(state) {
      return !!state.user?.only_maps
    },

    getCurrentManagedCompanyPermission(_, getters) {
      return getters.getCurrentManagedCompany.permission_level;
    },

    isAfterSale(_, getters) {
      return getters.getCurrentManagedCompanyPermission == 1;
    },

    getPersonaId(state) {
      return state.user.persona
    },

    getCurrentManagedCompanyPaycheckPermission(_, getters) {
      return getters.getCurrentManagedCompany.paycheck_permission;
    },

    getCurrentManagedCompanyCertificateSuitabilityPermission(_, getters) {
      return getters.getCurrentManagedCompany.certificate_suitability_for_work_permission;
    },
    getCurrentManagedCompanyContractPermission(_, getters) {
      return getters.getCurrentManagedCompany.contract_permission;
    },
    getCurrentManagedCompanyIdentityDocumentPermission(_, getters) {
      return getters.getCurrentManagedCompany.identity_document_permission;
    },
    getCurrentManagedCompanyPostingCertificatePermission(_, getters) {
      return getters.getCurrentManagedCompany.posting_certificate_permission;
    },
  },
};

export default user;