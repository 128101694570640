<template>
  <div :id="uniqueId" class="wizard wizard-2" data-wizard-state="step-first" data-wizard-clickable="true">
    <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
      <div class="wizard-steps">
        <slot name="wizard-nav-left"></slot>
      </div>
    </div>
    <div class="wizard-body py-8 px-8 py-lg-20 px-lg-20">
      <slot :goNext="goNext" :goPrev="goPrev" :getStep="getStep" :goTo="goTo" :currentStep="currentStep"></slot>
    </div>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";

const scrollTop = () => {
  setTimeout(() => {
    KTUtil.scrollTop();
  }, 100);
};

export default {
  props: {
    clickableSteps: {
      type: Boolean,
      default: true,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      wizard: null,
    };
  },

  computed: {
    options() {
      return {
        // Initial active step number.
        startStep: 1,
        // Allow step clicking.
        clickableSteps: this.clickableSteps,
        //Disables scrollTop for nested wizard
        nested: this.nested,
        // Enable/disable wizard previouse and next navigation default handlers.
        // If true, you should not any navigation method to navigate the form.
        navigation: false,
      };
    },

    currentStep() {
      return this.getStep();
    },

    uniqueId() {
      return `kt_wizard_${this._uid}`;
    },
  },

  mounted() {
    this.wizard = new KTWizard(this.uniqueId, this.options);

    if (this.options.navigation) {
      this.wizard.on("change", function () {
        scrollTop();
      });
    }
  },

  // See https://keenthemes.com/metronic/?page=docs&section=html-components-wizard for API methods docs.
  methods: {
    goNext() {
      this.wizard?.goNext();
      if (!this.options.nested) {
        if (this.scroll) {
          scrollTop()
        } else { return }

      }
    },

    goPrev() {
      this.wizard?.goPrev();
      if (!this.options.nested) {
        if (this.scroll) {
          scrollTop()
        } else { return }
      }
    },

    getStep() {
      return this.wizard?.getStep();
    },

    goTo(number) {
      this.wizard?.goTo(number);
      scrollTop();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>
