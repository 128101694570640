import Swal from "sweetalert2";
import i18n from "@/core/plugins/vue-i18n";

function unsavedChangesSwal() {
  return Swal.fire({
    title: i18n.t("You have unsaved changes."),
    text: i18n.t("Do you want to save the changes?"),
    confirmButtonText: `<i class='fas fa-save mr-1'></i>${i18n.t("Save and leave")}`,
    showDenyButton: true,
    denyButtonText: `<i class='fas fa-sign-out-alt mr-1'></i>${i18n.t("Leave without saving")}`,
    cancelButtonText: i18n.t("Cancel"),
    showCancelButton: false,
    showCloseButton: true,
    closeButtonHtml: "<i class='fas fa-times p-0'></i>",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-danger",
      cancelButton: "btn",
      closeButton: ["btn", "btn-danger", "m-1"],
    },
  });
}

function pendingCheckoutSwal() {
  return Swal.fire({
    title: i18n.t("Your cart will be emptied"),
    text: i18n.t("are you sure?"),
    confirmButtonText: `<i class='fas fa-check mr-1'></i>yes`,
    showDenyButton: true,
    denyButtonText: `<i class='fas fa-times mr-1'></i>No`,
    cancelButtonText: i18n.t("Cancel"),
    showCancelButton: false,
    showCloseButton: false,
    closeButtonHtml: "<i class='fas fa-times p-0'></i>",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-danger",
      cancelButton: "btn",
      closeButton: ["btn", "btn-danger", "m-1"],
    },
  });
}

function backendErrorSwal(err = null, text = null, title = null) {
  const sessionExpired = err?.response?.status === 401;
  return sessionExpired ?
    Swal.fire({
      icon: "info",
      title: err?.response?.data?.message || "Session Expired",
      toast: true,
      timer: 3000,
      position: "top-end",
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }) :
    Swal.fire({
      icon: "info",
      title: title || i18n.t("Attention"),
      text: text || err?.response?.data?.message || err?.message || i18n.t("Something went wrong")
    });
}

function validationErrorSwal(title = null, text = null) {
  return Swal.fire({
    icon: "info",
    title: title || i18n.t("Validation Error"),
    text: text || i18n.t("There are some validation errors. Please check the form validity."),
  });
}

const successTitle = i18n.t("Success");

function successSwal({
  text = "",
  title = successTitle
} = {}) {
  return Swal.fire({
    icon: "success",
    title: title,
    text,
    timer: 2000,
    showConfirmButton: false,
    timerProgressBar: true,
  });
}

const deleteSwal = () => {
  return Swal.fire({
    title: i18n.t("Delete"),
    text: i18n.t("Are you sure you want to delete this?"),
    icon: "warning",
    confirmButtonClass: "btn btn-danger",
    confirmButtonText: i18n.t("Yes delete"),
    showCancelButton: true,
    cancelButtonText: i18n.t("No, do NOT delete"),
    cancelButtonClass: "btn",
    reverseButtons: true
  });
};

const confirmationSwal = ({
  text,
  title = i18n.t("Caution"),
  isHtml = false,
  icon = "warning"
}) => {
  const swalOptions = {
    icon: icon,
    title: title,
    confirmButtonText: i18n.t("Yes"),
    showDenyButton: true,
    denyButtonText: i18n.t("No"),
    customClass: {
      confirmButton: "btn",
      denyButton: "btn",
    },
  };

  // Set text or HTML content based on isHtml flag
  if (isHtml) {
    swalOptions.html = text;
  } else {
    swalOptions.text = text;
  }

  return Swal.fire(swalOptions);
};

const countryChangeSwal = ({
  text,
  title = i18n.t("Caution")
}) => {
  return Swal.fire({
    icon: "warning",
    title,
    text,
    confirmButtonText: i18n.t("Yes"),
    showDenyButton: true,
    denyButtonText: i18n.t("No"),
    customClass: {
      confirmButton: "btn",
      denyButton: "btn",
    },
  });
};

const addressValidationSwal = () => {
  return Swal.fire({
    title: i18n.t("Caution"),
    text: i18n.t("Seems like some fields are missing, do you want to continue?(You can modify them and then validate the address with the validate address button)"),
    icon: "warning",
    confirmButtonText: i18n.t("Yes"),
    cancelButtonText: i18n.t("No"),
    showCancelButton: true,
    reverseButtons: true,
    customClass: {
      confirmButton: "btn",
      cancelButton: "btn",
    },
  });
};

const legalPersonSetAsButtonSwal = (type, enable) => {
  return Swal.fire({
    title: "",
    text: `${enable ? "Setting" : "Removing"} as ${type}, are you sure?`,
    icon: "warning",
    confirmButtonText: i18n.t("Yes"),
    cancelButtonText: i18n.t("No"),
    showCancelButton: true,
    customClass: {
      confirmButton: "btn",
      cancelButton: "btn",
    },
  });
};
const physicalPersonSetAsButtonSwal = (type, enable) => {
  return Swal.fire({
    title: "",
    text: `${enable ? "Setting" : "Removing"} as ${type}, are you sure?`,
    icon: "warning",
    confirmButtonText: i18n.t("Yes"),
    cancelButtonText: i18n.t("No"),
    showCancelButton: true,
    customClass: {
      confirmButton: "btn",
      cancelButton: "btn",
    },
  });
};

const quickInviteSendSwal = (email) => {
  return Swal.fire({
    title: i18n.t("Invite user"),
    text: `${i18n.t("An invitation will be sent to")} ${email}, ${i18n.t("are you sure?")}`,
    icon: "info",
    input: 'select',
    inputOptions: {
      0: i18n.t("Admin"),
      1: i18n.t("AfterSale")
    },
    inputPlaceholder: i18n.t("Permission level"),
    showCancelButton: true,
    confirmButtonText: i18n.t("Invite user"),
    cancelButtonText: i18n.t("Cancel"),
    reverseButtons: true,
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn",
      input: "form-control form-control-solid"
    },
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value != '') {
          resolve()
        } else {
          resolve(i18n.t("You need to select a permission level"))
        }
      })
    }
  })

}

const toggleUserSwal = (enabled) => {
  return Swal.fire({
    title: "",
    text: `${i18n.t("This User will be")} ${enabled ? i18n.t("disabled") : i18n.t("enabled")}  ${i18n.t("are you sure?")}`,
    icon: "warning",
    confirmButtonText: i18n.t("Yes"),
    cancelButtonText: i18n.t("No"),
    showCancelButton: true,
    showCloseButton: true,
    closeButtonHtml: "<i class='fas fa-times p-0'></i>",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-danger",
      cancelButton: "btn",
      closeButton: ["btn", "btn-danger", "m-1"],
    },
  });
};

const shopConfirmSwal = () => {
  return Swal.fire({
    title: `${i18n.t("Place the order?")} `,
    icon: "info",
    confirmButtonText: i18n.t("Yes"),
    denyButtonText: i18n.t("No"),
    showDenyButton: true,
    showCloseButton: true,
    reverseButtons: true,
    closeButtonHtml: "<i class='fas fa-times p-0'></i>",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-danger",
      cancelButton: "btn",
      closeButton: ["btn", "btn-danger", "m-1"],
    },
  });
};

let notificationCount = 0;
let debounceTimeout = null;

const notificationToast = (icon = null) => {
  clearTimeout(debounceTimeout);

  notificationCount++;

  debounceTimeout = setTimeout(() => {
    let notificationTitle = notificationCount > 1 ?
      `${notificationCount} ${i18n.t('new notifications')}` :
      `${notificationCount} ${i18n.t('new notification')}`;

    Swal.fire({
      title: notificationTitle,
      icon: icon || "info",
      timer: 2000,
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    // reset the notification count after showing the notification
    notificationCount = 0;
  }, 3000); // debouncing interval
};

const maxRiskSwal = () => {
  return Swal.fire({
    title: i18n.t("Dangerous country"),
    text: i18n.t("Click on the buy reports button to buy risk reports for this country"),
    icon: "warning"
  });
}

const ART13_EmptySwal = (name, surname) => {
  return Swal.fire({
    title: i18n.t("Warning module A1-ART 13"),
    text: i18n.t("The A1-ART 13 form for " + `${name}` + " " + `${surname}` + " is empty, please fill in the form or uncheck it"),
    icon: "warning"
  });
}

const ART12_SamePostingCountry = (name, surname) => {
  return Swal.fire({
    title: i18n.t("Warning module A1-ART 12"),
    text: i18n.t("The A1-ART 12 for " + `${name}` + " " + `${surname}` + " must have the posting country field different from the Country"),
    icon: "warning"
  });
}

const ordersInvoiceDetailsSwal = (item) => {
  const invoiceDate = item.invoice_date || '';
  const invoiceRef = item.invoice_ref || '';

  return Swal.fire({
    title: i18n.t("Invoice details"),
    html: `
      <label for="swal-input1" class="mb-0">${i18n.t("Invoice Date")}</label>
      <input type="date" id="swal-input1" class="swal2-input" placeholder="Invoice Date" value="${invoiceDate}">
      <label for="swal-input2" class="mb-0">${i18n.t("Invoice Reference")}</label>
      <input type="text" id="swal-input2" class="swal2-input" placeholder="Invoice Reference" value="${invoiceRef}">
    `,
    focusConfirm: false,
    preConfirm: () => {
      return {
        invoice_date: document.getElementById('swal-input1').value,
        invoice_ref: document.getElementById('swal-input2').value
      }
    }
  })
}

const orderNotesSwal = (item, internal=true) => {
  const notes = internal ? (item.internal_notes || "") : (item.notes || "");

  return Swal.fire({
    title: i18n.t("Order notes"),
    html: `
      <label for="swal-input1" class="mb-0">${i18n.t("Notes")}</label>
      <textarea id="swal-input1" class="swal2-textarea" placeholder="Notes">${notes}</textarea>
    `,
    focusConfirm: false,
    preConfirm: () => {
      return {
        notes: document.getElementById('swal-input1').value
      }
    }
  })

}

const switchFocusSwal = ( message, focusName) => {
  return Swal.fire({
          title: i18n.t("Switch to focus") + ": " + focusName.toUpperCase(),
          text: message,
          icon: "info",
          confirmButtonText: i18n.t("Switch to") + " " + focusName,
          showCancelButton: true,
          cancelButtonText: i18n.t("Cancel"),
          reverseButtons: true
        })
}

export {
  unsavedChangesSwal,
  backendErrorSwal,
  validationErrorSwal,
  successSwal,
  deleteSwal,
  confirmationSwal,
  countryChangeSwal,
  addressValidationSwal,
  legalPersonSetAsButtonSwal,
  physicalPersonSetAsButtonSwal,
  pendingCheckoutSwal,
  toggleUserSwal,
  shopConfirmSwal,
  maxRiskSwal,
  notificationToast,
  quickInviteSendSwal,
  ART13_EmptySwal,
  ART12_SamePostingCountry,
  ordersInvoiceDetailsSwal,
  orderNotesSwal,
  switchFocusSwal
};