import api from "@/core/services/api.service";
const path = "post-sources"
const source = {
  namespaced: true,
  state: {
    sources: []
  },
  mutations: {
    setSources(state, sources) {
      state.sources = sources
    },

    setSourceId(state, source) {
      for (const key in state.sources) {
        if (state.sources[key].id == source.id) {
          state.sources[key] = source
        }
      }
    },
    reset: () => {}
  },
  actions: {
    async loadSources({
      commit
    }) {
      const res = await api.get(`${path}/`);
      commit('setSources', res.data.results)
    },

    async setSourceId({
      commit,
    }, source) {
      commit('setSourceId', source)
    },
  }
}

export default source;