<template>
  <google-maps-autocomplete
    v-model="place"
    :error-messages="errorMessages"
    :autocomplete-options="{
      types: ['administrative_area_level_3', 'locality'],
      componentRestrictions: restriction
    }"
    v-bind="{
      hint,
      label,
      'valid-feedback': validFeedback,
      state,
      'invalid-feedback': invalidFeedback,
      horizontal,
    }"
  ></google-maps-autocomplete>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
import GoogleMapsAutocomplete from "@/view/components/google-maps/GoogleMapsAutocomplete.vue";
export default {
  components: {
    GoogleMapsAutocomplete,
  },

  mixins: [BFormMixin],

  props: {
    value: {
      type: [Object],
      default: null,
    },
    restriction: {
      type: [Object, String],
      default: ()=>{}
    }
  },

  computed: {
    place: {
      get() {
        return this.value ?? {};
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
