import PostAccessService from "@/core/services/risk/post-access.service";

const capabilities = {
  namespaced: true,
  state: {
    capabilities: [],
    risk_map: [],
    pending: [],
    subscriptions: []
  },
  mutations: {
    setCapabilities(state, items) {
      state.capabilities = items
    },
    setPending(state, items) {
      state.pending = items
    },
    setRiskMap(state, items) {
      state.risk_map = items
    },
    setSubscriptions(state, items) {
      state.subscriptions = items
    },
    reset: () => {}
  },
  actions: {
    async loadCapabilities({
      commit
    }) {
      let results = null
      try {
        results = await PostAccessService.getCapabilities();
      } catch (err) {
        console.error(err);
      }

      if (results) {
        if (results.subscriptions.length) {
          commit("setSubscriptions", results.subscriptions)
        }

        if (results.pending_capabilities.length) {
          commit("setPending", results.pending_capabilities)
        }

        if (results.risk_maps_enabled_countries.length) {
          commit("setRiskMap", results.risk_maps_enabled_countries)
        }

        if (results.capabilities.length) {
          commit("setCapabilities", results.capabilities)
        }
      }
    },
  },
  getters: {
    getActiveCapabilities(state) {
      let activeCaps = {}

      state.capabilities.forEach((cap) => {
        cap.categories.forEach(category => {
          if (activeCaps[category.id]) {
            activeCaps[category.id].push(cap.country)
          } else {
            activeCaps[category.id] = [cap.country]
          }
        })
        // {'id_categories':[iso1, iso2, iso3]}
      });

      return activeCaps
    },
    getActiveSubscription(state) {
      let activeSubs = {}

      state.subscriptions.forEach((sub) => {
        if (sub.active) {
          const categories = sub.querytemplate.categories;
          const countries = sub.querytemplate.post_countries;
          categories.forEach((cat) => {
            if (!activeSubs[cat]) {
              activeSubs[cat] = countries;
            } else {
              const catSet = new Set([...activeSubs[cat], ...countries]);
              activeSubs[cat] = Array.from(catSet);
            }
          });
        }
      });

      return activeSubs
    },
    getActiveSubscriptionForCountry: (state) => (country) => {
      if (!country) {
        return {}
      }

      let activeSubs = []
      state.subscriptions.forEach((sub) => {
        if (sub.active) {
          const countries = sub.querytemplate.post_countries;
          if (countries.some(el => el == country)) {
            activeSubs.push({
              name: sub.querytemplate.name,
              expiringDate: sub.date_end,
              id: sub.id,
              autoRenewal: sub.auto_renewal,
              active: sub.active,
            });
          }
        }
      });
      return activeSubs
    },
    getPendingCapabilities(state) {
      let pendingSubs = {}

      state.pending.forEach((sub) => {
        if (sub.risk_map == false) {
          const categories = sub.categories.map((cate) => cate.id);
          const country = sub.country;
          categories.forEach((cat) => {
            if (!pendingSubs[cat]) {
              pendingSubs[cat] = [country];
            } else {
              const pending = typeof pendingSubs[cat] == "string" ? [pendingSubs[cat]] : pendingSubs[cat];
              const catSet = new Set([...pending, country]);
              pendingSubs[cat] = Array.from(catSet);
            }
          });
        }
      });

      return pendingSubs
    },
    getPendingMaps(state) {
      let pendingSubs = []

      state.pending.forEach((sub) => {
        if (sub.risk_map) {
          pendingSubs.push(sub.country)
        }
      });

      return pendingSubs
    },
    /**
     * @param {*} state
     * @param {*} country La nazione da filtrare
     * @returns Le capabilities relative a quella nazione
     */
    getCapabilitiesForCountry: (state) => (country) => {
      return state.capabilities.find(el => el.country == country);
    },
    getCountrySubscription(state) {
      const subscriptions = Object.assign([], state.risk_map);

      if (state.capabilities.length) {
        state.capabilities.forEach(element => {
          if (!subscriptions.includes(element.country)) {
            subscriptions.push(element.country)
          }
        });
      }

      return subscriptions
    },
    getSubscriptionExpiringDate: (state) => (country, cat_id) => {
      let expiring_date = null;
      state.subscriptions.forEach((sub) => {
        if (sub.querytemplate.categories.find((el) => el == cat_id) &&
          sub.querytemplate.post_countries.find((el) => el == country)) {
          expiring_date = sub.date_end;
        }
      });
      return expiring_date;
    }
  }

}

export default capabilities