import api from "../api.service";

class NationsCountryService {
  async getAll(prices) {
    return await this.getPaginated(1000, 0, prices);
  }

  /**This service is necessary for all nation select fields
   * DO NOT DELETE IT
   */
  async getPaginated(limit, offset, prices) {
    const res = await api.get("nations-country/", {
      params: {
        limit,
        offset,
        risk_maps_prices: prices
      }
    });
    return res.data;
  }
  async getPaginatedNations({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    prices = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      risk_maps_prices: prices
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get("nations-country/", {
      params,
    });
    return res.data;
  }

  async getOne(iso, prices = false) {
    const res = await api.get(`nations-country/${iso}/`, {
      params: {
        risk_maps_prices: prices
      },
    });
    return res.data;
  }

  async update(iso, body) {
    const res = await api.patch(`nations-country/${iso}/`, body);
    return res.data;
  }

  async getGroups() {
    const res = await api.get("nations-groups/");
    return res.data;
  }
}



export default new NationsCountryService();