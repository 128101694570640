import api from "./api.service";

class UsersService {
  get PATH() {
    return "users";
  }

  async getUserByID(id) {
    const res = await api.get(`users/${id}/`);
    return res.data;
  }

  async editUser(id, body) {
    const res = await api.patch(`users/${id}/`, body)
    return res.data
  }
  async setFocusCompany(companyID) {
    const res = await api.post("users/set_focus/", {
      focus_company: companyID
    });
    return res.data;
  }

  async changePassword(id, body) {
    await api.post(`users/${id}/change_password/`, body);
  }

  async getApStaffUsers({
    perPage,
    page,
    sortBy = null,
    sortDesc = null,
    search = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const isApStaff = true;

    const offset = (page - 1) * perPage;
    const params = {
      is_ap_staff: isApStaff,
      limit: perPage,
      offset,
      search,
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const r = await api.get("users/", {
      params,
    });
    return r.data;
  }

  async getEuropostingUsers({
    perPage,
    page,
    sortBy,
    sortDesc,
    search,
    fields = null,
    expand = null,
    omit = null
  }) {
    const isApStaff = false;
    const offset = (page - 1) * perPage;
    const params = {
      is_ap_staff: isApStaff,
      limit: perPage,
      offset,
      search
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const r = await api.get("users/", {
      params,
    });
    return r.data;
  }
}

export default new UsersService();