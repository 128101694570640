<template>
  <div class="form-group row">
    <label v-if="label" class="col-form-label" :class="{ 'col-xl-3 col-lg-3': horizontal, 'col-12': !horizontal }">{{
      label }}</label>
    <div class="col-form-label" :class="{ 'col-xl-9 col-lg-6': horizontal, 'col-12': !horizontal }">
      <div class="radio-inline">
        <label v-for="(option, index) in options" :key="index" class="radio radio-primary"
          :class="{ 'is-invalid': !valid, 'radio-disabled': disabled }">
          <input v-model="vModel" type="radio" :value="option.value" :disabled="disabled" />
          <span></span>
          {{ option.text }}
        </label>
      </div>
      <div v-if="!valid" class="invalid-feedback d-block my-2" v-text="showErrors"></div>
      <span v-if="hint" class="form-text text-muted">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],

  props: {
    value: {
      type: [String, Boolean, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showErrors() {
      let errors = [];
      this.errorMessages.map((error) => {
        errors.push(error);
      });

      return errors.join("\n");
    },
  }
};
</script>
