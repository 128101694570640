var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('euro-file-input', {
    attrs: {
      "accept": _vm.accept,
      "label": _vm.label,
      "error-messages": _vm.errorMessages
    },
    on: {
      "change": _vm.croppie
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": _vm.dialogWidth
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('vue-croppie', {
    ref: "croppieRef",
    attrs: {
      "enable-orientation": false,
      "enable-resize": false,
      "enable-exif": true,
      "boundary": {
        width: 450,
        height: 450
      },
      "viewport": {
        width: 240,
        height: 240,
        type: 'square'
      }
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "block": ""
    },
    on: {
      "click": _vm.crop
    }
  }, [_vm._v("Crop")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }