<template>
  <b-form-group :description="hint" :label="label" :valid-feedback="validFeedback" :state="state"
    :invalid-feedback="invalidFeedback" :label-cols-lg="horizontal ? 3 : 12" :label-cols-xl="horizontal ? 3 : 12"
    :content-cols-lg="horizontal ? 9 : 12" :content-cols-xl="horizontal ? 6 : 12" :disabled="disabled">
    <template #label>
      <slot name="label"></slot>
    </template>
    <b-input-group>
      <b-form-input :id="inputId" ref="input" autocomplete="off" name="search" :placeholder="$t(placeholder)"
        aria-label="Search" accesskey="?" :value="valueFormatted" :state="state" class="form-control form-control-solid"
        :disabled="disabled" @input="onInput" @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"></b-form-input>
      <b-input-group-append>
        <slot name="append"></slot>
      </b-input-group-append>
    </b-input-group>
    <slot name="pointer-location"></slot>
  </b-form-group>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],

  props: {
    value: {
      type: Object,
      default: () => { },
    },
    // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions
    autocompleteOptions: {
      type: Object,
      default: () => { },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Add a location',
    },
  },

  data: () => ({
    autocomplete: null,
    geocoder: null,
    inputValue: null
  }),

  computed: {
    inputId() {
      return `google-autocomplete-${this._uid}`;
    },

    valueFormatted() {
      if (this.inputValue && this.inputValue.hasOwnProperty('formatted_address')) {
        return this.inputValue.formatted_address;
      }
      if (this.inputValue && this.inputValue.hasOwnProperty('formatted')) {
        return this.inputValue.formatted;
      }
      return '';
    }
  },
  watch: {
    autocompleteOptions: {
      deep: true,
      handler(val) {
        if (this.autocomplete) {
          this.autocomplete.setOptions(val);
        }
      },
    },
    value(newValue) {
      this.inputValue = newValue;
    }
  },

  mounted() {
    this.initAutocomplete();
    this.inputValue = this.value
  },

  beforeDestroy() {
    google.maps.event.clearInstanceListeners(this.autocomplete);
  },

  methods: {
    async reinitializeAutocomplete(val) {
      return await this.geocoder.geocode({ address: val }).then((response) => {
        this.inputValue = response.results[0];
        this.$emit('form-validate', true);
        return response.results[0];
      }).catch((e) => {
        console.error("Geocode was not successful for the following reason: " + e);
      });
    },

    initAutocomplete() {
      this.geocoder = new google.maps.Geocoder();
      const input = document.getElementById(this.inputId);
      const options = {
        fields: ["geometry", "formatted_address", "name", "address_component", "type", "place_id"],
        ...this.autocompleteOptions,
      };
      this.autocomplete = new google.maps.places.Autocomplete(input, options);

      this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },

    onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      // if there is no geometry it means that what was written returned no results.
      if (!place.geometry) {
        return;
      }

      this.$emit("input", place);
    },

    onInput(v) {
      // If the input gets cleared, we reset the place.
      if (v === "") {
        this.$emit("input", null);
      }
    },

    changeAddressInput(value) {
      this.inputValue = value;
    }
  },
};
</script>

<style>
.form-control.form-control-solid:disabled {
  background-color: #F3F6F9;
  opacity: .7;
}
</style>