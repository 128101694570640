import api from "./api.service";
import store from "@/core/services/store";

class AuthService {
  async login(email, password) {
    const res = await api.post("auth/login/", { email, password });
    store.commit("constants/SET_API_VERSION", res.headers["api-version"]);
    return res.data;
  }

  setEpUser(access, refresh, user) {
    localStorage.setItem("ep_user", JSON.stringify({ access, refresh, user }));
  }

  logout() {
    localStorage.removeItem("ep_user");
  }

  async pwdChange({ password, token }) {
    const res = await api.post("password_reset/confirm/", {
      password,
      token,
    });
    return res;
  }
  
  async verifyPasswordResetToken(token) {
    return await api.post("password_reset/validate_token/", { token });
  }

  pwdMailRequest(email) {
    api.post("password_reset/", { email });
  }

  /**
   * isSameAuthUser checks if the current logged user is the same as the user given by userID.
   * @param {any} userID
   * @returns {boolean}
   */
  isSameAuthUser(userID) {
    const user = store?.state?.user?.user;
    if (!user || user.persona != userID) return false;
    return true;
  }

  /**
   * isManagingCompany checks if the current logged user is currently managing the given company.
   * @param {any} companyID
   * @returns {boolean}
   */
  isManagingCompany(companyID) {
    const user = store?.state?.user?.user;
    if (!user) return false;
    const managedCompany = user.managedcompany_set.filter(company => company.company == companyID);
    if (!managedCompany.length) return false;
    return true;
  }

  async activateMfa(method) {
    try {
      const res = await api.post(`auth/${method}/activate/`, { method });
      return res.data;
    } catch (err) {
      throw err?.response?.data?.error;
    }
  }

  async confirmMfaActivation(method, code) {
    try {
      const res = await api.post(`auth/${method}/activate/confirm/`, { code });
      return res.data;
    } catch (err) {
      if (err?.response?.status === 400) {
        throw err.response.data;
      }

      throw err?.response?.data?.error;
    }
  }

  async requestNewMfaCode(method) {
    try {
      const res = await api.post(`auth/code/request/`, { method });
      return res.data;
    } catch (err) {
      throw err?.response?.data?.error;
    }
  }

  async activeMfaMethods() {
    try {
      const res = await api.get("auth/mfa/user-active-methods/", { params: { limit: 20, offset: 0 } });
      return res.data;
    } catch (err) {
      throw err?.response?.data?.error;
    }
  }

  async deactivateMfaMethod(method, code) {
    try {
      await api.post(`auth/${method}/deactivate/`, { code });
    } catch (err) {
      if (err?.response?.status === 400) {
        if(err?.response?.data?.error) {
          throw err?.response?.data?.error
        }

        throw err.response.data;
      }

      throw err?.response?.data?.error;
    }
  }

  async loginMfa(code, ephemeral_token) {
    try {
      const res = await api.post(`auth/login/code/`, { code, ephemeral_token });
      return res.data;
    } catch (err) {
      if (err?.response?.status === 400) {
        throw err.response.data;
      }

      throw err?.response?.data?.error;
    }
  }

  async regenerateBackupCodes(method, code) {
    try {
      const res = await api.post(`auth/${method}/codes/regenerate/`, { code });
      return res.data;
    } catch (err) {
      if (err?.response?.status === 400) {
        throw err.response.data;
      }

      throw err?.response?.data?.error;
    }
  }

  async changePrimaryMfaMethod(method, code) {
    try {
      const res = await api.post(`auth/mfa/change-primary-method/`, { code, method });
      return res.data;
    } catch (err) {
      if (err?.response?.status === 400) {
        throw err.response.data;
      }

      throw err?.response?.data?.error;
    }
  }
}

export default new AuthService();
