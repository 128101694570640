import CompanyService from "@/core/services/company/company.service";

export default {
  data() {
    return {
      administrativeStructures: [],
    };
  },

  computed: {
    administrativeStructuresSelectable() {
      return this.administrativeStructures.map(el => ({
        value: el.id,
        text: `${el.name} - ${el.country_of_establishment}`,
      }));
    },
  },

  methods: {
    async loadAdministrativesStructuresByCountry(country) {
      const r = await CompanyService.getAllNotPaginatedFilterable({ country, getAdministrative: true });
      this.administrativeStructures = r.results;
    },

    clearAdministrativeStrucutres() {
      this.administrativeStructures = [];
    },
  },
};
