var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols-lg": _vm.horizontal ? 3 : 12,
      "label-cols-xl": _vm.horizontal ? 3 : 12,
      "content-cols-lg": _vm.horizontal ? 9 : 12,
      "content-cols-xl": _vm.horizontal ? 6 : 12
    }
  }, [_c('div', {
    staticClass: "image-input image-input-outline"
  }, [_c('div', {
    staticClass: "image-input-wrapper",
    style: {
      backgroundImage: "url(".concat(_vm.avatar, ")")
    }
  }), _c('label', {
    staticClass: "btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow avatar-action",
    attrs: {
      "data-action": "change",
      "data-toggle": "tooltip",
      "title": "",
      "data-original-title": "Change avatar"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen icon-sm"
  }), _c('input', {
    ref: "inputRef",
    attrs: {
      "type": "file",
      "name": "profile_avatar",
      "accept": "image/png, image/jpeg, image/jpg"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event);
      }
    }
  }), _c('input', {
    style: {
      position: 'absolute'
    },
    attrs: {
      "type": "hidden",
      "name": "profile_avatar_remove"
    }
  })]), _c('span', {
    staticClass: "btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow avatar-action",
    attrs: {
      "data-action": "cancel",
      "data-toggle": "tooltip",
      "title": "Cancel avatar"
    }
  }, [_c('i', {
    staticClass: "ki ki-bold-close icon-xs"
  })])]), _c('b-modal', {
    ref: "cropperModal",
    attrs: {
      "title": "Image Cropper"
    },
    on: {
      "hidden": _vm.onModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('b-button', {
          staticClass: "float-right",
          attrs: {
            "block": "",
            "variant": "primary"
          },
          on: {
            "click": _vm.crop
          }
        }, [_vm._v(" Crop ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('vue-croppie', {
    ref: "croppieRef",
    attrs: {
      "enable-orientation": false,
      "enable-resize": false,
      "enable-exif": true,
      "boundary": {
        width: 450,
        height: 450
      },
      "viewport": {
        width: 240,
        height: 240,
        type: 'square'
      }
    }
  })], 1)], 1), _c('euro-tus-file-input', {
    directives: [{
      name: "visible",
      rawName: "v-visible",
      value: false,
      expression: "false"
    }],
    ref: "tusInputRef",
    style: {
      height: 0
    },
    attrs: {
      "accept": "image/png, image/jpeg, image/jpg"
    },
    on: {
      "change": _vm.onEuroTusFileInputChange
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }