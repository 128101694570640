var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid",
    attrs: {
      "id": _vm.uniqueId,
      "data-wizard-state": "step-first",
      "data-wizard-clickable": "true"
    }
  }, [_c('div', {
    staticClass: "wizard-content d-flex flex-column mx-auto py-5 py-lg-10 w-100"
  }, [_vm._t("default", null, {
    "goNext": _vm.goNext,
    "goPrev": _vm.goPrev,
    "getStep": _vm.getStep,
    "goTo": _vm.goTo,
    "currentStep": _vm.currentStep
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }