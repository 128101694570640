import OrdersService from "@/core/services/risk/orders.service"

const cart = {
  namespaced: true,
  state: {
    items: [],
    currency: '€',
    durations: [{
      text: 'Monthly',
      value: 'monthly'
    }, {
      text: 'Three Months',
      value: 'three_months'
    }, {
      text: 'Six Months',
      value: 'six_months'
    }, {
      text: 'Yearly',
      value: 'yearly',
    }],
    productTypes: {
      'report': 'd1bd241c-842e-4056-8c95-74a7b1a958e0',
      'map': 'd75501fd-27f5-4b18-bd26-b2b314d0eaff'
    },
    priceMap: {},
    id: null,
    changed: false,
  },
  mutations: {
    addItem(state, item) {
      item.duration = state.durations[0].value // AGGIUNTA DURATION DI TIPO MOUNTLY

      // Se sto aggiungendo il padre devo togliere tutti i figli
      if (!state.items.some(prod => item.id == prod.id)) {
        state.items.push(item)
        state.changed = true
      }
    },
    addParentItem(state, {
      item,
      children
    }) {
      item.duration = state.durations[0].value // AGGIUNTA DURATION DI TIPO MOUNTLY

      // Se sto aggiungendo il padre devo togliere tutti i figli
      if (!state.items.some(prod => item.id == prod.id)) {
        children.forEach(el => { // Per ogni figlio capisco se è da rimuovere
          let itemToRemove = state.items.find(child => {
            if (child?.categories?.[0])
              return el.id == child.categories[0]
            else
              return false
          })
          if (itemToRemove) {
            const indexToRemove = state.items.findIndex((el) => el.id === itemToRemove.id);
            if (indexToRemove !== -1) {
              state.items.splice(indexToRemove, 1);
              state.changed = true
            }
          }
        })
        state.items.push(item)
        state.changed = true
      }
    },
    removeItem(state, id) {
      const indexToRemove = state.items.findIndex((el) => el.id === id);
      if (indexToRemove !== -1) {
        state.items.splice(indexToRemove, 1);
        state.changed = true
      }
    },
    setDuration(state, {
      duration,
      i
    }) {
      state.items[i].duration = duration
    },
    setId(state, id) {
      state.id = id
    },
    removeAllItems(state) {
      state.items = []
    },
    reset: () => {}
  },
  actions: {
    async addItem({
        state,
        dispatch,
        commit
      },
      item) {
      commit("addItem", item)
      if (state.changed) {
        await dispatch('saveOrder')
        state.changed = false;
      }
    },
    async addParentItem({
      state,
      dispatch,
      commit
    }, data) {

      commit("addParentItem", data)
      if (state.changed) {
        await dispatch('saveOrder')
        state.changed = false;
      }
    },
    async removeItem({
      state,
      dispatch,
      commit
    }, id) {
      commit("removeItem", id)
      if (state.changed) {
        await dispatch('saveOrder')
        state.changed = false;
      }
    },
    setDuration({
      commit
    }, payload) {
      commit("setDuration", payload)
    },
    setId({
      commit
    }, id) {
      commit("setId", id)
    },
    removeAllItems({
      commit
    }) {
      commit("removeAllItems")
    },
    initCart({
      state
    }, { userId, companyId }) {
      if (!userId || !companyId) {
        console.error('Impossibile eseguire initCart', userId, companyId)
        return
      }

      // Get orders draft
      state.items.splice(0, state.items.length);
      state.id = null;

      OrdersService.getAll({
          createdBy: userId,
          focus: companyId,
          status: "draft",
          currentPage: 1,
          perPage: 1000
      }).then(raw_response => {
          if (raw_response.results.length) { // possiede degli ordini?
          raw_response = raw_response.results[0]
          state.id = raw_response.id; // se backend non esiste?

          raw_response.items.forEach(element => {
            let item = element.attributes
            state.items.push(item)
            // Get Price
            let params = {
              productType: state.productTypes[item.type],
              country: item.countries[0],
            }
            if (item.categories?.[0])
              params.category = item.categories[0];

            OrdersService.getProductPrice(params).then(res => {
              state.priceMap[item.id] = res
            }).catch(err => {
              console.log('@getPrices initCart', err)
            })
          })
        } else {
          // se non c'è un ordine draft ne crea uno e lo assegna all'utente
          // Il focus viene sovrascritto da backend
          OrdersService.checkout({ status: 'draft', items: [] }, state.id).then(res => {
            state.id = res.id; // nuovo id del nuovo ordine
          })
        }
      }).catch(err => {
        console.log('@initCart: errore', err)
      })
    },
    saveOrder({
      state
    }) {
      let body = {
        items: []
      }

      state.items.forEach(item => {
        let attributes = item;
        attributes.date_range = item.duration;
        attributes.monitoring = true

        // possiede categorie solo se è un report
        let element = {
          product_type: state.productTypes[item.type],
          quantity: 1,
          attributes: attributes
        }
        body.items.push(element);
        // Se non ho i prezzi dell'elemento li aggiugno
        if (!state.priceMap[item.id]) {
          let params = {
            productType: state.productTypes[item.type],
            country: item.countries[0],
          }
          if (item.categories?.[0])
            params.category = item.categories[0];

          OrdersService.getProductPrice(params).then(res => {
            state.priceMap[item.id] = res
          }).catch(err => {
            console.log('@getPrices saveOrder', err)
          })
        }
      })

      OrdersService.checkout(body, state.id)
    },
  },
  getters: {
    getItemsForCountry(state) {
      let items = {}
      state.items.forEach(element => {
        if (items.hasOwnProperty(element.country)) {
          items[element.country].push(element)
        } else {
          items[element.country] = [
            element
          ]
        }
      });

      return items
    },
    getItems(state) {
      return state.items
    },
    getDurationTextFromValue: (state) => (value) => {
      let res = state.durations.find(duration => value == duration.value)
      return res ? res.text : "Once"
    }
  }
}

export default cart