import api from "@/core/services/api.service";

function addressFromGooglePlace(googlePlace) {
  const out = {
    locality: "",
    state: "",
    country_code: "",
    country: "",
    province: "",
    postal_code: "",
    route: "",
    street_number: "",
    latitude: "",
    longitude: "",
    formatted: "",
    manual_address: false
  };

  if (!googlePlace) return out;

  out.locality =
    googlePlace.address_components.find(ac => ac.types.includes("locality"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_5"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_4"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_3"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_2"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_1"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("country"))?.short_name;
  out.state = googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_1"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_2"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_3"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_4"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("administrative_area_level_5"))?.short_name ||
    googlePlace.address_components.find(ac => ac.types.includes("country"))?.short_name;
  out.province = googlePlace.address_components.find((ac) => ac.types[0] == "administrative_area_level_2")?.long_name;
  out.country_code = googlePlace.address_components.find((ac) => ac.types[0] == "country")?.short_name;
  out.country = googlePlace.address_components.find((ac) => ac.types[0] == "country")?.long_name;
  out.postal_code = googlePlace.address_components.find((ac) => ac.types[0] == "postal_code")?.long_name;
  out.route = googlePlace.address_components.find((ac) => ac.types[0] == "route")?.long_name;
  out.street_number = googlePlace.address_components.find((ac) => ac.types[0] == "street_number")?.long_name;
  out.latitude =
    typeof googlePlace.geometry.location.lat == "function" ?
    googlePlace.geometry.location.lat() :
    googlePlace.geometry.location.lat;
  out.longitude =
    typeof googlePlace.geometry.location.lng == "function" ?
    googlePlace.geometry.location.lng() :
    googlePlace.geometry.location.lng;
  out.formatted = googlePlace.formatted_address;
  out.manual_address = googlePlace.manual_address;

  return out;
}

function googlePlaceFromApi(apiAddress) {
  if (!apiAddress) {
    return {
      address_components: [],
      formatted_address: "",
      geometry: {
        location: {
          lat: null,
          lng: null,
        },
      },
      place_id: null,
    };
  }
  const out = {
    address_components: [{
        long_name: apiAddress?.street_number ?? "",
        short_name: apiAddress?.street_number ?? "",
        types: ["street_number"],
      },
      {
        long_name: apiAddress?.route ?? "",
        short_name: apiAddress?.route ?? "",
        types: ["route"]
      },
      {
        long_name: apiAddress?.locality?.state?.country?.name ?? "",
        short_name: apiAddress?.locality?.state?.country?.code ?? "",
        types: ["country"],
      },
      {
        long_name: apiAddress?.locality?.state?.name ?? "",
        short_name: apiAddress?.locality?.state?.name ?? "",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: apiAddress?.locality?.province ?? "",
        short_name: apiAddress?.locality?.province ?? "",
        types: ["administrative_area_level_2", "political"],
      },
      {
        long_name: apiAddress?.locality?.name ?? "",
        short_name: apiAddress?.locality?.name ?? "",
        types: ["administrative_area_level_3", "political"],
      },
      {
        long_name: apiAddress?.country ?? "",
        short_name: apiAddress?.country ?? "",
        types: ["country", "political"]
      },
      {
        long_name: apiAddress?.locality?.postal_code ?? "",
        short_name: apiAddress?.locality?.postal_code ?? "",
        types: ["postal_code"],
      },
    ],
    formatted_address: apiAddress.formatted,
    geometry: {
      location: {
        lat: apiAddress.latitude,
        lng: apiAddress.longitude
      }
    },
    place_id: apiAddress.id,
  };

  return {
    ...out,
    ...apiAddress
  };
}

function addressFromManualPlace(address) {
  return {
    locality: address.locality.name,
    state: address.locality.state.name,
    country_code: address.locality.state.country.code,
    country: address.locality.state.country.name,
    province: address.locality.province,
    postal_code: address.locality.postal_code,
    route: address.route,
    street_number: address.street_number,
    latitude: address.latitude,
    longitude: address.longitude,
    formatted: address.formatted,
  }
}

class addressService {
  get PATH() {
    return "address";
  }

  /**
   *
   * @param {Object[]} addresses
   * @returns
   */
  async getOrCreateAddress(addresses, multiple = true) {
    const addressesPromise = await Promise.all(
      addresses.map((element) => {
        if (!element.manual_address) {
          if (!element.place_id) {
            element.address = this.getGooglePlaceFromApi(element.address);
          }
          return addressFromGooglePlace(element.address);
        }
        return {
          ...element.composed_address,
          manual_address: element.manual_address
        };
      }),
    );

    let data = null
    if(multiple) {
      data = addressesPromise
    } else {
      data = addressesPromise[0]
    }
    const r = await api.post(`${this.PATH}/get_or_create_address/`, data, {
      params: {
        multiple: multiple
      }
    });
    return r.data.address;
  }

  getGooglePlaceFromApi(apiAddress) {
    return googlePlaceFromApi(apiAddress);
  }

  getAddressFromManualPlace(address) {
    return addressFromManualPlace(address);
  }

  async getGeocodedPlaceFromApiAddress(apiAddress) {
    const geocoder = new google.maps.Geocoder();

    const res = await geocoder.geocode({
      location: {
        lat: apiAddress.latitude,
        lng: apiAddress.longitude,
      },
    });

    return res.results[0];
  }

  async getByID(id) {
    const r = await api.get(`${this.PATH}/${id}/`);
    return r.data;
  }
}

export default new addressService();