var render = function render(){
  var _vm$_b;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-8"
  }, [_c('google-maps-autocomplete', _vm._b({
    ref: "autocomplete",
    attrs: {
      "autocomplete-options": {
        componentRestrictions: _vm.restriction
      },
      "disabled": _vm.manual
    },
    model: {
      value: _vm.place,
      callback: function callback($$v) {
        _vm.place = $$v;
      },
      expression: "place"
    }
  }, 'google-maps-autocomplete', (_vm$_b = {
    description: _vm.hint,
    label: _vm.label,
    'valid-feedback': _vm.validFeedback,
    state: _vm.state,
    'invalid-feedback': _vm.invalidFeedback,
    'label-cols': _vm.horizontal ? 3 : null
  }, _vm$_b["state"] = _vm.state, _vm$_b), false))], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('div', {
    staticClass: "form-group mb-0 mt-12"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('label', {
    staticClass: "col-form-label text-right"
  }, [_vm._v(_vm._s(_vm.$t("Manual editing")))]), _c('div', {
    staticClass: "ml-3"
  }, [_c('span', {
    staticClass: "switch switch-sm switch-icon"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.manual,
      expression: "manual"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.manual) ? _vm._i(_vm.manual, null) > -1 : _vm.manual
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.manual,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.manual = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.manual = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.manual = $$c;
        }
      }
    }
  }), _c('span')])])])])])]), _vm.manual ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "alert alert-custom alert-light-info px-4 py-3 mt-4",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "alert-text"
  }, [_vm._v(_vm._s(_vm.$t("Move the marker to the correct position and fill in the address information")) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v("Map")]), _c('div', {
    staticClass: "card mb-4"
  }, [_c('google-maps-map', {
    attrs: {
      "map-options": _vm.mapOptions,
      "markers": _vm.markers,
      "height": "280px"
    },
    on: {
      "marker-dragend": _vm.onMarkerDragend
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 text-center"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Latitude")))]), _c('div', [_vm._v(_vm._s(_vm.placeLat))])]), _c('div', {
    staticClass: "col-6 text-center"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Longitude")))]), _c('div', [_vm._v(_vm._s(_vm.placeLong))])])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Street")) + "*")]), _c('validation-provider', {
    ref: "address_line",
    attrs: {
      "name": _vm.$t('Street'),
      "vid": "address_line",
      "rules": {
        required: _vm.requiredRule
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          model: {
            value: _vm.selectedAddressLine,
            callback: function callback($$v) {
              _vm.selectedAddressLine = $$v;
            },
            expression: "selectedAddressLine"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("City")) + "*")]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('City'),
      "vid": "city",
      "rules": {
        required: _vm.requiredRule
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          model: {
            value: _vm.selectedCity,
            callback: function callback($$v) {
              _vm.selectedCity = $$v;
            },
            expression: "selectedCity"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Country")) + "*")]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": {
        required: _vm.requiredRule
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          model: {
            value: _vm.selectedCountry,
            callback: function callback($$v) {
              _vm.selectedCountry = $$v;
            },
            expression: "selectedCountry"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Region")) + "*")]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Region'),
      "vid": "region",
      "rules": {
        required: _vm.requiredRule
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          model: {
            value: _vm.selectedRegion,
            callback: function callback($$v) {
              _vm.selectedRegion = $$v;
            },
            expression: "selectedRegion"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Province")))]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Province'),
      "vid": "province"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          model: {
            value: _vm.selectedProvince,
            callback: function callback($$v) {
              _vm.selectedProvince = $$v;
            },
            expression: "selectedProvince"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Street number")))]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Street number'),
      "vid": "street_number"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          on: {
            "input": _vm.clearAddressInput
          },
          model: {
            value: _vm.selectedStreetNumber,
            callback: function callback($$v) {
              _vm.selectedStreetNumber = $$v;
            },
            expression: "selectedStreetNumber"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.$t("Postal code")))]), _c('validation-provider', {
    attrs: {
      "name": _vm.$t('Postal code'),
      "vid": "postal_code"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "fake-input": !_vm.manual
          },
          model: {
            value: _vm.selectedPostal,
            callback: function callback($$v) {
              _vm.selectedPostal = $$v;
            },
            expression: "selectedPostal"
          }
        })];
      }
    }])
  })], 1)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "alert alert-custom alert-outline-2x alert-outline-primary fade show mt-4",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-warning"
  })]), _c('div', {
    staticClass: "alert-text"
  }, [_vm._v("Search for your address in the address bar, if you can't find your address, switch to manual editing mode, move the marker to the correct position and fill in the address information.")])])]);

}]

export { render, staticRenderFns }