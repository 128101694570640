import api from "../api.service";

class NpgAdministrativeStructureService {
  get PATH() {
    return "nations-administrativestructure";
  }

  async getAll() {
    return await this.getPaginated(1000, 0);
  }

  async getPaginated(limit, offset) {
    const res = await api.get(`${this.PATH}/`, { params: { limit, offset } });
    return res.data;
  }

  async getOne(id) {
    return (await api.get(`${this.PATH}/${id}`)).data;
  }

  async getByJurisdiction(territorialJurisdiction) {
    return (await api.get(`${this.PATH}/`, { params: { territorial_jurisdiction: territorialJurisdiction } })).data;
  }
}

export default new NpgAdministrativeStructureService();
