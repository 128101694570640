<template>
  <div class="avatar" :class="{ 'rounded-image': rounded }" :style="{ width: size, height: size }">
    <img :src="src" alt="avatar" :style="{ width: size, height: size }" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "40px",
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.rounded-image {
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  & img {
    object-fit: cover;
    object-position: center;
  }

  background-color: #E1E9FF;
  color: #6993FF;
  border-radius: 0.42rem;
  overflow: hidden;
}
</style>
