var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: {
      height: _vm.height,
      width: _vm.width
    },
    attrs: {
      "id": _vm.mapId
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }