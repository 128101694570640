<template>
  <div class="avatar" :style="{ width: size, height: size }">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "40px",
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.4rem;
  word-break: break-all;
  text-align: center;
  text-transform: uppercase;
  background-color: #E1E9FF;
  color: #6993FF;
  border-radius: 0.42rem;
  overflow: hidden;
}
</style>
