import NationsCountryService from "@/core/services/nation/nations-country.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import PhysicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";
import NationLanguageService from "@/core/services/nation/nation-language.service";
import { getNotificationLevels } from "@/core/abstractions/notifications";
import i18n from "@/core/plugins/vue-i18n";

const constants = {
  namespaced: true,

  state: {
    countries: [],
    groups: [],
    secondmentStatuses: [],
    companyAddressKinds: [],
    physicalAddressKinds: [],
    languages: [],
    languageLevels: [],
    educationKinds: [],
    administrativeStructures: [],
    a1ModuleCertificateKinds: [],
    drivingLicenseCodeKinds: [],
    a1ModuleCountriesIso: [],
    notificationLevelsMap: [],
    version: "0.24",
    api_version: "",
    PRODUCT_TYPES: {
      COUNTRY_REPORT: "d1bd241c-842e-4056-8c95-74a7b1a958e0",
      RISK_MAP: "d75501fd-27f5-4b18-bd26-b2b314d0eaff",
      ATLASPOSTING_SEAT: "ae3fdb5c-eb82-4076-90d6-c71005ddd05f",
      POSTING_CERTIFICATE: "104eaf97-8db9-47b6-836c-c58e1aa99762", // MODULO A1
      DECLARATION_OF_DETACHMENT: "49c2f716-23cc-4920-9692-b7c83d03ced5",
      TRANSPARENCY_LETTER: "e8c2f28f-3d05-4a76-8c93-36e9fed0becc",
      IMMIGRATION_ASSESSMENT: "83eec605-398d-4567-8430-47399687621f",
      RISK_ASSESSMENT: "b4f05550-84f1-460c-90a4-771f1be08776",
      HEALTHCARE_REPORT: "cd10df47-f3bb-477e-8f86-2e33e838b925",
      GOVERNMENT_TRAVEL_COMMUNICATION_SERVICE:
        "278d0510-da7b-4991-b6d8-b75d4c4c6ce1",
      VISA_SERVICE: "7db06d34-e2bd-477d-88cb-956d6c71cac1",
    },
    POSTING_CERTIFICATE_KIND: {
      ART12: "ART12",
      ART13: "ART13",
    },
    maintenance_windows: [],
  },

  actions: {
    async loadConstants({ commit }) {
      try {
        const [
          countries,
          groups,
          companyAddressKinds,
          physicalAddressKinds,
          notificationLevels,
          languages,
        ] = await Promise.all([
          NationsCountryService.getAll(true),
          NationsCountryService.getGroups(),
          CompanyAddressService.getKinds(),
          PhysicalPersonAddressService.getKinds(),
          getNotificationLevels(),
          NationLanguageService.getAll(),
        ]);
        commit("SET_COUNTRIES", countries.results);
        commit("SET_GROUPS", groups.results);
        commit("SET_COMPANY_ADRESS_KINDS", companyAddressKinds);
        commit("SET_PHYSICAL_ADDRESS_KINDS", physicalAddressKinds);
        commit("SET_NOTIFICATION_LEVELS", notificationLevels);
        commit("SET_LANGUAGES", languages.results);
      } catch (err) {
        console.log("failed to load constants", err);
      }
    },
  },

  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },

    SET_GROUPS(state, groups) {
      state.groups = groups;
    },

    SET_SECONDMENT_STATUSES(state, statuses) {
      state.secondmentStatuses = statuses;
    },

    SET_COMPANY_ADRESS_KINDS(state, kinds) {
      state.companyAddressKinds = kinds;
    },

    SET_PHYSICAL_ADDRESS_KINDS(state, kinds) {
      state.physicalAddressKinds = kinds;
    },

    SET_LANGUAGES(state, languages) {
      state.languages = languages;
    },

    SET_LANGUAGE_LEVELS(state, languageLevels) {
      state.languageLevels = languageLevels;
    },

    SET_EDUCATION_KINDS(state, educationKinds) {
      state.educationKinds = educationKinds;
    },

    SET_A1_MODULE_CERTIFICATE_KINDS(state, a1ModuleCertificateKinds) {
      state.a1ModuleCertificateKinds = a1ModuleCertificateKinds;
    },

    SET_DRIVING_LICENSE_CODE_KINDS(state, kinds) {
      state.drivingLicenseCodeKinds = kinds;
    },

    SET_A1_MODULE_COUNTRIES_ISO(state, isos) {
      state.a1ModuleCountriesIso = isos;
    },

    SET_NOTIFICATION_LEVELS(state, levels) {
      state.notificationLevelsMap = levels;
    },
    SET_API_VERSION(state, api_version) {
      state.api_version = api_version;
    },
    SET_MAINTENANCE_WINDOWS(state, windows) {
      state.maintenance_windows = windows;
    },
    reset: () => {},
  },

  getters: {
    countriesWithSecondmentService(state) {
      return state.countries.filter((x) => x?.secondments?.service_available);
    },

    countryNameFromIso: (state) => (iso) => {
      return state.countries.find((el) => el.iso === iso)?.name;
    },

    countryFromIso: (state) => (iso) => {
      return state.countries.find((el) => el.iso === iso);
    },

    a1ModuleCountries(state, getters) {
      const out = [];

      state.a1ModuleCountriesIso.forEach((iso) => {
        const country = getters.countriesMap[iso];
        if (country) {
          out.push(country);
        }
      });

      return out;
    },

    countriesMap(state) {
      const out = {};

      state.countries.forEach((country) => {
        out[country.iso] = country;
      });

      return out;
    },

    secondmentStatusesMap(state) {
      return state.secondmentStatuses.reduce((acc, curr) => {
        acc[curr.value] = curr.display_name;
        return acc;
      }, {});
    },

    languagesMap(state) {
      return state.languages.reduce((acc, curr) => {
        acc[curr.iso_639_1] = curr;
        return acc;
      }, {});
    },

    languageLevelsMap(state) {
      return state.languageLevels.reduce((acc, curr) => {
        acc[curr.value] = curr.display_name;
        return acc;
      }, {});
    },

    a1ModuleCertificateKindsMap(state) {
      return state.a1ModuleCertificateKinds.reduce((acc, curr) => {
        acc[curr.value] = curr.display_name;
        return acc;
      }, {});
    },

    drivingLicenseCodeKindsMap(state) {
      return state.drivingLicenseCodeKinds.reduce((acc, curr) => {
        acc[curr.value] = curr.display_name;
        return acc;
      }, {});
    },

    getCountries(state) {
      return state.countries?.map((el) => ({
        text: i18n.t(el.name),
        value: el.iso,
      }));
    },

    europeanGroup(state) {
      return state.groups.find((group) => group.id == 1)?.nationingroup_set;
    },

    isEUCountry: (state) => (country) => {
      return state.groups
        .find((group) => group.id == 1)
        ?.nationingroup_set?.some((c) => c.country === country);
    },

    ehicGroup(state) {
      return state.groups.find((group) => group.id == 3)?.nationingroup_set;
    },

    schengenGroup(state) {
      return state.groups.find((group) => group.id == 2)?.nationingroup_set;
    },

    moduleA1Group(state) {
      return state.groups.find((group) => group.id == 4)?.nationingroup_set;
    },
    
    getMaintenanceWindows(state) {
      return state.maintenance_windows;
    }
  },
};

export default constants;
