import api from "../api.service";
import addressService from "@/core/services/address.service";
const path = "physicalperson/";

class PhysicalPersonService {
  async getAll({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    employeeFilter = null,
    fields = null,
    expand = null,
    omit = null,
    employeeContract = null,
    is_user = null,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      focus: true,
      is_employee: employeeFilter,
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    if (employeeContract !== null) {
      params.have_employee_contract = employeeContract;
    }
    if (is_user !== null) {
      params.is_user = is_user;
    }

    const res = await api.get("physicalperson/", {
      params,
    });
    return res.data;
  }

  async getAllInvited({
    perPage,
    page,
    sortBy,
    search,
    sortDesc,
    employeeFilter,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      focus: true,
      is_employee: employeeFilter,
      is_user: false,
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get("physicalperson/", {
      params,
    });
    return res.data;
  }

  async getAllNotPaginated(select) {
    const params = {
      focus: true,
    };
    if (select) {
      params.fields = "id,name,surname";
    }
    const res = await api.get("physicalperson/", {
      params: params,
    });
    return res.data;
  }

  async getOne(id, all = null, fields = null, expand = null, omit = null) {
    const path = `physicalperson/${id}/`;
    let params = {};

    if (all) {
      params.all = true;
    }

    if (omit && omit.length) {
      params.omit = omit;
    }

    if (fields && fields.length) {
      params.fields = fields;
    }

    if (expand && expand.length) {
      params.expand = expand;
    }
    return await api.get(path, {
      params: params,
    });
  }

  /**
   * Gets a physicalPerson by ID without converting the addresses to GogglePlace object.
   */
  async getOneOldAddressFormat(id) {
    const res = await api.get(`physicalperson/${id}/`);
    return res.data;
  }

  async create(body) {
    let { place_birth, physicalpersonaddress_set } = body;
    // place_birth
    if (place_birth) {
      const convertedAddresses = await addressService.getOrCreateAddress(
        [
          {
            address: place_birth,
            manual_address: false,
          },
        ],
        false
      );
      body.place_birth = convertedAddresses.id;
    }
    // physicalpersonaddress_set
    if (physicalpersonaddress_set && physicalpersonaddress_set.length) {
      const newAddressSet = {
        main: true,
        description: "string",
        kind: 0,
        address: 0,
        manual_address: false,
        composed_address: null,
      };
      let addresses = [];
      physicalpersonaddress_set.forEach((el) => {
        (newAddressSet.main = el.main),
          (newAddressSet.description = el.description),
          (newAddressSet.kind = el.kind),
          (newAddressSet.address = el.address);
        newAddressSet.manual_address = el.manual_address;
        newAddressSet.composed_address = el.composed_address;
        addresses.push(el);
      });
      const convertedAddresses = await addressService.getOrCreateAddress(
        addresses,
        false
      );
      newAddressSet.address = convertedAddresses.id;
      body.physicalpersonaddress_set = [newAddressSet];
    }

    const res = await api.post("physicalperson/", body);
    return res.data;
  }

  async delete(id) {
    await api.delete(`physicalperson/${id}`);
  }

  async update(body, id) {
    const res = await api.patch(`physicalperson/${id}/`, body);
    return res.data;
  }

  async getDocuments({
    id,
    perPage,
    page,
    sortBy,
    search,
    sortDesc,
    fields = null,
    expand = null,
    omit = null,
    expiring=false
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      expiring,
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get(`physicalperson/${id}/get_documents/`, { params });
    return res.data;
  }

  async individualCompany(physicalPersonId, vat) {
    const body = {
      extracompanyvat_set: [vat],
    };

    const res = await api.post(
      `physicalperson/${physicalPersonId}/individual_company/`,
      body
    );
    return res.data;
  }

  async updateDate(person) {
    if (person.place_birth) {
      person.place_birth = await addressService.getGooglePlaceFromApi(
        person.place_birth
      );
    }

    if (person.physicalpersonaddress_set) {
      person.physicalpersonaddress_set = person.physicalpersonaddress_set.map(
        (el) => {
          el.original = {
            ...el.address,
          };
          el.address = addressService.getGooglePlaceFromApi(el.original);
          if (el.original.manual_address) {
            el.composed_address = addressService.getAddressFromManualPlace(
              el.original
            );
          }
          el.manual_address = el.original.manual_address;
          return el;
        }
      );
    }
    return person;
  }

  async setRoleSecondment(id, params) {
    const res = await api.patch(`physicalperson/${id}/`, params);
    return res.data;
  }

  async getGroup(physicalPersonsId, all) {
    const params = {
      physicalperson_ids: physicalPersonsId,
      all: all,
    };
    const res = await api.get(path, { params });
    return res.data;
  }
}

export default new PhysicalPersonService();
