<template>
  <div :class="{ 'frame': frame }">
    <image-avatar v-if="avatarImage" :rounded="rounded" :src="avatarImage" :size="size"></image-avatar>
    <text-avatar v-else :text="avatarText" :size="size"></text-avatar>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "40px",
    },
    avatarImage: {
      type: String,
      default: "",
    },
    avatarText: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: false
    },
    frame: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>
.frame {
  border: 3px solid white;
}
</style>
