<template>
  <button class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-2" data-wizard-type="action-prev"
    @click.prevent="$emit('click')">
    {{ $t("Previous") }}
  </button>
</template>

<script>
export default {
  props: {
    showSpinner: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
