var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row mb-0"
  }, [_vm.label ? _c('div', {
    staticClass: "col-12"
  }, [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]) : _vm._e(), _vm._l(_vm.emails, function (email, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex align-items-center element"
    }, [_c('div', {
      staticClass: "col-xl-3"
    }, [_c('b-form-select', {
      staticClass: "form-control form-control-solid h-auto",
      attrs: {
        "options": _vm.options
      },
      model: {
        value: email.kind,
        callback: function callback($$v) {
          _vm.$set(email, "kind", $$v);
        },
        expression: "email.kind"
      }
    })], 1), _c('div', {
      staticClass: "col-xl-7"
    }, [_c('validation-observer', {
      ref: "validation-email-".concat(idx),
      refInFor: true,
      attrs: {
        "tag": "div"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": _vm.$t('Email'),
        "vid": "email[".concat(idx, "]"),
        "rules": {
          min: 3,
          email: true,
          personal_email_unique: !_vm.isFormSubmitted && idx === _vm.emails.length - 1 ? _vm.computedMailList : false,
          custom_mail: [email]
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('euro-input', {
            attrs: {
              "placeholder": _vm.$t('Email'),
              "type": "email",
              "parent-class": false,
              "error-messages": errors
            },
            model: {
              value: email.email,
              callback: function callback($$v) {
                _vm.$set(email, "email", $$v);
              },
              expression: "email.email"
            }
          })];
        }
      }], null, true)
    })], 1), !_vm.valid ? _c('div', {
      staticClass: "invalid-feedback d-block my-2 px-4",
      domProps: {
        "textContent": _vm._s(_vm.invalidFeedback)
      }
    }) : _vm._e(), _vm.hint ? _c('span', {
      staticClass: "form-text text-muted px-4"
    }, [_vm._v(_vm._s(_vm.hint))]) : _vm._e()], 1), _c('div', {
      staticClass: "col-xl-2"
    }, [_c('div', {
      staticClass: "d-flex justify-content-center align-items-center m-n1"
    }, [_c('div', {
      staticClass: "d-flex justify-content-start"
    }, [email.main ? _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "fas fa-circle ml-3",
      style: {
        color: '#00A9BD'
      },
      attrs: {
        "title": _vm.$t('Main')
      }
    }) : _c('div', {
      staticClass: "ml-7"
    })]), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.getTooltipMessage(email),
        "icon": "fas fa-hand-point-up",
        "size": "xs",
        "disabled": !email.email
      },
      on: {
        "click": function click($event) {
          return _vm.setMain(email);
        }
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "icon": "flaticon2-plus",
        "size": "xs",
        "tooltip": _vm.$t('Add new email'),
        "disabled": _vm.emails.length === 1 && !_vm.emails[0].email
      },
      on: {
        "click": _vm.addNewEmail
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "icon": "flaticon2-cross",
        "size": "xs",
        "tooltip": _vm.$t('Remove email'),
        "disabled": _vm.emails.length === 1 && !_vm.emails[0].email
      },
      on: {
        "click": function click($event) {
          return _vm.removeEmail(idx);
        }
      }
    })], 1)])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }