export default {
  download: "fas fa-download",
  priceList: "fas fa-file-invoice-dollar",
  remove: "fas fa-minus",
  ban: "fas fa-ban",
  cartAdd: "fas fa-cart-plus",
  cart: "fas fa-shopping-cart",
  at: "fas fa-at",
  phone: "fas fa-phone",
  totalDays: "fas fa-calendar-check",
  country: "fas fa-globe-americas",
  client: "fas fa-user-tie",
  fileInsurance: "fas fa-file-medical",
  fileBriefcaseInsurance: "fas fa-briefcase-medical",
  file: "fas fa-file",
  fileAlt: "fas fa-file-alt",
  idDocument: "fas fa-id-card",
  hotel: "fas fa-hotel",
  housing: "fas fa-hotel",
  restaurant: "fas fa-utensils",
  add: "fas fa-plus",
  employee: "fas fa-users",
  users: "fas fa-users-cog",
  notify: "far fa-bell",
  nextStep: "fas fa-step-forward",
  prevStep: "fas fa-step-backward",
  saveDraft: "fas fa-hdd",
  save: "fas fa-save",
  edit: "flaticon2-edit",
  undo: "fas fa-undo-alt",
  search: "flaticon-search ",
  contract: "flaticon2-list-2 icon-xl",
  fileContract: "fas fa-file-contract",
  ellipsisV: "fas fa-ellipsis-v",
  circleFilled: "fas fa-circle",
  caretUp: "fas fa-caret-up",
  caretDown: "fas fa-caret-down",
  calendar: "far fa-calendar-alt",
  arrival: "fas fa-plane-arrival",
  plane: "fas fa-plane-arrival",
  train: "fas fa-train",
  car: "fas fa-car",
  boat: "fas fa-ship",
  departure: "fas fa-plane-departure",
  passport: "fas fa-passport",
  chart: "far fa-chart-bar",
  food: "fas fa-utensils",
  check: "fas fa-check",
  warning: "fas fa-exclamation-triangle",
  email: "flaticon-email",
  sendMail: "far fa-paper-plane",
  map: "fas fa-map-marked-alt",
  delete: "flaticon-delete-1",
  bill: "fas fa-money-bill",
  emptyBill: "far fa-money-bill-alt",
  exchange: "fas fa-exchange-alt",
  spinner: "fas fa-spinner fa-spin",
  close: "fas fa-times",
  document: "flaticon2-document",
  infoCircle: "fas fa-info-circle",
  eye: "fas fa-eye",
  envelopeOpen: "fas fa-envelope-open",
  envelopeClose: "fas fa-envelope",
  lineAwesome: {
    danger: "la la-exclamation-triangle",
    grid: "la la-border-all",
    list: "la la-list",
    shop: "la la-store",
    info: "las la-info",
    add: "las la-plus",
    manage: "las la-wrench",
    account: "la la-user-circle",
    company: "las la-building",
    physicalPerson: "la la-user-tag",
    lock: "las la-lock",
    secondment: "la la-toolbox",
    legalPerson: "la la-industry",
    dashboard: "la la-dashboard",
    role: "la-user-tag",
  },
  list: "fas fa-list",
  clone: "fas fa-clone",
  sync: "fas fa-sync",
  pen: "fas fa-pen",
  circle: "fas fa-circle",
  check_circle: "fas fa-check-circle",
  file_signature: "fas fa-file-signature",
  bookmark: "far fa-bookmark",
  clipboard_check: "fas fa-clipboard-check",
  plane_arrival: "fas fa-plane-arrival",
  invoiceDollar: "fas fa-file-invoice-dollar"
};