<template>
  <span :id="btnRef" class="inline-block">
    <b-button :ref="btnRef" :size="size" class="btn-hover-primary btn-icon" :disabled="disabled" :pressed="pressed"
      :squared="squared" @click="onBtnClick">
      <i :class="iconClasses"></i>
    </b-button>
    <b-tooltip v-if="tooltip" :target="btnRef" noninteractive>{{ tooltip }}</b-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "xs",
    },
    icon: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pressed: {
      type: Boolean,
      default: null,
    },
    squared: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconClasses() {
      return [this.icon, this.disabled ? "text-muted" : "text-primary"];
    },

    btnRef() {
      return `btn-icon-${this._uid}`;
    },
  },

  methods: {
    onBtnClick(e) {
      this.$emit("click", e);
      this.$refs[this.btnRef].blur();
    },
  },
};
</script>
