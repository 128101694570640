<template>
  <li aria-haspopup="true" :data-menu-toggle="menuToggle" class="menu-item menu-item-submenu menu-item-open-dropdown"
    :class="{ 'menu-item-active': hasActiveChildren(menuItemActive) }">
    <routerLink :to="action" class="menu-link menu-toggle">
      <span class="menu-text">{{ label }}</span>
    </routerLink>
    <div class="menu-submenu" :class="isClassic ? 'menu-submenu-classic' : 'menu-submenu-fixed'">
      <ul class="menu-subnav">
        <slot></slot>
      </ul>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.menu-nav-label {
  text-align: center;
  font-size: .9rem;
}
</style>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    // It can be either 'hover' or 'click'
    menuToggle: {
      type: String,
      default: "hover",
    },
    menuItemActive: {
      type: String,
      default: "",
    },
    isClassic: {
      type: Boolean,
      default: false,
    },
    action: {
      type: [String, Object],
      default: '#'
    }
  },

  methods: {
    hasActiveChildren(match) {
      if (!match) return false;
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<style scoped>
.navigation-icon {
  color: var(--primary)
}

@media screen and (min-width: 992px) {
  .navigation-icon {
    color: #fff;
  }
}
</style>
