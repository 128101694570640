var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3",
    attrs: {
      "data-wizard-type": "action-prev"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('click');
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md mr-2"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/".concat(_vm.icon)
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Previous")) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }