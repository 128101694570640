<template>
  <b-container fluid>
    <b-row v-for="(language, idx) in languages" :key="idx" align-v="start">
      <b-col cols="1">
        <b-form-checkbox v-model="language.certification" class="mt-1" :value="true" :unchecked-value="false">
          Certification
        </b-form-checkbox>
      </b-col>
      <b-col cols="3">
        <euro-select v-model="language.level" :options="languageLevels" placeholder="Language Level"></euro-select>
      </b-col>
      <b-col cols="3">
        <euro-select v-model="language.language" :options="languageList" placeholder="Select a language"></euro-select>
      </b-col>
      <b-col cols="3">
        <euro-file-input v-model="language.attachment" placeholder="File Attachment"></euro-file-input>
      </b-col>
      <b-col cols="2" class="d-flex justify-content-between">
        <btn-icon
          :disabled="!language.attachment_cache"
          tooltip="Download Attachment"
          icon="flaticon2-download"
        ></btn-icon>
        <btn-icon tooltip="Add New Language" icon="flaticon2-plus" @click="addNewLanguage"></btn-icon>
        <btn-icon tooltip="Remove Language" icon="flaticon2-cross" @click="removeLanguage(idx)"></btn-icon>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    languageLevels: {
      type: Array,
      required: true,
    },
    languageList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      languages: [],
    };
  },

  watch: {
    languages: {
      deep: true,
      handler(val) {
        const languages = val.filter(el => el.language || el.level);
        this.$emit("input", languages);
      },
    },
  },

  created() {
    if (this.value.length) {
      this.languages = this.value.map(el => ({ ...el, attachment_cache: el.attachment }));
    } else {
      this.languages = [{ level: "A1", certification: false, attachment: null, language: "" }];
    }
  },

  methods: {
    addNewLanguage() {
      this.languages = [...this.languages, { level: "A1", certification: false, attachment: null, language: "" }];
    },

    removeLanguage(idx) {
      if (this.languages.length === 1) return;
      this.languages.splice(idx, 1);
    },

    getErrorMsg(idx) {
      return this.errorMessages[idx]?.contact;
    },
  },
};
</script>
