<template>
  <div class="form-group row mb-0">
    <div v-if="label" class="col-12">
      <label class="col-form-label">
        {{ label }}
      </label>
    </div>
    <div v-for="(social, idx) in socials" :key="idx" class="d-flex element">
      <div class="col-xl-3">
        <b-form-select v-model="social.kind" :options="options"
          class="form-control form-control-solid selectpicker"></b-form-select>
      </div>
      <validation-observer tag="div" class="col-xl-7">
        <validation-provider v-slot="{ errors }" :name="$t('Social account')" vid="social" :rules="{ url: true }">
          <euro-input v-model="social.contact" :error-messages="errors" :parent-class="false"
            :placeholder="$t('Social account')"></euro-input>
        </validation-provider>
      </validation-observer>
      <div class="col-xl-2">
        <div class="d-flex justify-content-center align-items-center m-n1">
          <div :style="{ width: '3.5ch' }"></div>
          <div :style="{ width: '42.5px' }"></div>
          <btn-icon :tooltip="$t('Add new social contact')" icon="flaticon2-plus" size="xs" class="m-1 py-1 px-2"
            :disabled="socials.length === 1 && !socials[0].contact" @click="addNewSocial"></btn-icon>
          <btn-icon :tooltip="$t('Remove social contact')" icon="flaticon2-cross" size="xs" class="m-1 py-1 px-2"
            :disabled="socials.length === 1 && !socials[0].contact" @click="removeSocial(idx)"></btn-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteSwal } from "@/core/helpers/swal";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      socials: [],
    };
  },

  watch: {
    socials: {
      deep: true,
      handler(val) {
        const socials = val.filter((el) => el.contact);
        this.$emit("input", socials);
      },
    },
  },

  mounted() {
    if (this.value.length) {
      this.socials = this.value;
    } else {
      this.socials = [{ kind: "FAC", contact: "" }];
    }
  },

  methods: {
    addNewSocial() {
      this.socials = [...this.socials, { kind: "FAC", contact: "" }];
    },

    removeSocial(idx) {
      deleteSwal().then((res) => {
        if (res.isConfirmed) {
          if (this.socials.length === 1) {
            this.socials.splice(0, 1, { kind: "FAC", contact: "" });
          } else {
            this.socials.splice(idx, 1);
          }
          this.$emit("updated", this.socials);
        }
      });
    },

    getErrorMsg(idx) {
      return this.errorMessages[idx]?.contact;
    },
  },
};
</script>

<style lang="scss" scoped>
.gap {
  gap: 2rem;
}

.element {
  padding-bottom: 1.25rem;
  width: 100%;
}

.element:last-child {
  padding: 0;
}
</style>
