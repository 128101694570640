var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column-auto flex-column px-10"
  }, [_c('div', {
    staticClass: "wizard-nav pb-lg-7 pb-3 d-flex flex-column align-items-center align-items-md-center"
  }, [_c('div', {
    staticClass: "wizard-steps d-flex flex-column flex-md-row"
  }, [_vm._t("default")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }