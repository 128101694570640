<template>
  <div class="form-group row">
    <label v-if="label" class="col-form-label" :class="{ 'col-2': horizontal, 'col-12': !horizontal }">{{ label }}</label>
    <div :class="{ 'col-10': horizontal, 'col-12': !horizontal }">
      <slot></slot>
      <textarea v-model="vModel" :disabled="disabled" class="form-control form-control-solid h-auto" :class="className"
        :placeholder="placeholder" :debounce="debounce" :rows="rows"></textarea>
      <div v-if="!valid" class="invalid-feedback d-block" v-text="invalidFeedback"></div>
      <span v-if="hint" class="form-text text-muted">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    debounce: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Number,
      default: 6,
    },
  },

  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
