var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.attachment ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2 mb-5"
  }, [_c('div', {
    staticClass: "mr-auto"
  }, [_vm._v(_vm._s(_vm.$t("Current Attachment:")))]), _c('a', {
    staticClass: "btn btn-light-primary btn-sm d-block font-weight-bolder",
    attrs: {
      "href": _vm.attachment,
      "aria-haspopup": "true",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("View Attachment")) + " ")])]) : _vm._e(), _vm._l(_vm.multipleAttachment, function (el) {
    return _c('div', {
      key: el.id,
      staticClass: "d-flex justify-content-between align-items-center mt-2 mb-5",
      attrs: {
        "id": 'tus-multiple-' + el.id
      }
    }, [_c('div', {
      staticClass: "mr-auto attachment-name"
    }, [_vm._v(_vm._s(el.attachment_name))]), _c('a', {
      staticClass: "btn btn-light-primary btn-sm d-block font-weight-bolder",
      attrs: {
        "href": el.attachment,
        "aria-haspopup": "true",
        "target": "_blank"
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md"
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/Files/File.svg"
      }
    })], 1), _vm._v(" " + _vm._s(_vm.$t("View Attachment")) + " ")]), _c('b-button', {
      staticClass: "btn btn-icon btn-hover-primary ml-2",
      attrs: {
        "tooltip": _vm.$t('Remove Attachment')
      },
      on: {
        "click": function click($event) {
          return _vm.remove(el.id);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md svg-icon-primary"
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/General/Trash.svg"
      }
    })], 1)])], 1);
  }), _c('b-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.multiple,
      expression: "!multiple"
    }],
    staticClass: "mb-1",
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols": _vm.horizontal ? 3 : null
    }
  }, [_c('b-form-file', {
    staticClass: "custom-file-upload",
    attrs: {
      "id": "uppy-file-input-".concat(_vm._uid),
      "state": _vm.state,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "accept": _vm.accept
    },
    on: {
      "change": _vm.onFileChange
    }
  }), _c('div', {
    attrs: {
      "id": _vm.statusBarId
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.multiple,
      expression: "multiple"
    }],
    attrs: {
      "id": _vm.statusBarIdMultiple
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }