import Vue from "vue";
import EuroSelect from "@/view/components/inputs/EuroSelect.vue";
import EuroDatePicker from "@/view/components/inputs/EuroDatePicker.vue";
import EuroFileInput from "@/view/components/inputs/EuroFileInput.vue";
import EuroInput from "@/view/components/inputs/EuroInput.vue";
import EuroFileInputCropper from "@/view/components/inputs/EuroFileInputCropper.vue";
import EuroPlaceInput from "@/view/components/inputs/EuroPlaceInput.vue";
import EuroRadio from "@/view/components/inputs/EuroRadio.vue";
import MultiTelephonesInput from "@/view/components/inputs/MultiTelephonesInput.vue";
import TelephoneInput from "@/view/components/inputs/TelephoneInput.vue";
import MultiEmailsInput from "@/view/components/inputs/MultiEmailsInput.vue";
import AddressInput from "@/view/components/inputs/AddressInput.vue";
import AvatarInput from "@/view/components/inputs/AvatarInput.vue";
import MultiSocialInput from "@/view/components/inputs/MultiSocialInput.vue";
import MultiLanguagesInput from "@/view/components/inputs/MultiLanguagesInput.vue";
import EuroCheckbox from "@/view/components/inputs/EuroCheckbox.vue";
import MultiTelephoneAddressInput from "@/view/components/inputs/MultiTelephoneAddressInput.vue";
import EuroDoubleDatePicker from "@/view/components/inputs/EuroDoubleDatePicker.vue";
import EuroTusFileInput from "@/view/components/inputs/EuroTusFileInput";
import EuroTextArea from "@/view/components/inputs/EuroTextArea.vue";
import EuroInputUrl from "@/view/components/inputs/EuroInputUrl.vue";

Vue.component("EuroSelect", EuroSelect);
Vue.component("euro-date-picker", EuroDatePicker);
Vue.component("euro-file-input", EuroFileInput);
Vue.component("EuroInput", EuroInput);
Vue.component("EuroTextArea", EuroTextArea);
Vue.component("euro-file-input-cropper", EuroFileInputCropper);
Vue.component("euro-place-input", EuroPlaceInput);
Vue.component("euro-radio", EuroRadio);
Vue.component("MultiTelephonesInput", MultiTelephonesInput);
Vue.component("TelephoneInput", TelephoneInput);
Vue.component("MultiEmailsInput", MultiEmailsInput);
Vue.component("AddressInput", AddressInput);
Vue.component("AvatarInput", AvatarInput);
Vue.component("MultiSocialInput", MultiSocialInput);
Vue.component("MultiLanguagesInput", MultiLanguagesInput);
Vue.component("EuroCheckbox", EuroCheckbox);
Vue.component("MultiTelephoneAddressInput", MultiTelephoneAddressInput);
Vue.component("EuroDoubleDatePicker", EuroDoubleDatePicker);
Vue.component("EuroTusFileInput", EuroTusFileInput);
Vue.component("EuroInputUrl", EuroInputUrl);