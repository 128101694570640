import api from "../api.service";
import addressService from "../address.service";

class CompanyAddressService {
  async getKinds() {
    const res = await api.options("company-address/");
    return res.data.actions.POST.kind.choices.map(choice => ({
      ...choice,
      value: choice.value.toString()
    }));
  }

  async getAllByCompanyId(companyID, {
    perPage,
    page,
    sortBy,
    search,
    sortDesc
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      company: companyID
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`company-address/`, {
      params,
    });
    return res.data;
  }

  async getAllByCompany(companyID) {
    const res = await api.get("company-address/", {
      params: {
        company: companyID
      },
    });
    return res;
  }

  async getOne(companyAddressID) {
    const res = await api.get(`company-address/${companyAddressID}/`);
    return res;
  }

  /**
   * @deprecated
   */
  async getAllByCompanyPaginated(companyID, limit, offset) {
    const res = await api.get("company-address/", {
      params: {
        company: companyID,
        limit,
        offset
      },
    });
    return res.data;
  }

  async create(body) {
    const convertedAddresses = await addressService.getOrCreateAddress([body]);

    body.address = convertedAddresses[0].id;
    const res = await api.post("company-address/", body);
    return res.data;
  }

  async update(body, id) {
    const convertedAddresses = await addressService.getOrCreateAddress([body]);

    body.address = convertedAddresses[0].id;
    const res = await api.patch(`company-address/${id}/`, body);
    return res.data;
  }

  async delete(address) {
    await api.delete(`company-address/${address.id}/`);
  }

  async setMain(address) {
    const res = await api.post(`company-address/${address.id}/set_main/`, address);
    return res.data;
  }
}

export default new CompanyAddressService();