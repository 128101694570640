import Vue from "vue";
import WizardLeft from "@/view/components/wizards/WizardLeft/WizardLeft.vue";
import WizardNavStepIconLeft from "@/view/components/wizards/WizardLeft/WizardNavStepIconLeft.vue";
import WizardNavStepLeft from "@/view/components/wizards/WizardLeft/WizardNavStepLeft.vue";
import WizardStepLeft from "@/view/components/wizards/WizardLeft/WizardStepLeft.vue";
import WizardActionsLeft from "@/view/components/wizards/WizardLeft/WizardActionsLeft.vue";
import WizardNextBtnLeft from "@/view/components/wizards/WizardLeft/WizardNextBtnLeft.vue";
import WizardPrevBtnLeft from "@/view/components/wizards/WizardLeft/WizardPrevBtnLeft.vue";
import WizardSubmitBtnLeft from "@/view/components/wizards/WizardLeft/WizardSubmitBtnLeft.vue";

Vue.component("wizard-left", WizardLeft);
Vue.component("wizard-nav-step-icon-left", WizardNavStepIconLeft);
Vue.component("wizard-nav-step-left", WizardNavStepLeft);
Vue.component("wizard-step-left", WizardStepLeft);
Vue.component("wizard-actions-left", WizardActionsLeft);
Vue.component("wizard-next-btn-left", WizardNextBtnLeft);
Vue.component("wizard-prev-btn-left", WizardPrevBtnLeft);
Vue.component("wizard-submit-btn-left", WizardSubmitBtnLeft);