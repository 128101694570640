import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import setupInterceptors from "@/core/services/interceptors";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/vue-croppie";
import "@/core/plugins/custom";
import "@/core/plugins/vue-tel-input";
import "@/core/plugins/vee-validate";
import "@/core/plugins/vue-airbnb-style-datepicker";
import "@/core/plugins/vue-timeline";
import "@/core/plugins/vue-slider";
import "@/core/plugins/vue-observe-visibility";

import "@/core/directives";

Vue.use(require("vue-moment"));

setupInterceptors(store);

var vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export { vm };
