import Vue from "vue";

import MenuNav from "@/view/components/navigation/MenuNav";

import MenuNavLink from "@/view/components/navigation/MenuNavLink";
import MenuNavDropdown from "@/view/components/navigation/MenuNavDropdown";
import MenuNavDropdownContent from "@/view/components/navigation/MenuNavDropdownContent";
import MenuNavDropdownNested from "@/view/components/navigation/MenuNavDropdownNested";

Vue.component("menu-nav", MenuNav);
Vue.component("menu-nav-link", MenuNavLink);
Vue.component("menu-nav-dropdown", MenuNavDropdown);
Vue.component("menu-nav-dropdown-content", MenuNavDropdownContent);
Vue.component("menu-nav-dropdown-nested", MenuNavDropdownNested);
