import api from "@/core/services/api.service";

export default {
  get PATH() {
    return "post-category";
  },

  async getAll(options = null) {
    const params = {};
    if (options?.tree_view) params.tree_view = options.tree_view;
    const res = await api.get(`${this.PATH}/`, {
      params
    });
    return res.data.results;
  },

  async getDescendants(categoryId){
    const params = { include_self: false };
    if(categoryId) params.category_id = categoryId
    const res = await api.get(`${this.PATH}/get_category_descendants/`,{params});
    return res.data;
  },

  async getAllNotPaginated(options = null) {
    const params = {};
    if (options?.country) params.country = options.country;
    const results = await api.get(`${this.PATH}/get_report_categories/`, {
      params,
    });
    return results.data;
  },
};
