import moment from "moment";
import momentTz from "moment-timezone";

class DateService {
  format(date, format = "YYYY-MM-DD") {
    return moment(date).format(format);
  }

  formatWithTime(date, format = "YYYY-MM-DD", timezone) {
    return momentTz(date).tz(timezone).format(format + " hh:mm A");
  }

  diff(firstDate, secondDate) {
    return moment(firstDate).diff(moment(secondDate), "days");
  }

  isBefore(firstDate, secondDate) {
    return moment(firstDate).isBefore(moment(secondDate));
  }

  isAfter(firstDate, secondDate) {
    return moment(firstDate).isAfter(moment(secondDate));
  }

  enumerateDates(firstDate, lastDate) {
    const curr = moment(firstDate).startOf("day");
    const last = moment(lastDate).startOf("day");

    let dates = [this.format(firstDate)];

    while (curr.add(1, "days").diff(last) < 0) {
      dates.push(curr.clone().format("YYYY-MM-DD"));
    }

    return [...dates, this.format(lastDate)];
  }

  toDate(date) {
    return moment(date).toDate();
  }

  toValue(date) {
    const out = moment(date);
    return out.valueOf();
  }

  fromNumber(number) {
    return moment(number);
  }

  fromNumberFormatted(number, format = "YYYY-MM-DD") {
    return this.fromNumber(number).format(format);
  }

  timezones() {
    return momentTz.tz.names();
  }

  addYears(years, dateStart) {
    const mDateStart = moment(dateStart)

    return mDateStart.add(years, 'years').format("YYYY-MM-DD")
  }

  timeAgo(date) {
    return moment(date).fromNow();
  }

  formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes;
  }
  
  formatIfRecent(date, format = "YYYY-MM-DD", timezone) {
    const now = moment();
    const targetDate = moment(date);
    const diffInDays = now.diff(targetDate, 'days');

    if (diffInDays < 1) {
      return targetDate.fromNow();
    } else {
      return this.formatWithTime(date, format, timezone);
    }
  }
}

export default new DateService();