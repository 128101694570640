<template>
  <b-form-group :description="hint" :label="label" :valid-feedback="validFeedback" :state="state"
    :invalid-feedback="invalidFeedback" :label-cols="horizontal ? 3 : null">
    <b-input-group>
      <b-form-input v-model="vModel" type="text" placeholder="YYYY-MM-DD" autocomplete="off"
        class="form-control form-control-solid h-auto"></b-form-input>
      <b-input-group-append>
        <b-form-datepicker v-model="vModel" button-only right locale="en-US" :min="minDate" :max="maxDate"
          @context="onContext">
          <template #button-content>
            <i class="flaticon2-calendar-4"></i>
          </template>
        </b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
  },

  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
};
</script>
