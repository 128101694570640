<template>
  <ul class="menu-content">
    <li class="menu-item">
      <h3 class="menu-heading menu-toggle">
        <i class="menu-bullet menu-bullet-dot"><span></span></i>
        <span class="menu-text"><i class="icon-xl text-dark" :class="icon"></i> {{ label }} </span>
        <i class="menu-arrow"></i>
      </h3>
      <ul class="menu-inner">
        <slot></slot>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
