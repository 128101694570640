var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'form-group row': _vm.parentClass,
      validated: !_vm.valid
    }
  }, [_vm.label ? _c('label', {
    staticClass: "label-text col-form-label",
    class: {
      'col-xl-3 col-lg-3': _vm.horizontal,
      'col-12': !_vm.horizontal,
      'disabled': _vm.disabled
    }
  }, [_vm._t("label", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " ")];
  }), _vm.helpText ? _c('span', {
    staticClass: "ml-2",
    attrs: {
      "data-toggle": "tooltip",
      "title": _vm.helpText
    }
  }, [_c('i', {
    staticClass: "fa fa-sm fa-info-circle text-primary"
  })]) : _vm._e()], 2) : _vm._e(), _c('div', {
    class: {
      'col-lg-9 col-xl-9': _vm.horizontal,
      'col-12': !_vm.horizontal
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm._t("prepend", function () {
    return [_vm.prependText ? _c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(_vm.prependText))]) : _vm._e()];
  }), _vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vModel,
      expression: "vModel"
    }],
    staticClass: "form-control form-control-solid input-text",
    class: Object.assign({
      'is-invalid': !_vm.valid,
      'fake-input': _vm.fakeInput
    }, _vm.className),
    attrs: {
      "placeholder": _vm.placeholder,
      "step": _vm.step,
      "min": _vm.min,
      "max": _vm.max,
      "readOnly": _vm.fakeInput,
      "disabled": _vm.disabled,
      "maxlength": _vm.maxlength,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.vModel) ? _vm._i(_vm.vModel, null) > -1 : _vm.vModel
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.vModel,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.vModel = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.vModel = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.vModel = $$c;
        }
      }
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vModel,
      expression: "vModel"
    }],
    staticClass: "form-control form-control-solid input-text",
    class: Object.assign({
      'is-invalid': !_vm.valid,
      'fake-input': _vm.fakeInput
    }, _vm.className),
    attrs: {
      "placeholder": _vm.placeholder,
      "step": _vm.step,
      "min": _vm.min,
      "max": _vm.max,
      "readOnly": _vm.fakeInput,
      "disabled": _vm.disabled,
      "maxlength": _vm.maxlength,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.vModel, null)
    },
    on: {
      "change": function change($event) {
        _vm.vModel = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vModel,
      expression: "vModel"
    }],
    staticClass: "form-control form-control-solid input-text",
    class: Object.assign({
      'is-invalid': !_vm.valid,
      'fake-input': _vm.fakeInput
    }, _vm.className),
    attrs: {
      "placeholder": _vm.placeholder,
      "step": _vm.step,
      "min": _vm.min,
      "max": _vm.max,
      "readOnly": _vm.fakeInput,
      "disabled": _vm.disabled,
      "maxlength": _vm.maxlength,
      "type": _vm.type
    },
    domProps: {
      "value": _vm.vModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.vModel = $event.target.value;
      }
    }
  }), _vm._t("append", function () {
    return [_vm.appendText ? _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(_vm.appendText))]) : _vm._e()];
  })], 2), !_vm.valid ? _c('div', {
    staticClass: "invalid-feedback d-block my-2",
    domProps: {
      "textContent": _vm._s(_vm.showErrors)
    }
  }) : _vm._e(), _vm.hint ? _c('span', {
    staticClass: "form-text text-muted"
  }, [_vm._v(_vm._s(_vm.hint))]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }