import api from "../api.service";
import addressService from "../address.service";

class CompanyService {
  async getAll(perPage, page, sortBy = null, search = null, sortDesc = null, country = null, getAdministrative = null, kind = null, fields = null, omit = null, role = null) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      fields: fields,
      omit: omit,
      search,
      companyaddress__address__locality__state__country__code: country,
      is_administrative_structure: getAdministrative,
      kind: kind,
      role: role,
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get("company/", {
      params,
    });
    return res.data;
  }

  async all({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    country,
    countryOfEstablishment,
    isAdministrative,
    kind,
    fields,
    omit,
    documentCounterpart,
    inGroup,
    isFocus,
    company_with_orders = null,
    company_id = null,
    focusView,
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      fields: fields,
      omit: omit,
      companyaddress__address__locality__state__country__code: country,
      is_administrative_structure: isAdministrative,
      document_counterpart: documentCounterpart,
      in_group: inGroup,
      country_of_establishment: countryOfEstablishment,
      kind: kind,
      is_focus: isFocus,
      focus_view: focusView,
      company_with_orders: company_with_orders,
      company_id: company_id,
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get("company/", {
      params,
    });
    return res.data;
  }

  async getAllNotPaginated() {
    const paginated = await this.getAll();
    const res = await api.get("/company", {
      params: {
        limit: paginated.count,
        offset: 0
      }
    });
    return res.data;
  }

  async getAllNotPaginatedGroups() {
    const paginated = await this.getAll();
    const res = await api.get("/company", {
      params: {
        limit: paginated.count,
        offset: 0,
        in_group: true
      }
    });
    return res.data;
  }

  async getAllNotPaginatedFilterable({
    country,
    kind,
    getAdministrative,
    getTemporaryAgency,
    fields,
  }) {
    const paginated = await this.getAll(5, 1, "id", "", false, country, getAdministrative, kind, getTemporaryAgency);
    let body = {
      params: {
        limit: paginated.count,
        offset: 0,
        companyaddress__address__locality__state__country__code: country,
        is_administrative_structure: getAdministrative,
        kind: kind,
        is_temporary_work_agency: getTemporaryAgency,
      },
    }
    if (fields) {
      body.params.fields = fields;
    }
    const res = await api.get("/company", body);
    return res.data;
  }

  async getOne(id, expand = null, fields = null, omit = null) {

    const params = {};

    if (omit && omit.length) {
      params.omit = omit;
    }

    if (fields && fields.length) {
      params.fields = fields;
    }

    if (expand && expand.length) {
      params.expand = expand;
    }
    return await api.get(`company/${id}/`, {
      params,
    });
  }

  /**
   * Gets a company (legal person) by ID without converting the addresses to GogglePlace object.
   */
  async getOneOldAddressFormat({
    id,
    expand = null,
    fields = null,
    omit = null
  }) {
    const params = {};

    if (omit && omit.length) {
      params.omit = omit;
    }

    if (fields && fields.length) {
      params.fields = fields;
    }

    if (expand && expand.length) {
      params.expand = expand;
    }
    return await api.get(`company/${id}/`, {
      params,
    });
  }

  async delete(id) {
    await api.delete(`company/${id}/`);
  }

  async create(body) {
    const {
      place_of_establishment,
      companyaddress_set
    } = body;
    const addresses = [];
    place_of_establishment && addresses.push({
      address: place_of_establishment,
      manual_address: false
    });
    const newAddressSet = {
      main: true,
      description: "string",
      kind: 0,
      address: 0,
      manual_address: false,
      composed_address: null
    };
    if (companyaddress_set && companyaddress_set.length) {
      companyaddress_set.forEach(el => {
        (newAddressSet.main = el.main),
        (newAddressSet.description = el.description),
        (newAddressSet.kind = el.kind),
        (newAddressSet.address = el.address);
        (newAddressSet.manual_address = el.manual_address);
        (newAddressSet.composed_address = el.composed_address);
        addresses.push(el);
      });
    }
    const convertedAddresses = await addressService.getOrCreateAddress(addresses);
    if (place_of_establishment) {
      body.place_of_establishment = convertedAddresses[0].id;
    }

    if (
      (place_of_establishment && convertedAddresses.length > 1) ||
      (!place_of_establishment && convertedAddresses.length)
    ) {
      const start = place_of_establishment ? 1 : 0;
      newAddressSet.address = convertedAddresses[start].id;
      body.companyaddress_set = [newAddressSet];
    } else {
      body.companyaddress_set = [];
    }

    const res = await api.post("company/", body);
    return res.data;
  }

  async update(body, id, params = null) {
    const res = await api.patch(`company/${id}/`, body, {
      params
    });
    return res.data;
  }

  async getDocuments({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    companyId = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }

    const res = await api.get(`company/${companyId}/get_documents/`, {
      params,
    });
    return res.data;
  }

  async getCompanyContactPerson({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    companyId = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const results = await api.get(`company/${companyId}/company_contact/`, {
      params,
    }).catch(err => {
      console.error('@getCompanyContactPerson', err);
    });

    return results.data;
  }

  async deleteCompanyContactPerson(legalId, contactId) {
    api.delete(`company/${legalId}/company_contact/?contact_id=${contactId}`);
  }

  async createContact(body) {
    const res = await api.post(`company/${body.company}/company_contact/`, body);
    return res;
  }

  async updateCompanyAddress(company) {
    if (company.place_of_establishment) {
      company.place_of_establishment = await addressService.getGooglePlaceFromApi(company.place_of_establishment);
    }

    if (company.companyaddress_set) {
      company.companyaddress_set = company.companyaddress_set.map((el) => {
        el.original = {
          ...el.address
        }
        el.address = addressService.getGooglePlaceFromApi(el.original);
        if (el.original.manual_address) {
          el.composed_address = addressService.getAddressFromManualPlace(el.original)
        }
        el.manual_address = el.original.manual_address
        return el
      });
    }
    return company;
  }

  async deactivateFocusUser(id) {
    const res = await api.post(`company/${id}/deactivate_focus_user/`);
    return res.data;
  }
}

export default new CompanyService();