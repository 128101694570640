import addressService from "../address.service";
import api from "../api.service";

class PhysicalPersonAddressService {
  async getKinds() {
    const res = await api.options("physicalperson-address/");
    return res.data.actions.POST.kind.choices.map(choice => ({
      ...choice,
      value: choice.value.toString()
    }));
  }

  async getAllByPhysicalPerson(personID) {
    const res = await this.getAllByPhysicalPersonPaginated(personID, 1000, 0);
    return res.results;
  }

  async getAllByPhysicalPersonPaginated(personID, limit, offset) {
    const res = await api.get("physicalperson-address/", {
      params: { persona: personID, limit, offset },
    });
    return res.data;
  }

  async create(body) {
    const convertedAddresses = await addressService.getOrCreateAddress([body]);

    body.address = convertedAddresses[0].id;
    const res = await api.post("physicalperson-address/", body);

    return res.data;
  }

  async update(body, id) {
    const convertedAddresses = await addressService.getOrCreateAddress([body]);

    body.address = convertedAddresses[0].id;
    const res = await api.patch(`physicalperson-address/${id}/`, body);
    return res.data;
  }

  async delete(address) {
    await api.delete(`physicalperson-address/${address.id}`);
  }

  async setMain(address) {
    const res = await api.post(`physicalperson-address/${address.id}/set_main/`, address);
    return res.data;
  }

  async getOne(personAddressId) {
    const res = await api.get(`physicalperson-address/${personAddressId}/`);
    return res;
  }
}

export default new PhysicalPersonAddressService();
