<template>
  <div :id="mapId" :style="{
    height,
    width,
  }"></div>
</template>

<script>
export default {
  props: {
    // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
    mapOptions: {
      type: Object,
      default: () => ({}),
    },
    // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
    markers: {
      type: Array,
      default: () => [],
    },
    // https://developers.google.com/maps/documentation/javascript/reference/polygon#PolylineOptions
    polyline: {
      type: Object,
      default: null,
    },
    height: {
      type: String,
      default: "400px",
    },
    width: {
      type: String,
      default: "100%",
    },
    directions: {
      type: Object,
      default: null,
    },
    kmlLayersUrls: {
      type: Array,
      default: () => [],
    },
    // https://developers.google.com/maps/documentation/javascript/geocoding
    geocoderOptions: {
      type: Object,
      default: null,
    },
    geoJsonLayers: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    map: null,
    drawnPolyline: null,
    googleMarkers: [],
    defaultOptions: {
      center: { lat: 44.6499501, lng: 10.9285026 },
      zoom: 4,
      disableDefaultUI: true,
      zoomControl: true,
      // Remove all POI
      styles: [
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ]
    },
    directionsRenderer: null,
    infowindow: null,
    googleKmlLayers: [],
    googleGeoJsonLayers: [],
  }),

  computed: {
    mapId() {
      return `google-map${this._uid}`;
    },

    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.mapOptions,
      };
    },
  },

  watch: {
    mapOptions: {
      deep: true,
      handler() {
        if (!this.map) return;

        this.map.setOptions(this.mergedOptions);
      },
    },
    markers() {
      this.placeMarkers();
    },
    polyline: {
      handler() {
        this.drawPolyline();
      },
      deep: true,
    },
    directions: {
      deep: true,
      handler() {
        this.drawDirections();
      },
    },
    kmlLayersUrls: {
      deep: true,
      handler() {
        this.clearKmlLayers();
        this.placeKmlLayers();
      },
    },
    geoJsonLayers: {
      deep: true,
      handler() {
        this.clearGeoJSONLayers();
        this.placeGeoJSONLayers();
      },
    },

  },

  mounted() {
    if (!this.geocoderOptions) {
      this.initMap();
      return;
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(this.geocoderOptions, (results, status) => {
      if (status == "OK") {
        this.defaultOptions.center = results[0].geometry.location;
      } else {
        console.error(results, status);
      }

      this.initMap();
      this.map?.fitBounds(results[0].geometry.viewport);
    });
  },

  beforeDestroy() {
    this.clearGoogleMarkers();
    this.clearKmlLayers();
  },

  methods: {
    initMap() {
      const element = document.getElementById(this.mapId);
      this.map = new google.maps.Map(element, this.mergedOptions);

      this.infowindow = new google.maps.InfoWindow();
      this.placeMarkers();
      this.drawPolyline();

      if (this.directions) {
        this.drawDirections();
      }

      if (this.kmlLayersUrls.length) {
        this.placeKmlLayers();
      }
    },

    placeMarkers() {
      this.clearGoogleMarkers();
      // Add markers if any.
      this.markers.forEach((marker, index) => {
        const googleMarker = new google.maps.Marker({
          // animation: google.maps.Animation.DROP,
          ...marker,
          map: this.map,
        });

        if (marker?.draggable) {
          const onDragEnd = () => {
            const { lat, lng } = googleMarker.getPosition();
            this.$emit("marker-dragend", {
              // The index of the marker inside the markers array.
              marker: index,
              // The new position on the map of the marker.
              position: { lat: lat(), lng: lng() },
            });
          };

          google.maps.event.addListener(googleMarker, "dragend", onDragEnd);
        }

        if (marker?.title) {
          google.maps.event.addListener(googleMarker, "click", () => this.openInfoWindow(googleMarker));
        }

        this.googleMarkers.push(googleMarker);
      });
    },

    clearGoogleMarkers() {
      this.googleMarkers.forEach(marker => {
        // Removes the dragend listener.
        google.maps.event.clearListeners(marker, "dragend");
        // Removes click listener.
        google.maps.event.clearListeners(marker, "click");
        // Deletes the marker from the map.
        marker.setMap(null);
      });
      this.googleMarkers = [];
    },

    drawPolyline() {
      this.drawnPolyline?.setMap(null);

      if (!this.polyline) {
        return;
      }

      const defaultOptions = {};

      this.drawnPolyline = new google.maps.Polyline({
        ...defaultOptions,
        ...this.polyline,
      });
      this.drawnPolyline.setMap(this.map);
    },

    drawDirections() {
      this.clearGoogleMarkers();
      let originLatLng = {}
      if (!this.directionsRenderer) this.directionsRenderer = new google.maps.DirectionsRenderer();
      const { origin, destination, travelMode, waypoints } = this.directions;
      if ((origin && !destination) || (origin && !travelMode)) {
        const latLng = origin.split(',');
        originLatLng = {
          lat: Number(latLng[0]), lng: Number(latLng[1])
        }
        const googleMarker = new google.maps.Marker({
          position: originLatLng,
          map: this.map,
        });
        this.googleMarkers.push(googleMarker);
      }
      if (!destination || !travelMode) {
        this.directionsRenderer.setMap(null);
        if (origin) {
          this.map.setCenter(originLatLng);
        }
        return;
      }
      const directionsService = new google.maps.DirectionsService();
      this.directionsRenderer.setMap(this.map);
      directionsService
        .route({
          origin,
          destination,
          travelMode,
          waypoints,
        })
        .then(res => {
          this.directionsRenderer.setDirections(res);
        });
    },

    openInfoWindow(marker) {
      this.infowindow.setContent(marker.title);
      this.infowindow.open(this.map, marker);
    },

    placeKmlLayers() {
      this.googleKmlLayers = [];

      if (!this.map) return;

      this.kmlLayersUrls.forEach(url => {
        const kmlLayer = new google.maps.KmlLayer(url, {
          suppressInfoWindows: true,
        });

        kmlLayer.setMap(this.map)

        kmlLayer.addListener("click", kmlEvent => {
          const geocoder = new google.maps.Geocoder();
          const geocoderOpts = {
            location: kmlEvent?.latLng,
          };
          geocoder.geocode(geocoderOpts, (results, status) => {
            if (status == "OK" && results.length) {
              this.$emit("kml-click", results[0]);
            }
          });
        });

        this.googleKmlLayers.push({
          kmlLayer,
          url: url,
        });
      });
    },

    clearKmlLayers() {
      this.googleKmlLayers.forEach(kmlLayer => {
        google.maps.event.clearListeners(kmlLayer, "click");
        kmlLayer.kmlLayer.setMap(null)
      });

      this.googleKmlLayers = [];
    },

    placeGeoJSONLayers() {
      this.geoJsonLayers.forEach(layer => {
        const geoJsonLayer = new google.maps.Data({
          map: this.map,
          style: layer.style,
        });
        geoJsonLayer.setStyle(function (feature) {
          let color = feature.getProperty('alertlevel');
          let gdacsClass = feature.getProperty('Class');
          if (gdacsClass && gdacsClass.startsWith('Poly')) {
            if (gdacsClass.split('_').length > 1) {
              let gdacsClassValue = gdacsClass.split('_')[1];
              if (gdacsClassValue === 'Green' || gdacsClassValue === 'Yellow' || gdacsClassValue === 'Orange' || gdacsClassValue === 'Red') {
                color = gdacsClassValue;
              }
              else if (gdacsClassValue === 'Cones') {
                color = 'white';
              }
            }
          }
          return {
            icon: {
              url: feature.getProperty('icon'),  // Use icon URL from geoJSON properties
              scaledSize: new google.maps.Size(25, 25)
            },
            fillColor: color,
            fillOpacity: 0.5,
            strokeColor: color,
            strokeWeight: 0.5,
            title: feature.getProperty('name')
          }
        });
        geoJsonLayer.addGeoJson(layer.data);

        geoJsonLayer.addListener("click", geoJsonEvent => {
          const geocoder = new google.maps.Geocoder();
          const geocoderOpts = {
            location: geoJsonEvent?.latLng,
          };
          geocoder.geocode(geocoderOpts, (results, status) => {
            if (status == "OK" && results.length) {
              this.$emit("geojson-click", results[0]);
            }
          });
        });

        const infoWindow = new google.maps.InfoWindow();

        geoJsonLayer.addListener("click", geoJsonEvent => {
          const geocoder = new google.maps.Geocoder();
          const geocoderOpts = {
            location: geoJsonEvent?.latLng,
          };
          geocoder.geocode(geocoderOpts, (results, status) => {
            if (status == "OK" && results.length) {
              this.$emit("geojson-click", results[0]);

              // Construct InfoWindow content
              const severityData = geoJsonEvent.feature.getProperty('severitydata');
              const contentString = `
                <div>
                  <h6>${geoJsonEvent.feature.getProperty('name')}</h6>
                  <p>${geoJsonEvent.feature.getProperty('description')}</p>
                  <p>${severityData.severitytext} ${severityData.severityunit}</p>
                  <a href="${geoJsonEvent.feature.getProperty('url').report}" target="_blank">More Details</a>
                </div>
              `;

              // Set the content of the InfoWindow
              infoWindow.setContent(contentString);

              // Open the InfoWindow at the clicked position
              infoWindow.setPosition(geoJsonEvent.latLng);
              infoWindow.open(this.map);
            }
          });
        });

        this.googleGeoJsonLayers.push({
          geoJsonLayer,
          geoJson: layer.geoJson,
        });
      });
    },
    clearGeoJSONLayers() {
      // if (!this.geoJsonLayers) return;
      this.googleGeoJsonLayers.forEach(layer => {
        google.maps.event.clearListeners(layer, "click");
        layer.geoJsonLayer.setMap(null)
      });

      this.googleGeoJsonLayers = [];
    },
  },
};
</script>