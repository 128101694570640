var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "menu-item menu-item-submenu menu-item-open-dropdown",
    class: {
      'menu-item-active': _vm.hasActiveChildren(_vm.menuItemActive)
    },
    attrs: {
      "aria-haspopup": "true",
      "data-menu-toggle": _vm.menuToggle
    }
  }, [_c('routerLink', {
    staticClass: "menu-link menu-toggle",
    attrs: {
      "to": _vm.action
    }
  }, [_c('span', {
    staticClass: "menu-text"
  }, [_vm._v(_vm._s(_vm.label))])]), _c('div', {
    staticClass: "menu-submenu",
    class: _vm.isClassic ? 'menu-submenu-classic' : 'menu-submenu-fixed'
  }, [_c('ul', {
    staticClass: "menu-subnav"
  }, [_vm._t("default")], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }