var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'frame': _vm.frame
    }
  }, [_vm.avatarImage ? _c('image-avatar', {
    attrs: {
      "rounded": _vm.rounded,
      "src": _vm.avatarImage,
      "size": _vm.size
    }
  }) : _c('text-avatar', {
    attrs: {
      "text": _vm.avatarText,
      "size": _vm.size
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }