var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row mb-0"
  }, _vm._l(_vm.phones, function (tel, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex align-items-center element"
    }, [_c('div', {
      staticClass: "col-xl-9"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-xl-4"
    }, [_c('b-form-select', {
      staticClass: "form-control form-control-solid h-auto",
      attrs: {
        "disabled": tel.main,
        "options": _vm.options
      },
      model: {
        value: tel.kind,
        callback: function callback($$v) {
          _vm.$set(tel, "kind", $$v);
        },
        expression: "tel.kind"
      }
    })], 1), _c('div', {
      staticClass: "col-xl-8"
    }, [_c('div', {
      staticClass: "col-12"
    }, [_c('validation-provider', {
      attrs: {
        "name": "phone",
        "vid": "phone[".concat(idx, "]"),
        "rules": {
          min: 7,
          telephone: [tel],
          personal_phone_unique: idx === _vm.phones.length - 1 ? _vm.computedPhoneList : false
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('telephone-input', {
            staticClass: "mb-0",
            attrs: {
              "error-messages": errors
            },
            model: {
              value: tel.number,
              callback: function callback($$v) {
                _vm.$set(tel, "number", $$v);
              },
              expression: "tel.number"
            }
          })];
        }
      }], null, true)
    })], 1)]), _c('div', {
      staticClass: "col-xl-12 mt-6"
    }, [_c('div', {
      staticClass: "col-12 pl-0"
    }, [_c('validation-provider', {
      attrs: {
        "name": "address",
        "vid": "company_address[".concat(idx, "]")
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var errors = _ref2.errors;
          return [_c('euro-select', {
            attrs: {
              "options": _vm.addresses,
              "error-messages": errors,
              "placeholder": _vm.$t('Select an address'),
              "class-name": "mb-0"
            },
            model: {
              value: tel.company_address,
              callback: function callback($$v) {
                _vm.$set(tel, "company_address", $$v);
              },
              expression: "tel.company_address"
            }
          })];
        }
      }], null, true)
    })], 1)])])]), _c('div', {
      staticClass: "col-xl-3"
    }, [_c('div', {
      staticClass: "d-flex justify-content-center align-items-center m-n1"
    }, [tel.main ? _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "fas fa-circle mr-3",
      style: {
        color: '#00A9BD'
      },
      attrs: {
        "title": "Main"
      }
    }) : _c('div', {
      style: {
        width: '3.5ch'
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.getTooltipMessage(tel),
        "icon": "fas fa-hand-point-up",
        "size": "xs",
        "disabled": !tel.number || tel.kind !== 'MOB'
      },
      on: {
        "click": function click($event) {
          return _vm.setMain(tel);
        }
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.$t('Add new telephone'),
        "icon": "flaticon2-plus",
        "size": "xs",
        "disabled": _vm.phones.length === 1 && !_vm.phones[0].number
      },
      on: {
        "click": _vm.addNewTelephone
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.$t('Remove telephone'),
        "icon": "flaticon2-cross",
        "size": "xs",
        "disabled": _vm.phones.length === 1 && !_vm.phones[0].number || tel.main
      },
      on: {
        "click": function click($event) {
          return _vm.removeTelephone(idx);
        }
      }
    })], 1)])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }