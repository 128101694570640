var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "menu-content"
  }, [_c('li', {
    staticClass: "menu-item"
  }, [_c('h3', {
    staticClass: "menu-heading menu-toggle"
  }, [_vm._m(0), _c('span', {
    staticClass: "menu-text"
  }, [_c('i', {
    staticClass: "icon-xl text-dark",
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.label) + " ")]), _c('i', {
    staticClass: "menu-arrow"
  })]), _c('ul', {
    staticClass: "menu-inner"
  }, [_vm._t("default")], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('i', {
    staticClass: "menu-bullet menu-bullet-dot"
  }, [_c('span')]);

}]

export { render, staticRenderFns }