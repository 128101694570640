var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols-lg": _vm.horizontal ? 3 : 12,
      "label-cols-xl": _vm.horizontal ? 3 : 12,
      "content-cols-lg": _vm.horizontal ? 9 : 12,
      "content-cols-xl": _vm.horizontal ? 6 : 12,
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    }], null, true)
  }, [_c('b-input-group', [_c('b-form-input', {
    ref: "input",
    staticClass: "form-control form-control-solid",
    attrs: {
      "id": _vm.inputId,
      "autocomplete": "off",
      "name": "search",
      "placeholder": _vm.$t(_vm.placeholder),
      "aria-label": "Search",
      "accesskey": "?",
      "value": _vm.valueFormatted,
      "state": _vm.state,
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.onInput,
      "focus": function focus($event) {
        return _vm.$emit('focus', $event);
      },
      "blur": function blur($event) {
        return _vm.$emit('blur', $event);
      }
    }
  }), _c('b-input-group-append', [_vm._t("append")], 2)], 1), _vm._t("pointer-location")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }