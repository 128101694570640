var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wizard-step",
    attrs: {
      "data-wizard-type": "step"
    }
  }, [_c('div', {
    staticClass: "wizard-wrapper"
  }, [_c('div', {
    staticClass: "wizard-icon"
  }, [_c('i', {
    staticClass: "wizard-check ki ki-check"
  }), _c('span', {
    staticClass: "wizard-number"
  }, [_vm._v(_vm._s(_vm.number))])]), _c('div', {
    staticClass: "wizard-label mr-3"
  }, [_c('h3', {
    staticClass: "wizard-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.desc != '' ? _c('div', {
    staticClass: "wizard-desc"
  }, [_vm._v(_vm._s(_vm.desc))]) : _vm._e(), _vm._t("default")], 2), _c('span', {
    staticClass: "svg-icon svg-icon-dark-25"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/Arrow-right.svg"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }