var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "description": _vm.hint,
      "label": _vm.label,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols": _vm.horizontal ? 3 : null
    }
  }, [_c('b-form-file', {
    attrs: {
      "state": _vm.state,
      "placeholder": _vm.placeholder,
      "accept": _vm.accept
    },
    model: {
      value: _vm.vModel,
      callback: function callback($$v) {
        _vm.vModel = $$v;
      },
      expression: "vModel"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }