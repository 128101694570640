import axiosInstance from "./api.service";
import jwtDecode from "jwt-decode";
import store from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";

const decodeBase64JTW = token => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    Buffer.from(base64, "base64")
    .toString()
    .split("")
    .map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
    .join(""),
  );

  return JSON.parse(jsonPayload);
};

class TokenService {
  getLocalRefreshToken() {
    const user = this.getLocalTokens();
    return user?.refresh;
  }

  getLocalAccessToken() {
    const user = this.getLocalTokens();
    return user?.access;
  }

  updateLocalAccessToken(token, refresh) {
    const user = this.getLocalTokens();
    user.access = token;
    user.refresh = refresh
    this.setUser(user);
  }

  getLocalTokens() {
    return JSON.parse(localStorage.getItem("ep_user"));
  }

  getUserID() {
    const decodedJWT = decodeBase64JTW(this.getLocalTokens().refresh);
    return decodedJWT.user_id;
  }

  setUser(user) {
    localStorage.setItem("ep_user", JSON.stringify(user));
  }

  removeUSer() {
    localStorage.removeItem("ep_user");
  }

  /**
   *
   * @returns {Object} response
   * @returns {string} response.access
   */
  async refreshToken() {
    return await axiosInstance.post("/token/refresh/", {
      refresh: this.getLocalRefreshToken(),
    });
  }

  async refreshTokenAndUpdateState() {
    let token = this.getLocalAccessToken();

    // Decode the token to check its expiry time
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        // Check if the token is already being refreshed
        if (!store.state.auth.refreshing_tokens) {
          try {
            await store.commit('auth/UPDATE_REFRESHING_TOKENS', true); // Update the Vuex state
            // Refresh the token
            const res = await this.refreshToken();
            token = res?.data?.access;
            let refresh = res?.data?.refresh;
            if (!token) {
              throw {
                customCode: 401,
                message: i18n.t("COMMON.SESSION_EXPIRED")
              };
            }
            // Update the token in local storage and redux store
            this.updateLocalAccessToken(token, refresh);
            await store.dispatch("auth/updateAccessToken", token);
            await store.dispatch("auth/updateRefreshToken", refresh);
            await store.commit('auth/UPDATE_REFRESHING_TOKENS', false); // Reset the Vuex state after successful token refresh
          } catch (_error) {
            throw _error;
          }
        } else {
          // Wait until token is refreshed
          await new Promise((resolve) => {
            let unwatch = store.watch((state) => state.auth.refreshing_tokens, (newValue) => {
              if (!newValue) {
                resolve();
                unwatch();
              }
            });
          });
          token = store.state.auth.tokens.access;
        }
      }
      return token;
    }
    return null;
  }
}

export default new TokenService();