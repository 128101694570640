var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row"
  }, [_vm.label ? _c('label', {
    staticClass: "col-form-label col-12",
    attrs: {
      "for": "uniqueId"
    }
  }, [_vm._t("label", function () {
    return [_vm._v(" " + _vm._s(_vm.label) + " ")];
  })], 2) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('input', {
    class: _vm.computedClasses,
    attrs: {
      "id": _vm.uniqueId,
      "type": "text",
      "readonly": "",
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.inputValue
    }
  }), !_vm.disabled ? _c('AirbnbStyleDatepicker', {
    staticClass: "datepicker-position",
    attrs: {
      "trigger-element-id": _vm.uniqueId,
      "mode": 'range',
      "date-one": _vm.selectedFrom,
      "date-two": _vm.selectedTo,
      "min-date": _vm.minDate,
      "end-date": _vm.maxDate,
      "customized-dates": _vm.customizedDates,
      "enabled-dates": _vm.enabledDates,
      "show-shortcuts-menu-trigger": false
    },
    on: {
      "closed": _vm.onClosed,
      "apply": _vm.onApply,
      "date-one-selected": _vm.onDateFromSelected,
      "date-two-selected": _vm.onDateToSelected
    }
  }) : _vm._e(), _vm.enableClean ? _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "d-inline-block px-1 ml-2 mt-4",
    attrs: {
      "title": _vm.$t('Clear')
    },
    on: {
      "click": _vm.cleanDates
    }
  }, [_c('i', {
    class: _vm.icons.close
  })]) : _vm._e()], 1), _vm.errorMessages.length ? _c('p', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.errorMessages[0]))]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }