<template>
  <b-form-group
    :description="hint"
    :label="label"
    :valid-feedback="validFeedback"
    :state="state"
    :invalid-feedback="invalidFeedback"
    :label-cols="horizontal ? 3 : null"
  >
    <b-form-file v-model="vModel" :state="state" :placeholder="placeholder" :accept="accept"></b-form-file>
  </b-form-group>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],

  props: {
    value: {
      type: [File, String],
      default: null,
    },

    placeholder: {
      type: String,
      default: "",
    },

    accept: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      file: null,
    };
  },

  computed: {
    vModel: {
      get() {
        if (typeof this.value == "string") {
          return null;
        }

        return this.value;
      },
      set(val) {
        this.file = val;
        this.$emit("input", this.file);
      },
    },
  },
};
</script>

<style scoped>
.custom-file-label::after {
  content: "Browse";
}

.custom-file-upload .custom-file-input ~ .custom-file-label {
  border-color: #cbcbcb;
  border-width: 2px;
  border-style: dashed;
  background-color: #f1f1f1;
  height: 4rem;
  padding: 1rem;
}
.custom-file-upload .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1bc5bd;
  background-color: #fff;
}
.custom-file-upload .custom-file-input ~ .custom-file-label[data-browse]::after {
  display: none;
}
</style>
