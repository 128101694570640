import api from "../api.service";

class EmployeeService {
  get PATH() {
    return "employee";
  }

  async getAll({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    companyId,
    ids = [],
    fields = null,
    expand = "persona,company,employeecontract_set,employeecontract_set.national_contract_level,agency",
    omit = null,
    active = null,
    country = null,
    dateStart = null,
    dateEnd = null,
    secondmentId = null
  }) {
    const offset = (page - 1) * perPage;
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
    }

    const params = new URLSearchParams();
    params.append("limit", perPage);
    params.append("offset", offset);
    if (sortBy) {
      params.append("ordering", sortBy);
    }
    if (search) {
      params.append("search", search);
    }
    if (companyId) params.append("company", companyId);

    ids.forEach((el) => {
      params.append("ids", el);
    });

    if (search) {
      params['search'] = search
    }

    if (omit && omit.length) {
      params.append("omit", omit);
    }
    if (fields && fields.length) {
      params.append("fields", fields);
    }
    if (expand !== null) {
      params.append("expand", expand);
    }
    if (active !== null) {
      params.append("active", active)
    }
    if (country !== null) {
      params.append("country", country)
    }
    if (dateStart !== null) {
      params.append("date_start", dateStart)
    }
    if (dateEnd !== null) {
      params.append("date_end", dateEnd)
    }
    if (secondmentId !== null) {
      params.append("secondment_id", secondmentId)
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async associateContract(body) {
    const res = await api.post(`${this.PATH}/`, body);
    return res.data;
  }

  async dissociateContract(employeeId) {
    const res = await api.delete(`${this.PATH}/${employeeId}`);
    return res;
  }

  async getOne(
    id,
    fields = null,
    expand = null,
    omit = null,
    country = null,
    dateStart = null,
    dateEnd = null
  ) {
    let params = new URLSearchParams();
    if (fields && fields.length) {
      params.append("fields", fields);
    }
    if (expand && expand.length) {
      params.append("expand", expand);
    }
    if (omit && omit.length) {
      params.append("omit", omit);
    }
    if (country !== null) {
      params.append("country", country)
    }
    if (dateStart !== null) {
      params.append("date_start", dateStart)
    }
    if (dateEnd !== null) {
      params.append("date_end", dateEnd)
    }
    return await api.get(`${this.PATH}/${id}/`, {
      params
    });
  }

  async getDocuments({
    id,
    perPage,
    page,
    sortBy,
    search,
    sortDesc,
    fields = null,
    expand = null,
    omit = null,
    expiring=false
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search,
      expiring,
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (id) {
      params['persona'] = id
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get(`${this.PATH}/get_employee_documents/`, {
      params
    });
    return res.data;
  }

  async deletePaymentCheckAttachment(id) {
    return await api.delete(`employee-paycheck-attachment/${id}/`);
  }

  async donwloadPaycheckAttachmentZip(id) {
    return await api.get(`employee-paycheck/${id}/download_attachments/`, {
      responseType: 'blob', // Important
    });
  }

  async terminateEmploymentContract(id, termination_date = null) {
    let bodyData = {};
    if (termination_date) {
      bodyData.termination_date = termination_date;
    }
    return await api.post(`employee-contract/${id}/terminate_contract/`, JSON.stringify(bodyData));
  }
}

export default new EmployeeService();