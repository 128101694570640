var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.className,
    attrs: {
      "description": _vm.hint,
      "valid-feedback": _vm.validFeedback,
      "state": _vm.state,
      "invalid-feedback": _vm.invalidFeedback,
      "label-cols-lg": _vm.horizontal ? 3 : 12,
      "label-cols-xl": _vm.horizontal ? 3 : 12,
      "content-cols-lg": _vm.horizontal ? 9 : 12,
      "content-cols-xl": _vm.horizontal ? 6 : 12
    },
    scopedSlots: _vm._u([_vm.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.label) + " "), _vm._t("label"), _vm.helpText ? _c('span', {
          staticClass: "ml-2",
          attrs: {
            "data-toggle": "tooltip",
            "title": _vm.helpText
          }
        }, [_c('i', {
          staticClass: "fa fa-sm fa-info-circle text-primary"
        })]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }, [_c('b-input-group', [_c('multiselect', _vm._b({
    ref: _vm.multiSelectRef,
    attrs: {
      "loading": _vm.isLoading,
      "value": _vm.multiselectValue,
      "limit": _vm.asynchronous ? _vm.perPage : undefined,
      "limit-text": _vm.asynchronous ? _vm.limitText : undefined,
      "internal-search": !_vm.asynchronous
    },
    on: {
      "input": _vm.onMultiSelectInput,
      "search-change": _vm.debouncedSearchChange,
      "open": function open($event) {
        _vm.selectIsOpen = true;
      },
      "close": function close($event) {
        _vm.selectIsOpen = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(props) {
        return [_vm._t("singleLabel", null, null, props)];
      }
    }, _vm.customOptions ? {
      key: "option",
      fn: function fn(props) {
        return [_vm._t("options", null, {
          "props": props
        })];
      }
    } : null, !_vm.multiSelectPropsComputed.multiple && _vm.multiSelectValueText && _vm.allowEmpty ? {
      key: "beforeList",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center bg-primary p-3 text-white cursor-pointer",
          on: {
            "click": _vm.resetSelection
          }
        }, [_c('i', {
          staticClass: "fas fa-times text-white"
        }), _c('span', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(_vm.multiSelectValueText))])])];
      },
      proxy: true
    } : null], null, true)
  }, 'multiselect', _vm.multiSelectPropsComputed, false), [_vm.asynchronous ? _c('template', {
    slot: "afterList"
  }, [_c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    staticClass: "text-sm-center"
  }, [_vm.count > _vm.asyncOptions.length && !_vm.query.length ? _c('p', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Showing {shown} of {count} results", {
    shown: _vm.asyncOptions.length,
    count: _vm.count
  })) + " ")]) : _vm._e(), _vm.isLoading ? _c('p', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Loading more...")) + " ")]) : _vm._e()])]) : _vm._e()], 2), _c('b-input-group-append', [_vm._t("append")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }