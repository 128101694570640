import API from "../api.service";

export default {
  async getCapabilities() {
    const r = await API.get(`post-access/get_capabilities/`);
    return r.data;
  },

  async editPostSubscription(id, body) {
    const res = await API.patch(`post-access/${id}/`, body)
    return res.data
  }
};