import npgTerritorialJurisdictionService from "../../services/npg/npg-territorial-jurisdiction.service";
import npgAdministrativeStructureService from "../npg/npg-administrative-structure.service";
import npgCategoriesService from "../npg/npg-categories.service";
const npg = {
  namespaced: true,

  state: {
    isNpgMasterFetched: false,
    territorialJurisdiction: null,
    administrativeStructure: null,
    categories: null,
  },

  actions: {
    async loadTerritorialJurisdictions({
      commit,
      state
    }) {
      const territorialJurisdiction = await npgTerritorialJurisdictionService.getAll();
      commit("SET_JURISDICTIONS", territorialJurisdiction);
      if (state.administrativeStructure && state.categories) {
        commit("SET_FLAG");
      }
    },
    async loadAdministrativeStructures({
      commit,
      state
    }) {
      const administrativeStructure = await npgAdministrativeStructureService.getAll();
      commit("SET_ADM_STRUCTURE", administrativeStructure);
      if (state.territorialJurisdiction && state.categories) {
        commit("SET_FLAG");
      }
    },
    async loadCategories({
      commit,
      state
    }) {
      const categories = await npgCategoriesService.getAll();
      commit("SET_CATEGORIES", categories);
      if (state.territorialJurisdiction && state.administrativeStructure) {
        commit("SET_FLAG");
      }
    },
    setLoadFlag({
      commit
    }) {
      commit("SET_FLAG");
    },
  },
  mutations: {
    SET_JURISDICTIONS(state, territorialJurisdiction) {
      state.territorialJurisdiction = territorialJurisdiction;
    },
    SET_ADM_STRUCTURE(state, administrativeStructure) {
      state.administrativeStructure = administrativeStructure.results;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_FLAG(state) {
      state.isNpgMasterFetched = true;
    },
    reset: () => {}
  },
  getters: {
    getTerritorialJurisdiction(state) {
      return state.territorialJurisdiction.results?.map(el => ({
        text: el.name,
        value: el.iso,
      }));
    },
    getAdministrativeStructure(state) {
      return state.administrativeStructure?.map(el => ({
        text: el.name,
        value: el.id,
      }));
    },
    getCategories(state) {
      return state.categories.results?.map(el => ({
        text: el.name,
        value: el.id,
      }));
    },
  },
};

export default npg;