<template>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-custom alert-outline-2x alert-outline-primary fade show mt-4" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">Search for your address in the address bar, if you can't find your address, switch to
          manual editing mode, move the marker to the correct position and fill in the address information.</div>
      </div>
    </div>
    <div class="col-8">
      <google-maps-autocomplete ref="autocomplete" v-model="place" v-bind="{
        description: hint,
        label,
        'valid-feedback': validFeedback,
        state,
        'invalid-feedback': invalidFeedback,
        'label-cols': horizontal ? 3 : null,
        state: state,
      }" :autocomplete-options="{ componentRestrictions: restriction }" :disabled="manual"></google-maps-autocomplete>
    </div>
    <div class="col-4">
      <div class="form-group mb-0 mt-12">
        <div class="d-flex align-items-center justify-content-end">
          <label class="col-form-label text-right">{{ $t("Manual editing") }}</label>
          <div class="ml-3">
            <span class="switch switch-sm switch-icon">
              <label>
                <input v-model="manual" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="manual" class="col-12">
      <div class="alert alert-custom alert-light-info px-4 py-3 mt-4" role="alert">
        <div class="alert-text">{{ $t("Move the marker to the correct position and fill in the address information") }}
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <label class="text-capitalize">Map</label>
          <div class="card mb-4">
            <google-maps-map :map-options="mapOptions" :markers="markers" height="280px"
              @marker-dragend="onMarkerDragend"></google-maps-map>
          </div>
          <div class="row">
            <div class="col-6 text-center">
              <label class="text-capitalize">{{ $t("Latitude") }}</label>
              <div>{{ placeLat }}</div>
            </div>
            <div class="col-6 text-center">
              <label class="text-capitalize">{{ $t("Longitude") }}</label>
              <div>{{ placeLong }}</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <label class="text-capitalize">{{ $t("Street") }}*</label>
              <validation-provider ref="address_line" v-slot="{ errors }" :name="$t('Street')" vid="address_line"
                :rules="{ required: requiredRule }">
                <euro-input v-model="selectedAddressLine" :error-messages="errors" :fake-input="!manual" />
              </validation-provider>
            </div>
            <div class="col-6">
              <label class="text-capitalize">{{ $t("City") }}*</label>
              <validation-provider v-slot="{ errors }" :name="$t('City')" vid="city" :rules="{ required: requiredRule }">
                <euro-input v-model="selectedCity" :error-messages="errors" :fake-input="!manual" />
              </validation-provider>
            </div>
            <div class="col-6">
              <label class="text-capitalize">{{ $t("Country") }}*</label>
              <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country"
                :rules="{ required: requiredRule }">
                <euro-input v-model="selectedCountry" :error-messages="errors" :fake-input="!manual" />
              </validation-provider>
            </div>
            <div class="col-6">
              <label class="text-capitalize">{{ $t("Region") }}*</label>
              <validation-provider v-slot="{ errors }" :name="$t('Region')" vid="region"
                :rules="{ required: requiredRule }">
                <euro-input v-model="selectedRegion" :error-messages="errors" :fake-input="!manual" />
              </validation-provider>
            </div>
            <div class="col-6">
              <label class="text-capitalize">{{ $t("Province") }}</label>
              <validation-provider v-slot="{ errors }" :name="$t('Province')" vid="province">
                <euro-input v-model="selectedProvince" :error-messages="errors" :fake-input="!manual" />
              </validation-provider>
            </div>
            <div class="col-6">
              <label class="text-capitalize">{{ $t("Street number") }}</label>
              <validation-provider v-slot="{ errors }" :name="$t('Street number')" vid="street_number">
                <euro-input v-model="selectedStreetNumber" :error-messages="errors" :fake-input="!manual"
                  @input="clearAddressInput" />
              </validation-provider>
            </div>
            <div class="col-6">
              <label class="text-capitalize">{{ $t("Postal code") }}</label>
              <validation-provider v-slot="{ errors }" :name="$t('Postal code')" vid="postal_code">
                <euro-input v-model="selectedPostal" :error-messages="errors" :fake-input="!manual" />
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { BFormMixin } from "@/core/mixins";
import axios from "axios";
import { mapGetters } from "vuex";
import GoogleMapsAutocomplete from "@/view/components/google-maps/GoogleMapsAutocomplete.vue";
import GoogleMapsMap from "@/view/components/google-maps/GoogleMapsMap.vue";
export default {
  components: {
    GoogleMapsAutocomplete,
    GoogleMapsMap,
  },

  mixins: [BFormMixin],

  props: {
    value: {
      type: [Object],
      default: null,
    },
    restriction: {
      type: [Object, String],
      default: () => null,
    },
    addressCenter: {
      type: Object,
      default: () => null,
    },
    activeStep: {
      type: Boolean,
      default: false,
    },
    countryEstablishment: {
      type: String,
      default: "",
    },
    manualAddress: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      oldVal: null,
      selectedStreetNumber: "",
      selectedPostal: "",
      selectedAddressLine: "",
      selectedCity: "",
      selectedProvince: "",
      selectedRegion: "",
      selectedCountry: "",
      api: 'https://maps.googleapis.com/maps/api/geocode/json',
      validate: false,
      manual: this.manualAddress
    };
  },

  computed: {
    ...mapGetters("constants", ["countriesMap"]),
    place: {
      get() {
        let out = {};
        if (this.countryEstablishment) {
          out = {
            address_components: [
              {
                long_name: this.countriesMap[this.countryEstablishment].name,
                short_name: this.countryEstablishment,
                types: ["country"],
              },
            ],
          };
        }
        return this.value ?? out;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit('submit-disabled', val == '');
      },
    },
    city() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "locality")[0];
      const fallBack = this.place?.address_components?.filter(
        comp => comp.types[0] === "administrative_area_level_3",
      )[0];
      if (val) {
        return val?.long_name;
      } else {
        return fallBack?.long_name;
      }
    },

    province() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "administrative_area_level_2")[0];
      return val?.long_name;
    },

    region() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "administrative_area_level_1")[0];
      return val?.long_name;
    },

    country() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "country")[0];
      return { long_name: val?.long_name, short_name: val?.short_name };
    },

    addressLine() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "route")[0];
      return val?.long_name;
    },

    streetNumber() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "street_number")[0];
      return val?.long_name;
    },

    postalCode() {
      const val = this.place?.address_components?.filter(comp => comp.types[0] === "postal_code")[0];
      return val?.long_name;
    },

    placeLat() {
      if (this.place.geometry) {
        let { lat } = this.place?.geometry?.location;
        // Sometimes lat and lng are functions.
        if (typeof lat == "function") {
          lat = lat();
        }

        return lat;
      } else {
        return "";
      }
    },

    placeLong() {
      if (this.place.geometry) {
        let { lng } = this.place?.geometry?.location;
        // Sometimes lat and lng are functions.
        if (typeof lng == "function") {
          lng = lng();
        }

        return lng;
      } else {
        return "";
      }
    },

    zoom() {
      if (this.place?.geometry) {
        return 15;
      }
      return 5;
    },

    center() {
      if (!this.place?.geometry) {
        if (!this.addressCenter)
          return {
            lat: 44.6499501,
            lng: 10.9285026,
          };
        else return this.addressCenter;
      }

      let { lat, lng } = this.place?.geometry?.location;
      // Sometimes lat and lng are functions.
      if (typeof lat == "function") {
        lat = lat();
      }
      if (typeof lng == "function") {
        lng = lng();
      }

      return {
        lat,
        lng,
      };
    },

    mapOptions() {
      return {
        center: this.center,
        zoom: this.zoom,
      };
    },

    markers() {
      return [{ position: this.center, draggable: this.manual }];
    },

    requiredRule() {
      return !!(this.activeStep && this.manual);
    },
  },

  watch: {
    place: {
      immediate: true,
      async handler(_, old) {
        this.oldVal = { ...old, accepted: this.oldVal ? this.oldVal.accepted : false };
        this.selectedStreetNumber = this.streetNumber ?? "";
        this.selectedPostal = this.postalCode ?? "";
        this.selectedAddressLine = this.addressLine ?? "";
        this.selectedCity = this.city ?? "";
        this.selectedProvince = this.province ?? "";
        this.selectedRegion = this.region ?? "";
        this.selectedCountry = this.country?.long_name ?? "";
      },
    },
    oldVal: {
      deep: true,
      async handler() {
        if (this.countryEstablishment) {
          if (this.country.short_name !== this.countryEstablishment && !this.oldVal.accepted) {
            const res = await Swal.fire({
              title: this.$t("Warning"),
              text: this.$t("The selected country is different from the country of establishment, do you want to proceed"),
              icon: "warning",
              reverseButtons: true,
              confirmButtonClass: "btn btn-primary",
              confirmButtonText: this.$t("Yes"),
              showCancelButton: true,
              cancelButtonText: this.$t("No"),
              cancelButtonClass: "btn",
            });
            if (!res.isConfirmed) {
              this.place = this.oldVal;
            } else {
              this.oldVal.accepted = true;
            }
          }
        }
      },
    },
    manual(val) {
      this.$emit("change-manual-address", val);
      if (val) {
        this.$emit('update-composed-address', this.getComposedAddress());
      }
    },
    selectedAddressLine(value) {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.$emit('submit-disabled', value == '');
      }
    },
    selectedRegion(value) {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.$emit('submit-disabled', value == '');
        this.checkRequestaInput();
      }
    },
    selectedCity(value) {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.$emit('submit-disabled', value == '');
      }
    },
    selectedCountry(value) {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.$emit('submit-disabled', value == '');
      }
    },
    selectedStreetNumber() {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.checkRequestaInput();
      }
    },
    selectedPostal() {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.checkRequestaInput();
      }
    },
    selectedProvince() {
      if (this.manual) {
        this.$emit('update-composed-address', this.getComposedAddress());
        this.checkRequestaInput();
      }
    }
  },

  methods: {
    // Calls google's api to get a new place object based on coordinates.
    async onMarkerDragend(marker) {
      const { position: { lat, lng } } = marker;
      await axios.get(`${this.api}?latlng=${lat},${lng}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`)
        .then(res => {
          this.$refs.autocomplete.changeAddressInput(res.data.results[0]);
          this.place = res.data.results[0];
          this.$refs['address_line'].validate();
          this.$emit('update-composed-address', this.getComposedAddress());
        }).catch(console.error);
    },

    clearAddressInput() {
      this.$refs.autocomplete.changeAddressInput('');
    },

    checkRequestaInput() {
      let disabled = true
      if (this.selectedAddressLine != '') {
        disabled = false
      }
      if (this.selectedCity != '') {
        disabled = false
      }
      if (this.selectedCountry != '') {
        disabled = false
      }
      if (this.selectedProvince != '') {
        disabled = false
      }

      this.$emit('submit-disabled', disabled);
    },

    getComposedAddress() {
      return {
        "locality": this.selectedCity,
        "state": this.selectedRegion,
        "country": this.country.long_name,
        "country_code": this.country.short_name,
        "province": this.selectedProvince,
        "postal_code": this.selectedPostal,
        "route": this.selectedAddressLine,
        "street_number": this.selectedStreetNumber,
        "latitude": this.placeLat,
        "longitude": this.placeLong,
        "formatted": this.formattedAddress(),
      }
    },

    formattedAddress() {
      const info = []
      if (this.selectedAddressLine) {
        info.push(this.selectedAddressLine);
      }
      if (this.selectedStreetNumber) {
        info.push(this.selectedStreetNumber);
      }
      if (this.selectedCity) {
        info.push(this.selectedCity);
      }
      if (this.country.long_name) {
        info.push(this.country.long_name);
      }
      if (this.selectedPostal) {
        info.push(this.selectedPostal);
      }
      if (this.selectedProvince) {
        info.push(this.selectedProvince);
      }
      if (this.selectedRegion) {
        info.push(this.selectedRegion);
      }
      return info.join(", ");
    }
  },
};
</script>
