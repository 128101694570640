import Vue from "vue";
import moment from "moment";
import VueI18n from "vue-i18n";
import en from "@/core/config/i18n_validate/en";
import es from "@/core/config/i18n_validate/es";
import fr from "@/core/config/i18n_validate/fr";
import it from "@/core/config/i18n_validate/it";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
} from "vee-validate/dist/vee-validate.full";
import api from "@/core/services/api.service";
import i18n from "@/core/plugins/vue-i18n";

const language = localStorage.getItem("language") || "en";

const lang = new VueI18n({
  locale: language,
  messages: {
    en: {
      validations: en,
    },
    it: {
      validations: it,
    },
    es: {
      validations: es,
    },
    fr: {
      validations: fr,
    },
  },
});

configure({
  defaultMessage: (field, values) => {
    values._field_ = lang.t(`${field.toLowerCase()}`);
    return lang.t(`validations.messages.${values._rule_}`, values);
  },
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

extend("max_digits", {
  validate(value, { DIGITS }) {
    const digits = value.replace(",", "").replace(".", "");
    return digits.length <= DIGITS;
  },
  params: ["DIGITS"],
  message: "Max {DIGITS} digits allowed",
});

extend("date_format", {
  validate(value, { DATE_FORMAT }) {
    return moment(value, DATE_FORMAT, true).isValid();
  },
  params: ["DATE_FORMAT"],
  message: "The {_field_} field must have the {DATE_FORMAT} format",
});

extend("before", {
  validate(value, { BEFORE_DATE }) {
    const val = moment(value, true);
    const checker = moment(BEFORE_DATE, true);
    return val.isSameOrBefore(checker);
  },
  params: ["BEFORE_DATE"],
  message: "The {_field_} field must be before {BEFORE_DATE}",
});

extend("before_now", {
  validate(value) {
    const val = moment(value, true);
    return val.isSameOrBefore(moment());
  },
  message: "the {_field_} field can't be in the future.",
});

// Like 'before', but it validates only if BEFORE_DATE is present.
extend("before_if", {
  validate(value, { BEFORE_DATE }) {
    if (!BEFORE_DATE) {
      return true;
    }
    const val = moment(value, true);
    const checker = moment(BEFORE_DATE, true);
    return val.isSameOrBefore(checker);
  },
  params: ["BEFORE_DATE"],
  message: i18n.t("The {_field_} field must be before {BEFORE_DATE}"),
});

extend("after", {
  validate(value, { DATE }) {
    const val = moment(value, true);
    const date = moment(DATE, true);
    return val.isSameOrAfter(date);
  },
  params: ["DATE"],
  message: i18n.t("The {_field_} field can't be before {DATE}"),
});

extend("after_if", {
  validate(value, { BEFORE_DATE }) {
    if (!BEFORE_DATE) {
      return true;
    }
    const val = moment(value, true);
    const checker = moment(BEFORE_DATE, true);
    return val.isSameOrAfter(checker);
  },
  params: ["BEFORE_DATE"],
  message: "The {_field_} field can't be before {BEFORE_DATE}",
});

extend(
  "custom_mail",
  {
    async validate(value, { mailObject }) {
      const result = await api.post("validators/email_validate/", {
        value,
        create: mailObject.id == undefined,
      });
      return result.data.error ? result.data.error : result.data.valid;
    },
    params: ["mailObject"],
  },
  {
    immediate: false,
  }
);

extend(
  "telephone",
  {
    async validate(value, { telObject }) {
      if (value.length < 5) {
        return false;
      }

      const result = await api.post("validators/phone_validate/", {
        value,
        create: telObject.id == undefined,
      });
      return result.data.error ? result.data.error : result.data.valid;
    },
    params: ["telObject"],
  },
  {
    immediate: false,
  }
);

extend("personal_phone_unique", {
  validate(value, phones) {
    return !phones.find((el) => el === value);
  },
  message: "this telephone has already been registered",
});

extend("vat", {
  async validate(value, { countryOfEstablishment, CREATE }) {
    value = value.trim();
    if (value.length > 7 && countryOfEstablishment) {
      const result = await api.post("validators/vat_validate/", {
        value,
        country: countryOfEstablishment,
        create: CREATE,
      });
      return result.data.error ? result.data.error : result.data.valid;
    } else return "Country does not match VAT number";
  },
  params: ["countryOfEstablishment", "CREATE"],
});

extend("vat_country", {
  validate(value, { countryOfEstablishment }) {
    if (!value.startsWith(countryOfEstablishment))
      return `VAT always starts with country code ${countryOfEstablishment}`;
    else return true;
  },
  params: ["countryOfEstablishment"],
});

extend("fiscal_id", {
  params: ["CREATE", "COUNTRY", "LEGAL_PERSON", "PERSONA", "DATA"],
  async validate(value, { CREATE, COUNTRY, LEGAL_PERSON, PERSONA, DATA }) {
    const body = {
      value,
      create: CREATE,
      country: COUNTRY,
      legal_person: LEGAL_PERSON,
    };
    if (PERSONA) body.persona_id = PERSONA;
    if (DATA) body.persona_data = DATA;
    const result = await api.post("validators/fiscalid_validate/", body);
    return result.data.error ? result.data.error : result.data.valid;
  },
});

extend("email_unique", {
  async validate(value) {
    const body = {
      main: true,
      value,
    };
    // Check if email
    if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(value)) {
      return "This field must be a valid email";
    }
    const result = await api.post("validators/email_validate/", body);
    return result.data.error ? result.data.error : result.data.valid;
  },
});

extend("personal_email_unique", {
  validate(value, mails) {
    return !mails.find((el) => el === value);
  },
  message: "this mail has already been registered",
});

extend("url", {
  validate(value) {
    const rule =
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
    return rule.test(value);
  },
  message: "the {_field_} field must be a valid URL",
});

extend("alphaNumeric", {
  validate(value) {
    const rule = /^[A-Za-z0-9 ]+$/gi;
    return rule.test(value);
  },
  message: "the {_field_} field can't contain special characters",
});

extend("drivingLicenseNumber", {
  validate(value) {
    if (!value || value.length < 10) return false;

    const first = value.slice(0, 1);
    if (!first.match(/[A-Za-z]/i)) return false;

    const last = value[value.length - 1];
    if (!last.match(/[A-Za-z]/i)) return false;

    return true;
  },
  message: "not a valid eu driving license number",
});

extend("a1_not_same_as", {
  // validate that the posting country for A1 art12 is not the same as the emitting country
  params: ["otherValue", "otherName", "certificateKind"],
  validate(value, { otherValue, certificateKind }) {
    if (Array.isArray(value)) {
      // if `otherValue` is an array, check if `value` is in the array and if certificateKind is not ART13
      return !value.some(
        (item) => item === otherValue && certificateKind !== "ART13"
      );
    }
    // Handles string case
    return value !== otherValue;
  },
  message(field, { otherName }) {
    return i18n.t(`The ${field} field should not be the same as ${otherName}`);
  },
});


extend("a1_art13_secondment", {
  // validate that the secondment country is included in the posting_country array, 
  // and that the value have at least another country in the array as ART 13 is for multiple countries
  params: ["secondmentCountry"],
  validate(value, { secondmentCountry }) {
    return value.length > 1 && value.includes(secondmentCountry);
  },
  message(field, { secondmentCountry }) {
    return i18n.t("a1_art13_secondment_validator_message", { field, secondmentCountry });  },
});