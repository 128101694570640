<template>
  <button class="btn btn-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3"
    data-wizard-type="action-submit" @click.prevent="$emit('click')">
    <slot></slot>
    {{ getLabel }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    getLabel() {
      return !this.label ? this.$t("Submit") : this.label;
    },
  },
};
</script>
