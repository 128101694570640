import Vue from "vue";
import Router from "vue-router";
import {
  auth,
  isOnlyMaps,
  checkFocusCompany
} from "@/core/middlewares";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [{
      path: "/",
      redirect: () => "/dashboard",
      component: () => import(
        /* webpackChunkName: "main-path" */
        "@/view/layout/Layout"),
      meta: {
        middlewares: [auth, isOnlyMaps],
      },
      children: [{
          path: "/dashboard",
          name: "dashboard",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */
              "@/view/pages/Dashboard.vue"
            ),
        },
        {
          path: "/risk-map",
          name: "risk-map",
          component: () =>
            import(
              /* webpackChunkName: "risk-map" */
              "@/view/pages/RiskMap.vue"
            ),
        },
        {
          path: "/reports",
          name: "reports",
          component: () =>
            import(
              /* webpackChunkName: "reports" */
              "@/view/pages/Reports.vue"
            ),
        },
        {
          path: "checkout",
          name: "checkout",
          component: () =>
            import(
              /* webpackChunkName: "checkout" */
              "@/view/pages/checkout/Detail.vue"
            ),
        },
        {
          path: "orders",
          name: "manage-orders-company",
          component: () =>
            import(
              /* webpackChunkName: "manage-orders-company" */
              "@/view/pages/legal-person/components/LegalPersonManageOrders.vue"
            ),
        },
        {
          path: "subscriptions",
          name: "subscriptions",
          component: () =>
            import(
              /* webpackChunkName: "subscriptions" */
              "@/view/pages/subscriptions/Manage.vue"
            ),
        },
        {
          path: "/users",
          name: "focus-users",
          redirect: "focus-users.users-manage",
          component: () =>
            import(
              /* webpackChunkName: "focus-users.users-manage" */
              "@/view/pages/company-users/Layout.vue"
            ),
          children: [{
              path: "manage",
              name: "focus-users.user-manage",
              component: () =>
                import(
                  /* webpackChunkName: "focus-users.user-manage" */
                  "@/view/pages/company-users/Manage.vue"
                ),
            },
            {
              path: "create",
              name: "focus-users.user-create",
              component: () =>
                import(
                  /* webpackChunkName: "focus-users.user-create" */
                  "@/view/pages/company-users/Create.vue"
                ),
            },
            {
              path: "table",
              name: "focus-users.user-table",
              component: () =>
                import(
                  /* webpackChunkName: "focus-users.user-table" */
                  "@/view/pages/company-users/UserPage.vue"
                ),
            },
          ],
        },
        {
          path: "associate-contract/:ppId?",
          name: "associate-contract",
          component: () =>
            import(
              /* webpackChunkName: "associate-contract" */
              "@/view/pages/contract/Associate.vue"
            ),
          props: true,
        },
        {
          path: "employees-manage",
          name: "employees-manage",
          component: () =>
            import(
              /* webpackChunkName: "employees-manage" */
              "@/view/pages/employees/Manage.vue"
            ),
        },
        {
          path: "employees-documents",
          name: "employees-documents",
          component: () =>
            import(
              /* webpackChunkName: "employees-documents" */
              "@/view/pages/employees/Documents.vue"
            ),
        },
        {
          path: "physical-person",
          redirect: "/physical-person/manage",
          name: "physical-person",
          component: () =>
            import(
              /* webpackChunkName: "physical-person" */
              "@/view/pages/physical-person/Layout.vue"
            ),
          children: [{
              path: "manage",
              name: "manage-physical-person",
              component: () =>
                import(
                  /* webpackChunkName: "manage-physical-person" */
                  "@/view/pages/physical-person/Manage.vue"
                ),
            },
            {
              path: "create/:isEmployee?",
              name: "create-physical-person",
              component: () =>
                import(
                  /* webpackChunkName: "create-physical-person" */
                  "@/view/pages/physical-person/Create.vue"
                ),
            },
            {
              path: "employee/",
              name: "create-employee",
              props: {
                isEmployee: true
              },
              component: () =>
                import(
                  /* webpackChunkName: "create-physical-person" */
                  "@/view/pages/physical-person/Create.vue"
                ),
            },
            {
              path: ":userID",
              name: "detail-physical-person",
              component: () =>
                import(
                  /* webpackChunkName: "detail-physical-person" */
                  "@/view/pages/physical-person/Detail.vue"
                ),
              redirect: {
                name: "detail-physical-information",
              },
              children: [{
                  path: "information",
                  name: "detail-physical-information",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-information" */
                      "@/view/pages/physical-person/components/PhysicalPersonPersonalInfoCard.vue"
                    ),
                },
                {
                  path: "contacts",
                  name: "detail-physical-contacts",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-contacts" */
                      "@/view/pages/physical-person/components/PhysicalPersonContactCard.vue"
                    ),
                },
                {
                  path: "address",
                  name: "detail-physical-address",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-address" */
                      "@/view/pages/physical-person/components/PhysicalPersonAddressCard.vue"
                    ),
                },
                {
                  path: "documents",
                  name: "detail-physical-documents",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-documents" */
                      "@/view/pages/physical-person/components/PhysicalPersonDocumentCard.vue"
                    ),
                },
                {
                  path: "password",
                  name: "detail-physical-password",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-password" */
                      "@/view/pages/physical-person/components/PhysicalPersonPasswordCard.vue"
                    ),
                },
                {
                  path: "orders",
                  name: "detail-physical-orders",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-orders" */
                      "@/view/pages/physical-person/components/PhysicalPersonOrdersCard.vue"
                    ),
                },
                {
                  path: "settings",
                  name: "detail-physical-settings",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-settings" */
                      "@/view/pages/physical-person/components/PhysicalPersonSettingsCard.vue"
                    ),
                },
                {
                  path: "assignments",
                  name: "detail-physical-assignments",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-assignments" */
                      "@/view/pages/physical-person/components/PhysicalPersonAssignmentsCard.vue"
                    ),
                },
                {
                  path: "employment-details",
                  name: "detail-physical-employment",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-physical-employment" */
                      "@/view/pages/physical-person/components/PhysicalPersonEmployeeCard.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "/legal-person",
          redirect: "/legal-person/manage",
          name: "legal-person",
          component: () =>
            import(
              /* webpackChunkName: "legal-person" */
              "@/view/pages/legal-person/Layout.vue"
            ),
          children: [{
              path: "manage",
              name: "manage-legal-person",
              component: () =>
                import(
                  /* webpackChunkName: "manage-legal-person" */
                  "@/view/pages/legal-person/Manage.vue"
                ),
            },
            {
              path: "create",
              name: "create-legal-person",
              component: () =>
                import(
                  /* webpackChunkName: "create-legal-person" */
                  "@/view/pages/legal-person/Create.vue"
                ),
            },
            {
              path: ":companyID",
              name: "detail-legal-person",
              component: () =>
                import(
                  /* webpackChunkName: "detail-legal-person" */
                  "@/view/pages/legal-person/Detail.vue"
                ),
              redirect: {
                name: "detail-legal-information",
              },
              children: [{
                  path: "information",
                  name: "detail-legal-information",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-information" */
                      "@/view/pages/legal-person/components/LegalPersonPersonalInfoCard.vue"
                    ),
                },
                {
                  path: "contacts",
                  name: "detail-legal-contacts",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-contacts" */
                      "@/view/pages/legal-person/components/LegalPersonContactsCard.vue"
                    ),
                },
                {
                  path: "address",
                  name: "detail-legal-address",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-address" */
                      "@/view/pages/legal-person/components/LegalPersonAddressCard.vue"
                    ),
                },
                {
                  path: "documents",
                  name: "detail-legal-documents",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-documents" */
                      "@/view/pages/legal-person/components/LegalPersonDocumentCard.vue"
                    ),
                },
                {
                  path: "contact",
                  name: "detail-legal-contact-person",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-contact-person" */
                      "@/view/pages/legal-person/components/LegalPersonContactPersonCard.vue"
                    ),
                },
                {
                  path: "groups",
                  name: "detail-legal-groups",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-groups" */
                      "@/view/pages/legal-person/components/LegalPersonCompanyGroupsCard.vue"
                    ),
                },
                {
                  path: "vat",
                  name: "detail-legal-vat",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-vat" */
                      "@/view/pages/legal-person/components/LegalPersonVatCard.vue"
                    ),
                },
                {
                  path: "fiscal",
                  name: "detail-legal-fiscal",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-fiscal" */
                      "@/view/pages/legal-person/components/LegalPersonFiscalCard.vue"
                    ),
                },
                {
                  path: "social-security",
                  name: "detail-legal-social-security",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-social-security" */
                      "@/view/pages/legal-person/components/LegalPersonSocialSecurityCard.vue"
                    ),
                },
                {
                  path: "employees",
                  name: "detail-legal-employees",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-employees" */
                      "@/view/pages/legal-person/components/LegalPersonEmployeesCard"
                    ),
                },
                {
                  path: "assignments",
                  name: "detail-legal-assignments",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-assignments" */
                      "@/view/pages/legal-person/components/LegalPersonAssignmentsCard.vue"
                    ),
                },
                {
                  path: "settings",
                  name: "detail-legal-settings",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-legal-setting" */
                      "@/view/pages/legal-person/components/LegalPersonSettingsCard.vue"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "national-contract",
          name: "national-contract",
          component: () =>
            import(
              /* webpackChunkName: "national-contract" */
              "@/view/pages/contract/NationalContract.vue"
            ),
        },
        {
          path: "national-contract/:ID",
          name: "national-contract-level",
          component: () =>
            import(
              /* webpackChunkName: "national-contract-level" */
              "@/view/pages/contract/NationalContractLevel.vue"
            ),
        },
        {
          path: "contract-comparison",
          name: "contract-comparison",
          component: () =>
            import(
              /* webpackChunkName: "contract-comparison" */
              "@/view/pages/contract/ContractComparison.vue"
            ),
        },
        {
          path: "contract-comparison-list",
          name: "contract-comparison-list",
          component: () =>
            import(
              /* webpackChunkName: "contract-comparison-list" */
              "@/view/pages/contract/ContractComparisonList.vue"
            ),
        },
        {
          path: "secondment",
          component: () =>
            import(
              /* webpackChunkName: "secondment" */
              "@/view/pages/secondment/Layout.vue"
            ),
          children: [{
              path: "create",
              name: "create-secondment",
              component: () =>
                import(
                  /* webpackChunkName: "create-secondment" */
                  "@/view/pages/secondment/Create.vue"
                ),
            },
            {
              path: "manage",
              name: "manage-secondment",
              component: () =>
                import(
                  /* webpackChunkName: "manage-secondment" */
                  "@/view/pages/secondment/Manage.vue"
                ),
            },
            {
              path: "edit/:secondmentId",
              name: "edit-secondment",
              component: () =>
                import(
                  /* webpackChunkName: "edit-secondment" */
                  "@/view/pages/secondment/Edit.vue"
                ),
            },
            {
              path: ":secondmentId",
              name: "detail-secondment",
              meta: {
                middlewares: [checkFocusCompany],
              },
              component: () =>
                import(
                  /* webpackChunkName: "detail-secondment" */
                  "@/view/pages/secondment/Detail.vue"
                ),
              redirect: {
                name: "detail-secondment-information",
              },
              children: [{
                  path: "information",
                  name: "detail-secondment-information",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-information" */
                      "@/view/pages/secondment/components/SecondmentInformationCard"
                    ),
                },
                {
                  path: "Declarations",
                  name: "declarations-of-detachment",
                  component: () =>
                    import(
                      /* webpackChunkName: "declarations-of-detachment" */
                      "@/view/pages/secondment/components/SecondmentDeclarationCard"
                    ),
                },
                {
                  path: "employees",
                  name: "detail-secondment-employees",
                  component: () =>
                    import(
                      /* webpackChunkName: "dashbodetail-secondment-employeesard" */
                      "@/view/pages/secondment/components/SecondmentEmployeesCard"
                    ),
                },
                {
                  path: "client",
                  name: "detail-secondment-client",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-client" */
                      "@/view/pages/secondment/components/SecondmentClientCard"
                    ),
                },
                {
                  path: "activities",
                  name: "detail-secondment-activities",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-activities" */
                      "@/view/pages/secondment/components/SecondmentActivitiesCard"
                    ),
                },
                {
                  path: "contractor",
                  name: "detail-secondment-contractor",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-contractor" */
                      "@/view/pages/secondment/components/SecondmentContractorCard"
                    ),
                },
                {
                  path: "suppliers",
                  name: "detail-secondment-suppliers",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-suppliers" */
                      "@/view/pages/secondment/components/SecondmentSuppliersCard"
                    ),
                },
                {
                  path: "documents",
                  name: "detail-secondment-documents",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-documents" */
                      "@/view/pages/secondment/components/SecondmentDocumentsCard"
                    ),
                },
                {
                  path: "history",
                  name: "detail-secondment-history",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-history" */
                      "@/view/pages/secondment/components/SecondmentHistoryCard"
                    ),
                },
                {
                  path: "travel-plans",
                  name: "detail-secondment-travel-plans",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-travel-plans" */
                      "@/view/pages/secondment/components/SecondmentTravelPlansCard"
                    ),
                },
                {
                  path: "reports",
                  name: "detail-secondment-reports",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-reports" */
                      "@/view/pages/secondment/components/SecondmentReportsCard"
                    ),
                },
                {
                  path: "orders",
                  name: "detail-secondment-orders",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-secondment-products" */
                      "@/view/pages/secondment/components/SecondmentOrdersCard"
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "notifications",
          name: "manage-notification",
          component: () =>
            import(
              /* webpackChunkName: "manage-notification" */
              "@/view/pages/notification/Manage.vue"
            ),
        },
        {
          name: "acceptance-sensitive-data",
          path: "/acceptance-sensitive-data",
          component: () =>
            import(
              /* webpackChunkName: "acceptance-sensitive-data" */
              "@/view/pages/AcceptanceSensitiveData"
            ),
          meta: {
            middlewares: [auth],
          },
        },
        {
          path: "administration",
          component: () =>
            import(
              /* webpackChunkName: "administration" */
              "@/view/pages/admin/Layout.vue"
            ),
          children: [{
              path: "nations",
              component: () =>
                import(
                  /* webpackChunkName: "nations" */
                  "@/view/pages/admin/nations/Layout.vue"
                ),
              redirect: {
                name: "manage-nations",
              },
              children: [{
                  path: "manage-nations",
                  name: "manage-nations",
                  component: () =>
                    import(
                      /* webpackChunkName: "manage-nations" */
                      "@/view/pages/admin/nations/Manage.vue"
                    ),
                },
                {
                  path: "manage-groups",
                  name: "manage-groups",
                  component: () =>
                    import(
                      /* webpackChunkName: "manage-groups" */
                      "@/view/pages/admin/nations/ManageGroups.vue"
                    ),
                },
                {
                  path: ":nationId",
                  name: "detail-nation",
                  component: () =>
                    import(
                      /* webpackChunkName: "detail-nation" */
                      "@/view/pages/admin/nations/Detail.vue"
                    ),
                  redirect: {
                    name: "detail-nation-information",
                  },
                  children: [{
                      path: "information",
                      name: "detail-nation-information",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-information" */
                          "@/view/pages/admin/nations/components/NationInformation.vue"
                        ),
                    },
                    {
                      path: "secondments",
                      name: "detail-nation-secondments",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-secondments" */
                          "@/view/pages/admin/nations/components/NationSecondmentSettings.vue"
                        ),
                    },
                    {
                      path: "legal-type",
                      name: "detail-nation-legal-type",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-legal-type" */
                          "@/view/pages/admin/nations/components/NationLegalType.vue"
                        ),
                    },
                    {
                      path: "administrative",
                      name: "detail-nation-administrative",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-administrative" */
                          "@/view/pages/admin/nations/components/NationAdministrativeStructures.vue"
                        ),
                    },
                    {
                      path: "prices",
                      name: "detail-nation-prices",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-prices" */
                          "@/view/pages/admin/nations/components/NationPriceList.vue"
                        ),
                    },
                    {
                      path: "groups",
                      name: "detail-nation-groups",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-groups" */
                          "@/view/pages/admin/nations/components/NationGroups.vue"
                        ),
                    },
                    {
                      path: "contracts",
                      name: "detail-nation-contracts",
                      component: () =>
                        import(
                          /* webpackChunkName: "detail-nation-contracts" */
                          "@/view/pages/admin/nations/components/NationNationalContracts.vue"
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: "staff",
              component: () =>
                import(
                  /* webpackChunkName: "staff" */
                  "@/view/pages/admin/staff"
                ),
              redirect: {
                name: "administration-staff-manage",
              },
              name: "administration-staff",
              children: [{
                  path: "manage",
                  name: "administration-staff-manage",
                  component: () =>
                    import(
                      /* webpackChunkName: "administration-staff-manage" */
                      "@/view/pages/admin/staff/Manage.vue"
                    ),
                },
                {
                  path: "create",
                  name: "administration-staff-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "administration-staff-create" */
                      "@/view/pages/admin/staff/Create.vue"
                    ),
                },
              ],
            },
            {
              path: "users",
              component: () =>
                import(
                  /* webpackChunkName: "users" */
                  "@/view/pages/admin/users"
                ),
              redirect: {
                name: "administration-users-manage",
              },
              name: "administration-users",
              children: [{
                  path: "manage",
                  name: "administration-users-manage",
                  component: () =>
                    import(
                      /* webpackChunkName: "administration-users-manage" */
                      "@/view/pages/admin/users/Manage.vue"
                    ),
                },
                {
                  path: "create/:type",
                  name: "administration-users-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "administration-users-create" */
                      "@/view/pages/admin/users/Create.vue"
                    ),
                },
              ],
            },
            {
              path: "notification",
              name: "administration-notification",
              component: () =>
                import(
                  /* webpackChunkName: "administration-notification" */
                  "@/view/pages/admin/Notification.vue"
                ),
            },
            {
              path: "npg",
              component: () =>
                import(
                  /* webpackChunkName: "npg" */
                  "@/view/pages/npg/Layout.vue"
                ),
              children: [{
                  path: "create",
                  name: "npg-form",
                  component: () =>
                    import(
                      /* webpackChunkName: "npg-form" */
                      "@/view/pages/npg/Create.vue"
                    ),
                },
                {
                  path: ":npgId/details",
                  name: "npg-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "npg-edit" */
                      "@/view/pages/npg/Create.vue"
                    ),
                },
                {
                  path: "manage",
                  name: "manage-npg",
                  component: () =>
                    import(
                      /* webpackChunkName: "manage-npg" */
                      "@/view/pages/npg/Manage.vue"
                    ),
                },
                {
                  path: ":npgId",
                  name: "npg-view",
                  component: () =>
                    import(
                      /* webpackChunkName: "npg-view" */
                      "@/view/pages/npg/Detail.vue"
                    ),
                },
              ],
            },
            {
              path: "manage-focus",
              name: "manage-focus",
              component: () =>
                import(
                  /* webpackChunkName: "manage-focus" */
                  "@/view/pages/admin/focus/Manage.vue"
                ),
            },
            {
              path: "manage-orders",
              name: "manage-orders",
              component: () =>
                import(
                  /* webpackChunkName: "manage-orders" */
                  "@/view/pages/admin/orders/Manage.vue"
                ),
            },
            {
              path: "admin-secondments",
              name: "admin-secondments",
              component: () =>
                import(
                  /* webpackChunkName: "admin-secondments" */
                  "@/view/pages/admin/secondments/manage.vue"
                ),
            },
            {
              path: "pubblication",
              name: "pubblication",
              component: () =>
                import(
                  /* webpackChunkName: "pubblication" */
                  "@/view/pages/admin/post/Layout.vue"
                ),
              children: [{
                path: "manage",
                name: "pubblication-index",
                component: () =>
                  import(
                    /* webpackChunkName: "pubblication-index" */
                    "@/view/pages/admin/post/Index.vue"
                  ),
                redirect: {
                  name: "source-list",
                },
                // TODO: completare importazione source da change detection
                children: [{
                    path: "sources",
                    name: "source-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "source-list" */
                        "@/view/pages/admin/post/Manage.vue"
                      ),
                  },
                  {
                    path: "sources/:sourceID/details",
                    name: "detail-source",
                    component: () =>
                      import(
                        /* webpackChunkName: "detail-source" */
                        "@/view/pages/admin/post/Detail.vue"
                      ),
                  },
                  {
                    path: "posts",
                    name: "post-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "post-list" */
                        "@/view/pages/admin/post/Manage.vue"
                      ),
                  },
                ],
              }],
            },
          ],
        },
        {
          name: "cookies-policy",
          path: "cookies-policy",
          component: () =>
            import(
              /* webpackChunkName: "ap-cookies-policy" */
              "@/view/pages/CookiePolicy.vue"
            ),
        },
        {
          name: "privacy-policy",
          path: "privacy-policy",
          component: () =>
            import(
              /* webpackChunkName: "ap-privacy-policy" */
              "@/view/pages/PrivacyPolicy.vue"
            ),
        },
        {
          name: "terms-conditions",
          path: "terms-conditions",
          component: () =>
            import(
              /* webpackChunkName: "ap-terms-conditions" */
              "@/view/pages/TermCondition.vue"
            ),
        },
        {
          name: "general-conditions",
          path: "general-conditions",
          component: () =>
            import(
              /* webpackChunkName: "ap-general-conditions" */
              "@/view/pages/GeneralConditions.vue"
            ),
        },
        {
          path: "focuschange",
          name: "focus-change",
          component: () =>
            import(
              /* webpackChunkName: "post-list" */
              "@/view/layout/ChangeFocus.vue"
            ),
        }
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () =>
        import(
          /* webpackChunkName: "custom-error" */
          "@/view/pages/error/Error.vue"
        ),
      children: [{
          path: "error-1",
          name: "error-1",
          component: () =>
            import(
              /* webpackChunkName: "error-1" */
              "@/view/pages/error/Error-1.vue"
            ),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () =>
            import(
              /* webpackChunkName: "error-2" */
              "@/view/pages/error/Error-2.vue"
            ),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () =>
            import(
              /* webpackChunkName: "error-3" */
              "@/view/pages/error/Error-3.vue"
            ),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () =>
            import(
              /* webpackChunkName: "error-4" */
              "@/view/pages/error/Error-4.vue"
            ),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () =>
            import(
              /* webpackChunkName: "error-5" */
              "@/view/pages/error/Error-5.vue"
            ),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () =>
            import(
              /* webpackChunkName: "error-6" */
              "@/view/pages/error/Error-6.vue"
            ),
        },
      ],
    },
    {
      name: "login",
      path: "/login",
      component: () =>
        import(
          /* webpackChunkName: "login" */
          "@/view/pages/auth/Login"
        ),
    },
    {
      name: "logout",
      path: "/logout",
      component: () =>
        import(
          /* webpackChunkName: "logout" */
          "@/view/pages/auth/Logout"
        ),
    },
    {
      name: "password-reset",
      path: "/password-reset",
      component: () =>
        import(
          /* webpackChunkName: "password-reset" */
          "@/view/pages/auth/Reset"
        ),
    },
    {
      name: "cookies-policy",
      path: "/cookies-policy/:id",
      component: () =>
        import(
          /* webpackChunkName: "ap-cookies-policy" */
          "@/view/pages/CookiePolicy"
        ),
    },
    {
      name: "privacy-policy",
      path: "/privacy-policy/:id",
      component: () =>
        import(
          /* webpackChunkName: "ap-privacy-policy" */
          "@/view/pages/PrivacyPolicy"
        ),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      name: "404",
      path: "/404",
      component: () => import(
        /* webpackChunkName: "404" */
        "@/view/pages/error/Error-1.vue"
      ),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.middlewares && record.meta.middlewares.length)) {
    const middlewares = to.matched.reduce((acc, curr) => {
      if (curr.meta.middlewares) {
        return [...acc, ...curr.meta.middlewares];
      }

      return acc;
    }, []);

    const context = {
      to,
      from,
      next,
      router,
    };

    for (const middleware of middlewares) {
      const v = middleware(context);
      context.next = v.next;
      if (!v.keepGoing) {
        break;
      }
    }

    context.next();
  } else if (to.name == from.name) {
    return;
  } else {
    next();
  }
});

export default router;