<template>
  <div class="form-group row mb-0">
    <div v-for="(tel, idx) in phones" :key="idx" class="d-flex align-items-center element ">
      <div class="col-xl-9">
        <div class="row">
          <div class="col-xl-4">
            <b-form-select v-model="tel.kind" :disabled="tel.main" :options="options"
              class="form-control form-control-solid h-auto"></b-form-select>
          </div>
          <div class="col-xl-8">
            <div class="col-12">
              <validation-provider v-slot="{ errors }" name="phone" :vid="`phone[${idx}]`" :rules="{
                min: 7,
                telephone: [tel],
                personal_phone_unique: idx === phones.length - 1 ? computedPhoneList : false,
              }">
                <telephone-input v-model="tel.number" :error-messages="errors" class="mb-0"></telephone-input>
              </validation-provider>
            </div>
          </div>
          <div class="col-xl-12 mt-6">
            <div class="col-12 pl-0">
              <validation-provider v-slot="{ errors }" name="address" :vid="`company_address[${idx}]`">
                <euro-select v-model="tel.company_address" :options="addresses" :error-messages="errors"
                  :placeholder="$t('Select an address')" class-name="mb-0"></euro-select>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="d-flex justify-content-center align-items-center m-n1">
          <i v-if="tel.main" v-b-tooltip.hover class="fas fa-circle mr-3" title="Main" :style="{ color: '#00A9BD' }"></i>
          <div v-else :style="{ width: '3.5ch' }"></div>
          <btn-icon class="m-1 py-1 px-2" :tooltip="getTooltipMessage(tel)" icon="fas fa-hand-point-up" size="xs"
            :disabled="!tel.number || tel.kind !== 'MOB'" @click="setMain(tel)"></btn-icon>
          <btn-icon class="m-1 py-1 px-2" :tooltip="$t('Add new telephone')" icon="flaticon2-plus" size="xs"
            :disabled="phones.length === 1 && !phones[0].number" @click="addNewTelephone"></btn-icon>
          <btn-icon class="m-1 py-1 px-2" :tooltip="$t('Remove telephone')" icon="flaticon2-cross" size="xs"
            :disabled="(phones.length === 1 && !phones[0].number) || tel.main" @click="removeTelephone(idx)"></btn-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TelephoneInput from "./TelephoneInput.vue";
// import CompanyTelephoneService from "@/core/services/company/company-telephone.service.js"
import Swal from "sweetalert2";
export default {
  components: { TelephoneInput },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    addresses: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      phones: [],
      mainPhoneIdx: -1,
    };
  },

  computed: {
    computedPhoneList() {
      const list = this.phones.map((el) => el.number);
      list.length--;
      return list;
    },
  },

  watch: {
    phones: {
      deep: true,
      handler(val) {
        const phones = val.filter((el) => el.number);
        this.$emit("input", phones);
        this.mainPhoneIdx = this.phones.findIndex((el) => el.main);
      },
    },
  },

  mounted() {
    if (this.value.length) {
      this.phones = this.value;
    } else {
      this.phones = [{ kind: "FIS", number: "", main: false, company_address: null }];
    }
  },

  methods: {
    getTooltipMessage(tel) {
      const { kind, number } = tel;
      if (!number) return this.$t("Enter a valid number");
      if (kind !== "MOB") return this.$t("Only mobile numbers can be set as main");
      else return this.$t("Set this number as main");
    },

    async setMain(tel) {
      const exMain = this.phones.find((el) => el.main && el.number !== tel.number);
      if (exMain) exMain.main = false;
      tel.main = true;
      this.$emit("updated", tel);
    },

    addNewTelephone() {
      this.phones = [...this.phones, { kind: "FIS", number: "", main: false, company_address: null }];
      this.mainPhoneIdx = this.phones.findIndex((el) => el.main);
    },

    removeTelephone(idx) {
      Swal.fire({
        title: this.$t("Delete"),
        text: this.$t("Are you sure you want to delete this phone?"),
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("Cancel"),
        cancelButtonClass: "btn",
      }).then((res) => {
        if (res.isConfirmed) {
          if (this.phones.length === 1) {
            this.phones.splice(0, 1, { kind: "FIS", number: "", main: true, company_address: null });
          } else {
            this.phones.splice(idx, 1);
          }
          this.$emit("updated", this.phones);
        }
      });
    },

    getPhoneLabelClass(idx) {
      return this.mainPhoneIdx !== -1 && idx !== this.mainPhoneIdx ? "font-weight-light" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.gap {
  gap: 10px;
}

.phone-width {
  width: 15rem;
}

.address-width {
  min-width: 17rem;
}

.element {
  padding-bottom: 1.25rem;
  width: 100%;
}

.element:last-child {
  padding: 0;
}
</style>
