<template>
  <li aria-haspopup="true" :data-menu-toggle="menuToggle" class="menu-item menu-item-submenu"
    :class="{ 'menu-item-active': hasActiveChildren(menuItemActive) }">
    <a href="#" class="menu-link menu-toggle">
      <span v-if="icon && !hasDot" class="svg-icon menu-icon">
        <inline-svg :src="`/media/svg/icons/${icon}`" />
      </span>
      <i v-if="hasDot" class="menu-bullet menu-bullet-dot"><span></span></i>
      <span class="menu-text"> {{ label }} </span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-right">
      <ul class="menu-subnav">
        <slot></slot>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    menuToggle: {
      type: String,
      default: "hover",
    },
    menuItemActive: {
      type: String,
      default: "",
    },
    hasDot: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    hasActiveChildren(match) {
      if (!match) return false;
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
