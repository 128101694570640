var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row"
  }, [_vm.label ? _c('label', {
    staticClass: "col-form-label",
    class: {
      'col-xl-3 col-lg-3': _vm.horizontal,
      'col-12': !_vm.horizontal
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    staticClass: "col-form-label",
    class: {
      'col-xl-9 col-lg-6': _vm.horizontal,
      'col-12': !_vm.horizontal
    }
  }, [_c('div', {
    staticClass: "radio-inline"
  }, _vm._l(_vm.options, function (option, index) {
    return _c('label', {
      key: index,
      staticClass: "radio radio-primary",
      class: {
        'is-invalid': !_vm.valid,
        'radio-disabled': _vm.disabled
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.vModel,
        expression: "vModel"
      }],
      attrs: {
        "type": "radio",
        "disabled": _vm.disabled
      },
      domProps: {
        "value": option.value,
        "checked": _vm._q(_vm.vModel, option.value)
      },
      on: {
        "change": function change($event) {
          _vm.vModel = option.value;
        }
      }
    }), _c('span'), _vm._v(" " + _vm._s(option.text) + " ")]);
  }), 0), !_vm.valid ? _c('div', {
    staticClass: "invalid-feedback d-block my-2",
    domProps: {
      "textContent": _vm._s(_vm.showErrors)
    }
  }) : _vm._e(), _vm.hint ? _c('span', {
    staticClass: "form-text text-muted"
  }, [_vm._v(_vm._s(_vm.hint))]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }