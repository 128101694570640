var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "avatar",
    class: {
      'rounded-image': _vm.rounded
    },
    style: {
      width: _vm.size,
      height: _vm.size
    }
  }, [_c('img', {
    style: {
      width: _vm.size,
      height: _vm.size
    },
    attrs: {
      "src": _vm.src,
      "alt": "avatar"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }