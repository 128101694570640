import API from "../api.service";

export default {
  get PATH() {
    return "orders";
  },

  async checkout(body, id) {
    let r = null;
    try {
      if (id) {
        r = await API.patch(`${this.PATH}/${id}/`, body);
      } else {
        r = await API.post(`${this.PATH}/`, body);
      }
      return r.data;
    } catch (err) {
      console.error("@orders service", err);
      throw err;
    }
  },

  async getAll({
    createdBy,
    focus,
    perPage,
    currentPage,
    sortBy = null,
    status = null,
    invoiced = null,
    sortDesc = null,
    omit = null,
    fields = null,
    expand = null,
    excludeDrafts = null,
    customer_email,
    search,
    paginated,
    company_id = null,
    from_date = null,
    to_date = null,
    assignment_from_date = null,
    assignment_to_date = null,
    product_types = null,
    assigned_to = null
  }) {
    const params = {
      paginated: paginated,
      created_by: createdBy,
      focus: focus,
      limit: perPage,
      search,
      status,
      customer_email: customer_email,
      company_id: company_id
    };
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (currentPage && perPage) {
      params.offset = (currentPage - 1) * perPage;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    if (excludeDrafts) {
      params.exclude_drafts = excludeDrafts;
    }
    if (from_date) {
      params.from_date = from_date;
    }
    if (to_date) {
      params.to_date = to_date;
    }
    if (assignment_from_date) {
      params.assignment_from_date = assignment_from_date;
    }
    if (assignment_to_date) {
      params.assignment_to_date = assignment_to_date;
    }
    if (invoiced !== null) {
      params.invoiced = invoiced;
    }
    if (product_types) {
      params.product_types = product_types.join(',');
    }
    if (assigned_to) {
      params.assigned_to = assigned_to;
    }
    const r = await API.get(`${this.PATH}/`, {
      params,
    });
    return r.data;
  },

  async get(id, {
    focus,
    omit = null,
    fields = null,
    expand = null
  }) {
    const params = {
      focus: focus,
    };
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const r = await API.get(`${this.PATH}/${id}/`, {
      params,
    });
    return r.data;
  },

  async updateStatus({
    newStatus,
    id
  }) {
    return await API.post(`${this.PATH}/${id}/process_order/`, {
      action: newStatus,
    });
  },

  async updateStatusItem(orderId, {
    newStatus,
    id
  }) {
    return await API.post(`${this.PATH}/${orderId}/process_order_item/`, {
      action: newStatus,
      item: id,
    });
  },
  
  async updateInvoicedStatusItem(orderId, {
    newStatus,
    id,
    invoice_date,
    invoice_ref
  }) {
    let data = {
      invoiced: newStatus,
      item: id,
    };
    if (invoice_date) {
      data.invoice_date = invoice_date;
    }
    if (invoice_ref) {
      data.invoice_ref = invoice_ref;
    }
    return await API.post(`${this.PATH}/${orderId}/set_invoice_status_order_item/`, data);
  },

  async updateOrder({
    id,
    items
  }) {
    const r = await API.patch(`${this.PATH}/${id}/`, items);
    return r.data;
  },

  async getProductPrice({
    productType,
    country,
    category,
    frequency
  }) {
    let params = {
      product_type: productType,
      country: country,
      category: category,
    };

    if (frequency)
      // senza la chiave frequency ottengo una mappa con tutte le frequency
      params.frequency = frequency;

    const r = await API.post(`${this.PATH}/get_product_price/`, params);
    return r.data;
  },

  async updateOrderItem(body, id) {
    return await API.patch(`${this.PATH}-orderitem/${id}/`, body);
  },
};