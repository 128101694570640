import Vue from "vue";
import Vuex from "vuex";
import VuexReset from '@ianwalter/vuex-reset'

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module"
import user from "./user.module";
import npg from "./npg.module";
import constants from "./constants.module";
import wizard from "./wizard.module";
import quickActions from "./quickActions.module";
import source from "./source.module";
import addresses from "./addresses.module";
import notification from "./notification.module";
import cart from "./cart.module";
import capabilities from "./capabilities.module";
import categories from "./category.module"

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [VuexReset()],
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    user,
    constants,
    wizard,
    quickActions,
    npg,
    source,
    addresses,
    notification,
    cart,
    capabilities,
    categories
  }
});