<template>
  <button class="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3"
    data-wizard-type="action-prev" @click.prevent="$emit('click')">
    <span class="svg-icon svg-icon-md mr-2">
      <inline-svg :src="`/media/svg/icons/Navigation/${icon}`" />
    </span>
    {{ $t("Previous") }}
  </button>
</template>

<script>
export default {
  props: {
    showSpinner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icon: 'Arrow-left.svg'
    }
  },
  watch: {
    showSpinner: {
      immediate: true,
      handler(val) {
        if (val) {
          this.icon = 'Waiting.svg'
        }

        this.icon = 'Arrow-left.svg'
      }
    }
  }
};
</script>
