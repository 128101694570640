import api from "../api.service";

class NpgTerritorialJurisdictionService {
  get PATH() {
    return "nations-country";
  }
  async getAll() {
    return await this.getPaginated(1000, 0);
  }

  async getPaginated(limit, offset) {
    const res = await api.get(`${this.PATH}/`, { params: { limit, offset } });
    return res.data;
  }
}

export default new NpgTerritorialJurisdictionService();
