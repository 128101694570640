var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("topbar"), _c('div', [_c('b-table', _vm._b({
    ref: "table",
    staticClass: "detail",
    attrs: {
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-icon-left": "",
      "show-empty": "",
      "thead-class": {
        'hidden_header': _vm.theadHidden
      }
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-primary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-3"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t("loading...")))])], 1)];
      },
      proxy: true
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_vm._t("row-details", null, {
          "row": row
        })];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('h4', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm.$t("No Items Found")))])])];
      },
      proxy: true
    }, _vm._l(_vm.tablePropsComputed.fields, function (header, idx) {
      return {
        key: "head(".concat(header.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('span', {
            key: idx
          }, [_vm._t("header.".concat(header.key), function () {
            return [_vm._v(" " + _vm._s(label) + " ")];
          })], 2)];
        }
      };
    }), _vm._l(_vm.tablePropsComputed.fields, function (cell, idx) {
      return {
        key: "cell(".concat(cell.key, ")"),
        fn: function fn(row) {
          return [_vm._t("cell.".concat(cell.key), function () {
            return [_c('div', {
              key: idx,
              staticClass: "h-100"
            }, [_vm._v(_vm._s(row.item[cell.key]))])];
          }, {
            "item": row.item,
            "row": row
          })];
        }
      };
    })], null, true)
  }, 'b-table', _vm.tablePropsComputed, false))], 1), _vm.paginated ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "w-50"
  }, [_c('b-pagination', {
    staticClass: "mb-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "first-number": "",
      "last-number": ""
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('div', {
    staticClass: "w-50 text-right"
  }, [_c('b-form-select', {
    staticClass: "per-page-select form-control form-control-solid h-auto",
    attrs: {
      "options": _vm.perPageOptions,
      "size": "sm"
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }), _c('span', {
    staticClass: "pl-2"
  }, [_vm._v(_vm._s(_vm.$t("Showing")) + " " + _vm._s(_vm.start) + " - " + _vm._s(_vm.end) + " " + _vm._s(_vm.$t("of")) + " " + _vm._s(_vm.totalRows) + " ")])], 1)]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }