var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    attrs: {
      "to": _vm.to
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var href = _ref.href,
          navigate = _ref.navigate,
          isActive = _ref.isActive,
          isExactActive = _ref.isExactActive;
        return [_c('li', {
          staticClass: "menu-item",
          class: [isActive && 'menu-item-active', isExactActive && 'menu-item-active'],
          attrs: {
            "aria-haspopup": "true"
          }
        }, [_c('a', {
          staticClass: "menu-link",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm.icon && !_vm.hasDot ? _c('span', {
          staticClass: "svg-icon menu-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/".concat(_vm.icon)
          }
        })], 1) : _vm._e(), _vm.hasDot ? _c('i', {
          staticClass: "menu-bullet menu-bullet-dot"
        }, [_c('span')]) : _vm._e(), _c('span', {
          staticClass: "menu-text",
          class: {
            '': _vm.vertical
          }
        }, [_vm._v(_vm._s(_vm.label))])])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }