<template>
  <div>
    <slot name="topbar"></slot>
    <div>
      <b-table ref="table" v-bind="tablePropsComputed" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
        :per-page="perPage" class="detail" :current-page="currentPage" sort-icon-left show-empty
        :thead-class="{ 'hidden_header': theadHidden }">
        <!-- BUSY STATE -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-3"></b-spinner>
            <strong>{{ $t("loading...") }}</strong>
          </div>
        </template>

        <template #row-details="row">
          <slot name="row-details" :row="row"></slot>
        </template>

        <!-- NO ITEMS -->

        <template #empty>
          <div class="text-center">
            <h4 class="text-muted">{{ $t("No Items Found") }}</h4>
          </div>
        </template>

        <!-- HEADERS -->

        <template v-for="(header, idx) in tablePropsComputed.fields" v-slot:[`head(${header.key})`]="{ label }">
          <span :key="idx">
            <slot :name="`header.${header.key}`">
              {{ label }}
            </slot>
          </span>
        </template>

        <!-- CELLS -->

        <template v-for="(cell, idx) in tablePropsComputed.fields" v-slot:[`cell(${cell.key})`]="row">
          <slot :name="`cell.${cell.key}`" :item="row.item" :row="row">
            <div :key="idx" class="h-100">{{ row.item[cell.key] }}</div>
          </slot>
        </template>
      </b-table>
    </div>
    <div v-if="paginated" class="d-flex align-items-center">
      <div class="w-50">
        <b-pagination v-model="currentPage" class="mb-0" :total-rows="totalRows" :per-page="perPage" first-number
          last-number></b-pagination>
      </div>
      <div class="w-50 text-right">
        <b-form-select v-model="perPage" :options="perPageOptions"
          class="per-page-select form-control form-control-solid h-auto" size="sm"></b-form-select>
        <span class="pl-2">{{ $t("Showing") }} {{ start }} - {{ end }} {{ $t("of") }} {{
        totalRows
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // For backwards compatibility.
    fields: {
      type: Array,
      default: () => [],
    },
    // For backwards compatibility.
    itemsProvider: {
      type: [Function, Array],
      default: (callback) => callback([]),
    },
    // For backwards compatibility.
    total: {
      type: Number,
      default: null,
    },
    // For backwards compatibility.
    filter: {
      type: [String, Object, Array],
      default: "",
    },
    // For backwards compatibility.
    perPageOptions: {
      type: Array,
      default: () => [5, 10, 20, 30, 40],
    },
    // For backwards compatibility.
    trClass: {
      type: [String, Function],
      default: "",
    },
    paginated: {
      type: Boolean,
      default: () => true,
    },
    tableProps: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    theadHidden: {
      type: Boolean,
      default: () => false,
    }
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    tablePropsComputed() {
      // For backwards compatibility.
      const defaults = {
        busy: this.isLoading,
        items: this.itemsProvider,
        fields: this.fields,
        filter: this.filter,
        stacked: "lg",
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
      };
      if (!this.tableProps) return defaults;

      return {
        ...defaults,
        ...this.tableProps,
      };
    },

    optionsComputed: {
      get() {
        // For backwards compatibility.
        const defaults = {
          "sort-by": this.sortBy,
          "sort-desc": this.sortDesc,
          "per-page": this.perPage,
          "current-page": this.currentPage,
        };

        if (!this.options) return defaults;

        return {
          ...defaults,
          ...this.options,
        };
      },
      set(val) {
        this.$emit("update:options", val);
      },
    },

    sortBy: {
      get() {
        if (this.options && this.options["sort-by"]) {
          return this.options["sort-by"];
        }

        if (this.$attrs["sort-by"]) {
          return this.$attrs["sort-by"];
        }

        return "";
      },
      set(val) {
        // Emit for backwards compatibility
        this.$emit("update:sort-by", val);

        this.optionsComputed = { ...this.optionsComputed, "sort-by": val };
      },
    },

    sortDesc: {
      get() {
        if (this.options && this.options["sort-desc"]) {
          return this.options["sort-desc"];
        }

        if (this.$attrs["sort-desc"]) {
          return this.$attrs["sort-desc"];
        }

        return false;
      },
      set(val) {
        // Emit for backwards compatibility
        this.$emit("update:sort-desc", val);

        this.optionsComputed = { ...this.optionsComputed, "sort-desc": val };
      },
    },

    perPage: {
      get() {
        if (this.options && this.options["per-page"]) {
          return this.options["per-page"];
        }

        if (this.$attrs["per-page"]) {
          return this.$attrs["per-page"];
        }

        return 5;
      },
      set(val) {
        // Emit for backwards compatibility
        this.$emit("update:per-page", val);

        this.optionsComputed = { ...this.optionsComputed, "per-page": val };
      },
    },

    currentPage: {
      get() {
        if (this.options && this.options["current-page"]) {
          return this.options["current-page"];
        }

        if (this.$attrs["current-page"]) {
          return this.$attrs["current-page"];
        }

        return 1;
      },
      set(val) {
        // Emit for backwards compatibility
        this.$emit("update:current-page", val);

        this.optionsComputed = { ...this.optionsComputed, "current-page": val };
      },
    },

    totalRows() {
      if (this.total) return this.total;

      if (!this.total && Array.isArray(this.tablePropsComputed.items)) {
        return this.tablePropsComputed.items.length;
      }

      return 0;
    },

    start() {
      return Math.max(this.perPage * (this.currentPage - 1) + 1, 1);
    },

    end() {
      return Math.min(this.start + this.perPage - 1, this.totalRows);
    },
  },

  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>

<style lang="scss">
i.arrow {
  font-size: 0.7rem;
}

.table th,
.table td {
  padding: 1.25rem;
  vertical-align: middle;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.col-actions {
    max-width: 130px;
    min-width: 80px;
  }
}

.table thead th {
  border: none;
  text-align: left;
  white-space: nowrap;
}

.per-page-select {
  max-width: 5rem;
}

.pagination .page-item {
  margin-left: 0.4rem;
}

.pagination .page-item {

  .page-link {
    color: #7E8299;
    align-items: center;
    height: 2.25rem;
    min-width: 2.25rem;
    padding: 0.5rem;
    text-align: center;
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.42rem;
    border: 0;
  }


  &:first-child .page-link,
  &:last-child .page-link {
    background-color: #F3F6F9;
    color: #7E8299;
  }

  &.active .page-link {
    background-color: #6993FF;
    color: white;
  }
}

.hidden_header {
  display: none;
}
</style>
