import Vue from "vue";

import Wizard from "@/view/components/wizards/Wizard/Wizard.vue";

import WizardNav from "@/view/components/wizards/Wizard/WizardNav.vue";
import WizardNavStepIcon from "@/view/components/wizards/Wizard/WizardNavStepIcon.vue";
import WizardNavStep from "@/view/components/wizards/Wizard/WizardNavStep.vue";

import WizardBody from "@/view/components/wizards/Wizard/WizardBody.vue";
import WizardStep from "@/view/components/wizards/Wizard/WizardStep.vue";

import WizardActions from "@/view/components/wizards/Wizard/WizardActions.vue";
import WizardNextBtn from "@/view/components/wizards/Wizard/WizardNextBtn.vue";
import WizardPrevBtn from "@/view/components/wizards/Wizard/WizardPrevBtn.vue";
import WizardSubmitBtn from "@/view/components/wizards/Wizard/WizardSubmitBtn.vue";

Vue.component("wizard", Wizard);
Vue.component("wizard-nav", WizardNav);
Vue.component("wizard-nav-step-icon", WizardNavStepIcon);
Vue.component("wizard-nav-step", WizardNavStep);
Vue.component("wizard-body", WizardBody);
Vue.component("wizard-step", WizardStep);
Vue.component("wizard-actions", WizardActions);
Vue.component("wizard-next-btn", WizardNextBtn);
Vue.component("wizard-prev-btn", WizardPrevBtn);
Vue.component("wizard-submit-btn", WizardSubmitBtn);