var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-center my-1 px-8 my-lg-5 px-lg-10"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }