var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row mb-0"
  }, [_vm.label ? _c('div', {
    staticClass: "col-12"
  }, [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]) : _vm._e(), _vm._l(_vm.phones, function (tel, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex element"
    }, [_c('div', {
      staticClass: "col-xl-3"
    }, [_c('b-form-select', {
      staticClass: "form-control form-control-solid h-auto",
      attrs: {
        "disabled": tel.main,
        "options": _vm.options
      },
      model: {
        value: tel.kind,
        callback: function callback($$v) {
          _vm.$set(tel, "kind", $$v);
        },
        expression: "tel.kind"
      }
    })], 1), _c('div', {
      staticClass: "col-xl-7"
    }, [_c('validation-observer', {
      ref: "validation-phone-".concat(idx),
      refInFor: true,
      attrs: {
        "tag": "div"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": _vm.$t('Phone'),
        "vid": "phone[".concat(idx, "]"),
        "rules": {
          min: 7,
          telephone: [tel],
          personal_phone_unique: !_vm.isFormSubmitted && idx === _vm.phones.length ? _vm.computedPhoneList : false
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('telephone-input', {
            attrs: {
              "default-country": _vm.defaultCountry,
              "parent-class": false,
              "error-messages": errors
            },
            model: {
              value: tel.number,
              callback: function callback($$v) {
                _vm.$set(tel, "number", $$v);
              },
              expression: "tel.number"
            }
          })];
        }
      }], null, true)
    })], 1), !_vm.valid ? _c('div', {
      staticClass: "invalid-feedback d-block my-2 px-4",
      domProps: {
        "textContent": _vm._s(_vm.invalidFeedback)
      }
    }) : _vm._e(), _vm.hint ? _c('span', {
      staticClass: "form-text text-muted px-4"
    }, [_vm._v(_vm._s(_vm.hint))]) : _vm._e()], 1), _c('div', {
      staticClass: "col-xl-2"
    }, [_c('div', {
      staticClass: "d-flex justify-content-center align-items-center m-n0"
    }, [_vm.isMulti ? _c('div', {
      staticClass: "d-flex justify-content-start",
      attrs: {
        "cols": "auto"
      }
    }, [tel.main ? _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "fas fa-circle mr-3",
      style: {
        color: '#00A9BD'
      },
      attrs: {
        "title": _vm.$t('Main')
      }
    }) : _c('div', {
      style: {
        width: '3.5ch'
      }
    })]) : _vm._e(), _vm.isMulti ? _c('div', {
      staticClass: "d-flex justify-content-center align-items-center m-n0",
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.getTooltipMessage(tel),
        "icon": "fas fa-hand-point-up",
        "size": "xs",
        "disabled": !tel.number || tel.kind !== 'MOB'
      },
      on: {
        "click": function click($event) {
          return _vm.setMain(tel);
        }
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.$t('Add new telephone'),
        "icon": "flaticon2-plus",
        "size": "xs",
        "disabled": _vm.phones.length === 1 && !_vm.phones[0].number
      },
      on: {
        "click": _vm.addNewTelephone
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.$t('Remove telephone'),
        "icon": "flaticon2-cross",
        "size": "xs",
        "disabled": _vm.phones.length === 1 && !_vm.phones[0].number || tel.main
      },
      on: {
        "click": function click($event) {
          return _vm.removeTelephone(tel, idx);
        }
      }
    })], 1) : _vm._e()])])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }