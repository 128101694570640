import AuthService from "../auth.service";

const tokens = JSON.parse(localStorage.getItem("ep_user"));
const initialState = tokens ? { loggedIn: true, tokens, refreshing_tokens: false } : { loggedIn: false, tokens: null, refreshing_tokens: false };

const auth = {
  namespaced: true,
  state: initialState,

  actions: {
    async login({commit}, {email, password}) {
      try {
        const tokens = await AuthService.login(email, password);
        if (tokens.method) {
          // If MFA is enabled, return the ephemeral token and methods for MFA verification
          return tokens;
        } else {
          // If MFA is not enabled, commit the login mutation
          await commit("LOGIN", tokens);
          return tokens;
        }
      } catch (err) {
        console.error(err);
        await commit("LOGOUT");
        throw err;
      }
    },

    async verifyMFA({commit}, {code, ephemeralToken}) {
      try {
        const tokens = await AuthService.loginMfa(code, ephemeralToken);
        await commit("LOGIN", tokens);
        return tokens;
      } catch (err) {
        console.log(err);
        await commit("LOGOUT");
        throw Error(err);
      }
    },

    async logout({ commit }) {
      await commit("LOGOUT");
    },

    async updateRefreshToken({ commit }, refreshToken) {
      await commit("UPDATE_REFRESH_TOKEN", refreshToken);
    },

    async updateAccessToken({ commit }, accessToken) {
      await commit("UPDATE_ACCESS_TOKEN", accessToken);
    },

    async updateRefreshingToken({ commit }, status) {
      await commit("UPDATE_REFRESHING_TOKENS", status);
    }
  },

  mutations: {
    LOGIN(state, tokens) {
        state.loggedIn = true;
        state.tokens = tokens;

        localStorage.setItem("ep_user", JSON.stringify(tokens));
    },

    LOGOUT(state) {
      state.loggedIn = false;
      state.tokens = null;
      localStorage.clear();
    },

    UPDATE_ACCESS_TOKEN(state, accessToken) {
      state.tokens = { ...state.tokens, access: accessToken };
    },
    UPDATE_REFRESH_TOKEN(state, refreshToken) {
      state.tokens = { ...state.tokens, refresh: refreshToken };
    },
    UPDATE_REFRESHING_TOKENS(state, value) {
      state.refreshing_tokens = value;
    },
    reset: () => {}
  },

  getters: {
    isAuthenticated(state) {
      return state.loggedIn == true && state.tokens !== null;
    },
    isLoggedIn(state) {
      return state.loggedIn
    }
  },
};

export default auth;
