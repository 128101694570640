<template>
  <b-form-group :description="hint" :valid-feedback="validFeedback" :state="state" :invalid-feedback="invalidFeedback"
    :label-cols-lg="horizontal ? 3 : 12" :label-cols-xl="horizontal ? 3 : 12" :content-cols-lg="horizontal ? 9 : 12"
    :content-cols-xl="horizontal ? 6 : 12" :class="className">
    <template v-if="label" v-slot:label>
      {{ label }}
      <slot name="label"></slot>
      <span v-if="helpText" data-toggle="tooltip" :title="helpText" class="ml-2">
        <i class="fa fa-sm fa-info-circle text-primary"></i>
      </span>
    </template>
    <b-input-group>
      <multiselect :ref="multiSelectRef" :loading="isLoading" :value="multiselectValue"
        v-bind="multiSelectPropsComputed" :limit="asynchronous ? perPage : undefined"
        :limit-text="asynchronous ? limitText : undefined" :internal-search="!asynchronous" @input="onMultiSelectInput"
        @search-change="debouncedSearchChange" @open="selectIsOpen = true" @close="selectIsOpen = false">
        <template slot="singleLabel" slot-scope="props">
          <slot v-bind="props" name="singleLabel"></slot>
        </template>

        <template v-if="customOptions" #option=props>
          <slot name="options" :props="props"></slot>
        </template>

        <template v-if="asynchronous" slot="afterList">
          <div v-observe-visibility="loadMore" class="text-sm-center">
            <p v-if="count > asyncOptions.length && !query.length" class="text-muted">
              {{ $t("Showing {shown} of {count} results", { shown: asyncOptions.length, count: count }) }}
            </p>
            <p v-if="isLoading" class="text-muted">
              {{ $t("Loading more...") }}
            </p>
          </div>
        </template>

        <template v-if="!multiSelectPropsComputed.multiple && multiSelectValueText && allowEmpty" #beforeList>
          <div class="d-flex align-items-center bg-primary p-3 text-white cursor-pointer" @click="resetSelection">
            <i class="fas fa-times text-white"></i>
            <span class="ml-3">{{ multiSelectValueText }}</span>
          </div>
        </template>
      </multiselect>
      <b-input-group-append>
        <slot name="append"></slot>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
import Multiselect from "vue-multiselect";
import i18n from "@/core/plugins/vue-i18n";

export default {
  components: {
    Multiselect,
  },

  mixins: [BFormMixin],

  props: {
    multiselectProps: {
      type: Object,
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: null,
    },
    customOptions: {
      type: Boolean,
      default: false
    },
    // For backwards compatibility.
    placeholder: {
      type: String,
      default: () => i18n.t("Select a value")
    },
    // For backwards compatibility.
    options: {
      type: Array,
      default: () => [],
    },
    // For backwards compatibility.
    multiple: {
      type: Boolean,
      default: false,
    },
    // For backwards compatibility.
    disabled: {
      type: Boolean,
      default: false,
    },
    // For backwards compatibility.
    searchable: {
      type: Boolean,
      default: false,
    },
    // For backwards compatibility.
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    asynchronous: {
      type: Boolean,
      default: false,
    },
    fetchFunction: {
      type: Function,
      default: null,
    },
    limit: {
      type: Number,
      default: 20,
    }
  },

  data() {
    return {
      isLoading: false,
      count: 0,
      page: 1,
      perPage: 20,
      query: "",
      asyncOptions: [],
      selectIsOpen: false,
      selectedItems: [],
      debounceTimeout: null,
    }
  },

  computed: {
    multiSelectPropsComputed() {
      const defaults = {
        options: this.asynchronous ? this.asyncOptions : this.options,
        "track-by": this.trackBy,
        label: this.multiSelectLabel,
        disabled: this.disabled,
        placeholder: this.placeholder,
        multiple: this.multiple,
        "allow-empty": this.allowEmpty,
        searchable: this.searchable,
        "show-labels": false,
      };

      if (!this.multiselectProps) return defaults;

      return {
        ...defaults,
        ...this.multiselectProps,
      };
    },

    multiselectValue() {
      if (this.value && this.isOptionsArrayOfObjs(this.multiSelectPropsComputed.options) && !this.returnObject) {
        const { "track-by": trackBy, options, multiple: isMultiple } = this.multiSelectPropsComputed;
        if (isMultiple) {
          return this.value.reduce((acc, curr) => {
            const valueAsObject = options.find(option => option[trackBy] === curr);
            if (valueAsObject) acc.push(valueAsObject);
            return acc;
          }, []);
        } else {
          return options.find(option => option[trackBy] === this.value);
        }
      }

      return this.value;
    },

    multiSelectValueText() {
      const { "track-by": trackBy, label, multiple } = this.multiSelectPropsComputed;
      if (!this.value) {
        return ''
      }
      if (!this.multiselectValue || multiple) return "";

      if (trackBy) {
        return this.multiselectValue[label];
      }

      return this.multiselectValue;
    },

    trackBy() {
      if (this.multiselectProps?.["track-by"]) return this.multiselectProps["track-by"];
      if (this.multiselectProps?.trackBy) return this.multiselectProps.trackBy;
      return this.isOptionsArrayOfObjs ? "value" : undefined;
    },

    multiSelectLabel() {
      if (this.multiselectProps?.["label"]) return this.multiselectProps["label"];

      return this.isOptionsArrayOfObjs ? "text" : undefined;
    },

    multiSelectRef() {
      return `multiselect-${this._uid}`;
    },
  },
  watch: {
    asyncOptions: {
      handler(options) {
        this.isOptionsArrayOfObjsComputed = this.isOptionsArrayOfObjs(options);
      },
      immediate: true,
    },
    selectIsOpen: {
      handler(isOpen) {
        // emit event to parent component
        this.$emit("open", isOpen);
      }
    }
  },
  async created() {
    if (this.asynchronous) {
      try {
        this.perPage = this.limit;
        const initialData = await this.fetchFunction("", this.perPage, this.page);
        this.asyncOptions = initialData.results;
        this.count = initialData.count;
        this.$emit('initial-options-loaded');
      } catch (e) {
        console.error(e);
        this.asyncOptions = [];
      }
    }
  },
  methods: {
    isOptionsArrayOfObjs(options) {
      return options.length && typeof options[0] === "object";
    },
    onMultiSelectInput(value) {
      if (value == null) {
        this.$emit("input", value);
        return
      }
      if (this.isOptionsArrayOfObjs(this.multiSelectPropsComputed.options) && !this.returnObject) {
        const values = Array.isArray(value) ? value : [value]; // Handle both single and multiple selects
        const trackBy = this.multiSelectPropsComputed["track-by"];

        // Create an array of tracked property values
        const trackedValues = values.map(item => item && item[trackBy]);
        // Update selected items
        this.selectedItems = Array.isArray(value) ? value : [value];
        // Emit all for multiple or just the first one for single select
        const toEmit = this.multiSelectPropsComputed.multiple ? trackedValues : trackedValues[0];
        this.$emit("input", toEmit);
      } else {
        this.$emit("input", value);
      }
    },

    open() {
      this.$refs[this.multiSelectRef].activate();
    },

    close() {
      this.$refs[this.multiSelectRef].deactivate();
    },

    limitText(count) {
      if (!this.asynchronous) return;
      return `and ${count} more..`
    },
    async debouncedSearchChange(query) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.searchChange(query);
      }, 500);
    },
    async searchChange(query) {
      if (!this.asynchronous || !this.fetchFunction) return;

      this.query = query;
      this.page = 1; // reset page number to 1 for a new search

      this.isLoading = true;
      const { count, results } = await this.fetchFunction(query, this.perPage, this.page);

      // Filter results to exclude any that are already in selectedItems
      const newResults = results.filter(result => {
        const resultTrackBy = result[this.trackBy];
        return !this.selectedItems.some(item => item && item[this.trackBy] === resultTrackBy);
      });

      // Add new results to asyncOptions
      this.asyncOptions = [...this.selectedItems, ...newResults];
      this.count = count;
      this.isLoading = false;
    },
    async loadMore() {
      if (this.asynchronous && this.selectIsOpen && this.asyncOptions.length < this.count && !this.isLoading && this.fetchFunction) {
        this.isLoading = true;
        const result = await this.fetchFunction(this.query, this.perPage, ++this.page);
        if (result && Array.isArray(result.results)) {
          this.asyncOptions = [...this.asyncOptions, ...result.results];
        }
        this.isLoading = false;
      }
    },
    resetSelection() {
      this.selectedItems = [];
      this.$emit('input', null);
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.multiselect__single {
  font-size: 1rem;
  padding-top: 3px;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #3F4254;
  font-weight: 400;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.multiselect__tag .multiselect__tag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiselect__tag-icon::after {
  color: #fff;
}

.multiselect {
  flex: 1;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--highlight {
  background: var(--primary);
}

.multiselect__tags {
  padding: 5px 40px 0 8px;
  font-size: 1rem;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #3F4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.multiselect__placeholder {
  margin-bottom: 7px;
  padding-top: 4px;
}

.multiselect__input,
.multiselect__content-wrapper {
  font-size: 13px !important;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #3F4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.multiselect__input {
  margin-bottom: 0;
  margin-top: 3px;
}

.multiselect__tag {
  padding: 7px 26px 8px 10px;
  background: #6993ff;
  margin-bottom: 0;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #5a81e6;
}

::v-deep .multiselect__content-wrapper {
  overscroll-behavior: contain;
  height: 300px; // Set a maximum height
  overflow-y: auto; // Enable vertical scrolling
}

// For preventing browser default pull-to-refresh behavior & scroll chaining
body {
  overscroll-behavior-y: contain;
}
</style>
