<template>
  <div>
    <!-- Display current attachment info -->
    <div v-if="attachment" class="d-flex justify-content-between align-items-center mt-2 mb-5">
      <div class="mr-auto">{{ $t("Current Attachment:") }}</div>
      <a :href=attachment class="btn btn-light-primary btn-sm d-block font-weight-bolder" aria-haspopup="true"
        target="_blank">
        <span class="svg-icon svg-icon-md">
          <inline-svg src="/media/svg/icons/Files/File.svg" />
        </span>
        {{ $t("View Attachment") }}
      </a>
    </div>
    <div v-for="el in multipleAttachment" :id="'tus-multiple-' + el.id" :key="el.id"
      class="d-flex justify-content-between align-items-center mt-2 mb-5">
      <div class="mr-auto attachment-name">{{ el.attachment_name }}</div>
      <a :href=el.attachment class="btn btn-light-primary btn-sm d-block font-weight-bolder" aria-haspopup="true"
        target="_blank">
        <span class="svg-icon svg-icon-md">
          <inline-svg src="/media/svg/icons/Files/File.svg" />
        </span>
        {{ $t("View Attachment") }}
      </a>
      <b-button :tooltip="$t('Remove Attachment')" class="btn btn-icon btn-hover-primary ml-2" @click="remove(el.id)">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/media/svg/icons/General/Trash.svg" />
        </span>
      </b-button>
    </div>
    <b-form-group v-show="!multiple" :description="hint" :label="label" :valid-feedback="validFeedback" :state="state"
      :invalid-feedback="invalidFeedback" :label-cols="horizontal ? 3 : null" class="mb-1">
      <b-form-file :id="`uppy-file-input-${_uid}`" :state="state" :disabled="disabled" :placeholder="placeholder"
        :accept="accept" class="custom-file-upload" @change="onFileChange"></b-form-file>
      <div :id="statusBarId"></div>
    </b-form-group>
    <div v-show="multiple" :id="statusBarIdMultiple"></div>
  </div>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import Dashboard from '@uppy/dashboard';
import ProgressBar from "@uppy/progress-bar";
import StatusBar from "@uppy/status-bar";
import "@uppy/progress-bar/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import '@uppy/dashboard/dist/style.min.css';
import TokenService from "@/core/services/token.service";
import { mapActions } from "vuex";

export default {
  mixins: [BFormMixin],

  props: {
    placeholder: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    attachment: {
      type: String,
      default: null
    },
    multipleAttachment: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    url: `${process.env.VUE_APP_BACKEND_BASE_URL}tus/files/`,
    /**
     * @type Uppy
     */
    statusBarId: 'status-bar' + (Math.random() + 1).toString(36).substring(7),
    statusBarIdMultiple: 'status-bar-multiple' + (Math.random() + 1).toString(36).substring(7),
    uppy: null,
    accessToken: null,
    idRemoved: []
  }),

  async mounted() {
    this.accessToken = await TokenService.getLocalAccessToken();
    this.initTus();
  },

  methods: {
    ...mapActions("auth", ["updateAccessToken", "updateRefreshToken", "updateRefreshingToken"]),
    initTus() {
      const __this = this
      this.uppy = new Uppy({
        debug: false,
        autoProceed: true,
        restrictions: {
          allowedFileTypes: (this.accept || '').trim().split(','),
          maxFileSize: 5000000
        }
      }).use(Tus, {
        endpoint: this.url,
        chunkSize: 2621440,
        async onBeforeRequest(req) {
          req.setHeader("Authorization", `Bearer ${__this.accessToken}`);
          if (req._xhr) {
            req._xhr.withCredentials = true;
          }
        },
        async onShouldRetry(err, retryAttempt, options, next) {
          if ((err?.originalResponse?.getStatus() === 403 || err?.originalResponse?.getStatus() === 401) && err?.originalResponse?.getBody().includes("token_not_valid")) {
            await __this.updateRefreshingToken(true)
            await TokenService.refreshToken().then(async res => {
              await __this.setTokens(res.data);
              __this.accessToken = res.data.access;

              return true;
            });
          }
          return next(err);
        }
      }).use(ProgressBar, {
        target: document.body,
      }).use(StatusBar, {
        target: "#" + this.statusBarId,
        hideUploadButton: true,
        hideAfterFinish: false,
        showProgressDetails: true,
      }).use(Dashboard, {
        inline: true,
        height: 340,
        target: "#" + this.statusBarIdMultiple,
        doneButtonHandler: null,
        showRemoveButtonAfterComplete: true,
        note: this.hint
      })
        .on("upload-success", this.onUppyUploadSuccess)
        .on('file-removed', this.onRemoveUploadSuccess);
    },

    async setTokens(tokens) {
      TokenService.updateLocalAccessToken(tokens.access, tokens.refresh);
      await this.updateAccessToken(tokens.access);
      await this.updateRefreshToken(tokens.refresh);
      await this.updateRefreshingToken(false)
    },

    onFileChange(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.upload(e.target.files[i]);
      }
    },

    onRemoveUploadSuccess(file) {
      // Extract the TusId, which is supposed to be the last part of the URL.
      const tusId = this.checkTusUuid(file.uploadURL);
      this.$emit("remove", tusId);
    },

    onUppyUploadSuccess(file, response) {
      if (!file) {
        this.$emit("change", null);
        return;
      }
      // Validate presence of uploadURL property in the response
      if (!response.uploadURL) {
        throw new Error("Missing uploadURL in response");
      }

      // Extract the TusId, which is supposed to be the last part of the URL.
      const tusId = this.checkTusUuid(response.uploadURL);
      this.$emit("change", tusId);
    },

    upload(file) {
      if (!file) {
        this.$emit("change", null);
      }

      this.uppy.addFile({
        source: "file-input",
        name: file.name,
        type: file.type,
        data: file,
      });
    },

    checkTusUuid(path) {
      // Extract the TusId, which is supposed to be the last part of the URL.
      const parts = path.split("/");
      const tusId = parts[parts.length - 2];

      // Validate if TusId is in the correct UUID format
      const uuidRegExp = new RegExp('^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$');
      if (!uuidRegExp.test(tusId)) {
        throw new Error("Invalid TusId format.");
      }
      return tusId
    },

    remove(id) {
      document.getElementById('tus-multiple-' + id).classList.add('d-none');
      document.getElementById('tus-multiple-' + id).classList.remove('d-flex');
      this.$emit('remove-attachment', id);
    },
  }
};
</script>

<style scoped>
.attachment-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 230px;
}
</style>