import axiosInstance from "./api.service";
import TokenService from "./token.service";
import jwtDecode from "jwt-decode";
import i18n from "@/core/plugins/vue-i18n";

const publicRoutes = [
    "/token",
    "/token/logout/",
    "/token/refresh/",
    "/token/verify/",
    "password_reset/confirm/",
    "password_reset/",
    "auth/login/",
    "auth/login/code/",
];

const setup = store => {

    axiosInstance.interceptors.request.use(
        async config => {
            config.headers["Accept-Language"] = i18n.locale || "en";
            // Check if it is a public route, skip token attaching
            if (!publicRoutes.includes(config.url)) {
                let token = TokenService.getLocalAccessToken();

                // Decode the token to check its expiry time
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const bufferTime = 30
                    const currentTime = Date.now() / 1000; // in seconds
                    // Check if the token has expired
                    if (decodedToken.exp < currentTime + bufferTime) {
                        // Check if the token is already being refreshed
                        if (!store.state.auth.refreshing_tokens) {
                            try {
                              await store.commit('auth/UPDATE_REFRESHING_TOKENS', true); // Update the Vuex state
                                // Refresh the token
                                const res = await axiosInstance.post("/token/refresh/", {
                                    refresh: TokenService.getLocalRefreshToken(),
                                });
                                token = res?.data?.access;
                                let refresh = res?.data?.refresh;
                                if (!token) {
                                    throw {customCode: 401, message: i18n.t("COMMON.SESSION_EXPIRED")};
                                }
                                // Update the token in local storage and redux store
                                TokenService.updateLocalAccessToken(token, refresh);
                                await store.dispatch("auth/updateAccessToken", token);
                                await store.dispatch("auth/updateRefreshToken", refresh);
                                await store.commit('auth/UPDATE_REFRESHING_TOKENS', false); // Reset the Vuex state after successful token refresh
                            } catch (_error) {
                                return Promise.reject(_error);
                            }
                        } else {
                            // Wait until token is refreshed
                            await new Promise((resolve) => {
                                let unwatch = store.watch((state) => state.auth.refreshing_tokens, (newValue) => {
                                    if (!newValue) {
                                        resolve();
                                        unwatch();
                                    }
                                });
                            });
                            token = store.state.auth.tokens.access;
                        }
                    }
                    // Attach the token to the request
                    config.headers["Authorization"] = `Bearer ${token}`;
                }
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    axiosInstance.interceptors.response.use(
        res => {
            return res;
        },
        async error => {
            // If /token/refresh fails it probably means that the Refresh Token is
            // expired, so we logout the user.
            if (error.response?.data?.code === "token_not_valid") {
                await store.commit("auth/LOGOUT");
                // replace the error with a custom one
                error.response = {
                    data: {
                        message: i18n.t("Your login session has expired. Please login again.")
                    },
                    status: 401
                };
            }
            // If the status code is 429 (rate limit), wait for the time specified in the Retry-After header
            if (error.response?.status === 429) {
              const retryAfter = error.response.headers['retry-after'];
              if (retryAfter) {
                await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));
                return axiosInstance.request(error.config);
              }
            }

            return Promise.reject(error);
        },
    );
};

export default setup;