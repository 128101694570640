var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group row mb-0"
  }, [_vm.label ? _c('div', {
    staticClass: "col-12"
  }, [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]) : _vm._e(), _vm._l(_vm.socials, function (social, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex element"
    }, [_c('div', {
      staticClass: "col-xl-3"
    }, [_c('b-form-select', {
      staticClass: "form-control form-control-solid selectpicker",
      attrs: {
        "options": _vm.options
      },
      model: {
        value: social.kind,
        callback: function callback($$v) {
          _vm.$set(social, "kind", $$v);
        },
        expression: "social.kind"
      }
    })], 1), _c('validation-observer', {
      staticClass: "col-xl-7",
      attrs: {
        "tag": "div"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": _vm.$t('Social account'),
        "vid": "social",
        "rules": {
          url: true
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('euro-input', {
            attrs: {
              "error-messages": errors,
              "parent-class": false,
              "placeholder": _vm.$t('Social account')
            },
            model: {
              value: social.contact,
              callback: function callback($$v) {
                _vm.$set(social, "contact", $$v);
              },
              expression: "social.contact"
            }
          })];
        }
      }], null, true)
    })], 1), _c('div', {
      staticClass: "col-xl-2"
    }, [_c('div', {
      staticClass: "d-flex justify-content-center align-items-center m-n1"
    }, [_c('div', {
      style: {
        width: '3.5ch'
      }
    }), _c('div', {
      style: {
        width: '42.5px'
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.$t('Add new social contact'),
        "icon": "flaticon2-plus",
        "size": "xs",
        "disabled": _vm.socials.length === 1 && !_vm.socials[0].contact
      },
      on: {
        "click": _vm.addNewSocial
      }
    }), _c('btn-icon', {
      staticClass: "m-1 py-1 px-2",
      attrs: {
        "tooltip": _vm.$t('Remove social contact'),
        "icon": "flaticon2-cross",
        "size": "xs",
        "disabled": _vm.socials.length === 1 && !_vm.socials[0].contact
      },
      on: {
        "click": function click($event) {
          return _vm.removeSocial(idx);
        }
      }
    })], 1)])], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }