<template>
  <div class="form-group row mb-0">
    <div v-if="label" class="col-12">
      <label class="col-form-label">
        {{ label }}
      </label>
    </div>
    <div v-for="(tel, idx) in phones" :key="idx" class="d-flex element">
      <div class="col-xl-3">
        <b-form-select v-model="tel.kind" :disabled="tel.main" :options="options"
          class="form-control form-control-solid h-auto"></b-form-select>
      </div>
      <div class="col-xl-7">
        <validation-observer :ref="`validation-phone-${idx}`" tag="div">
          <validation-provider v-slot="{ errors }" :name="$t('Phone')" :vid="`phone[${idx}]`" :rules="{
            min: 7,
            telephone: [tel],
            personal_phone_unique: !isFormSubmitted && idx === phones.length ? computedPhoneList : false,
          }">
            <telephone-input v-model="tel.number" :default-country="defaultCountry" :parent-class="false"
              :error-messages="errors"></telephone-input>
          </validation-provider>
        </validation-observer>
        <div v-if="!valid" class="invalid-feedback d-block my-2 px-4" v-text="invalidFeedback"></div>
        <span v-if="hint" class="form-text text-muted px-4">{{ hint }}</span>
      </div>
      <div class="col-xl-2">
        <div class="d-flex justify-content-center align-items-center m-n0">
          <div v-if="isMulti" cols="auto" class="d-flex justify-content-start">
            <i v-if="tel.main" v-b-tooltip.hover class="fas fa-circle mr-3" :title="$t('Main')"
              :style="{ color: '#00A9BD' }"></i>
            <div v-else :style="{ width: '3.5ch' }"></div>
          </div>
          <div v-if="isMulti" cols="12" md="auto" class="d-flex justify-content-center align-items-center m-n0">
            <btn-icon class="m-1 py-1 px-2" :tooltip="getTooltipMessage(tel)" icon="fas fa-hand-point-up" size="xs"
              :disabled="!tel.number || tel.kind !== 'MOB'" @click="setMain(tel)"></btn-icon>
            <btn-icon :tooltip="$t('Add new telephone')" class="m-1 py-1 px-2" icon="flaticon2-plus" size="xs"
              :disabled="phones.length === 1 && !phones[0].number" @click="addNewTelephone"></btn-icon>
            <btn-icon :tooltip="$t('Remove telephone')" class="m-1 py-1 px-2" icon="flaticon2-cross" size="xs"
              :disabled="(phones.length === 1 && !phones[0].number) || tel.main"
              @click="removeTelephone(tel, idx)"></btn-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TelephoneInput from "./TelephoneInput.vue";
import Swal from "sweetalert2";
import { deleteSwal } from "@/core/helpers/swal";
import { BFormMixin } from "@/core/mixins";
export default {
  components: { TelephoneInput },
  mixins: [BFormMixin],
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      default: () => [],
    },
    isMulti: {
      type: Boolean,
      default: true,
    },
    defaultCountry: {
      type: String,
      default: "it",
    },
    showMain: {
      type: Boolean,
      deefault: true,
    },
    label: {
      type: String,
      default: "",
    }
  },

  data() {
    return {
      mainPhoneIdx: -1,
      isFormSubmitted: false,
    };
  },

  computed: {
    computedPhoneList() {
      const list = this.phones.map(el => el.number);
      return list.length - 1;
    },
    phones: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:value", v)
      }
    },
  },

  mounted() {
    if (this.value.length) {
      this.phones = this.value;
    } else {
      this.addNewTelephone()
    }
  },

  updated() {
    if (this.phones.length == 0) {
      this.addNewTelephone()
    }
  },

  methods: {
    getTooltipMessage(tel) {
      const { kind, number } = tel;
      if (!number) {
        return this.$t("Enter a valid number");
      }
      if (kind !== "MOB") {
        return this.$t("Only mobile numbers can be set as main");
      } else {
        return this.$t("Set this number as main");
      }
    },

    async setMain(tel) {
      const exMain = this.phones.find(el => el.main && el.number !== tel.number);
      if (exMain) {
        exMain.main = false;
      }
      tel.main = true;
      this.$emit("updated");
    },

    addNewTelephone() {
      this.phones.push({ kind: "FIS", number: "", main: false });
      this.mainPhoneIdx = this.phones.findIndex(el => el.main);
    },

    removeTelephone(tel, idx) {
      if (tel.main) {
        Swal.fire({
          title: this.$t("Warning"),
          text: this.$t("This is the main phone number, do you want to delete it?"),
          icon: "warning",
          confirmButtonClass: "btn btn-danger",
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "No",
          cancelButtonClass: "btn",
        }).then(res => {
          if (res.isConfirmed) {
            this.isFormSubmitted = true;
            if (this.phones.length == 1) {
              this.phones.splice(0, 1, { kind: "FIS", number: "", main: false });
            } else {
              this.phones.splice(idx, 1);
            }
            this.$emit("updated");
            this.isFormSubmitted = false;
          }
        });
      } else {
        deleteSwal().then(res => {
          if (res.isConfirmed) {
            if (this.phones.length == 1) {
              this.phones.splice(0, 1, { kind: "FIS", number: "", main: false });
            } else {
              this.phones.splice(idx, 1);
            }
            this.$emit("updated");
          }
        });
      }
    },

    getPhoneLabelClass(idx) {
      return this.mainPhoneIdx !== -1 && idx !== this.mainPhoneIdx ? "font-weight-light" : "";
    },
    resetValidation() {
      this.isFormSubmitted = true;
    },
  },
};
</script>

<style scoped>
.element {
  padding-bottom: 1.25rem;
  width: 100%;
}

.element:last-child {
  padding: 0;
}
</style>
