<template>
  <router-view></router-view>
</template>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";

export default {
  name: "MetronicVue",

  beforeMount() {
    if (document.getElementById("google-maps")) return; // was already loaded
    var scriptTag = document.createElement("script");
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&libraries=places&callback=Function.prototype`;
    scriptTag.id = "google-maps";
    document.getElementsByTagName("head")[0].appendChild(scriptTag);
  },

  async mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
};
</script>

<style lang="scss">
:root {
  --primary-lighten: #4b90f8;
  --primary: #0083b6 !important;
  --warning: #f1c40f !important;
  --info: #5f4ea3 !important;
}

body {
  letter-spacing: 0.6px;
  font-weight: 500;
  min-height: 100vh !important;
}

$body-bg: #ecf0f1;
$body-color: #2c3e50;

$pagination-active-bg: var(--primary);
$pagination-hover-color: var(--primary);

// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "~select2/src/scss/core.scss";

// Main demo style scss
@import "assets/sass/style.vue";

::v-deep body.swal2-height-auto {
  height: 100vh !important;
}

.navi .navi-item .navi-link:disabled {
  opacity: 0.5;
}

.swal2-styled.swal2-deny.btn.btn-warning {
  background-color: var(--warning);
}

body {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 350px;
}

.svg-icon svg g [fill] {
  fill: white;
}

.col-form-label.col-auto {
  display: inline-block;
  width: 13ch !important;
}

.custom-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999999;
  background-color: var(--primary);
}

* {
  padding: 0;
  margin: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
}

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>
