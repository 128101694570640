<template>
  <div class="wizard-step" data-wizard-type="step">
    <div class="wizard-wrapper">
      <div v-if="icon" class="wizard-icon">
        <span class="svg-icon svg-icon-2x">
          <inline-svg :src="`/media/svg/icons/${icon}`" />
        </span>
      </div>
      <div class="wizard-label">
        <h3 class="wizard-title">{{ title }}</h3>
        <div v-if="desc" class="wizard-desc">{{ desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>
