<template>
  <button class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" data-wizard-type="action-next"
    @click.prevent="$emit('click')">
    {{ getLabel }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getLabel() {
      return !this.label ? this.$t("Next Step") : this.label;
    },
  },
};
</script>
