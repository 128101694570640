var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "menu-item menu-item-submenu",
    class: {
      'menu-item-active': _vm.hasActiveChildren(_vm.menuItemActive)
    },
    attrs: {
      "aria-haspopup": "true",
      "data-menu-toggle": _vm.menuToggle
    }
  }, [_c('a', {
    staticClass: "menu-link menu-toggle",
    attrs: {
      "href": "#"
    }
  }, [_vm.icon && !_vm.hasDot ? _c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/".concat(_vm.icon)
    }
  })], 1) : _vm._e(), _vm.hasDot ? _c('i', {
    staticClass: "menu-bullet menu-bullet-dot"
  }, [_c('span')]) : _vm._e(), _c('span', {
    staticClass: "menu-text"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('i', {
    staticClass: "menu-arrow"
  })]), _c('div', {
    staticClass: "menu-submenu menu-submenu-classic menu-submenu-right"
  }, [_c('ul', {
    staticClass: "menu-subnav"
  }, [_vm._t("default")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }