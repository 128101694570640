<template>
  <div class="form-group row mb-0">
    <div v-if="label" class="col-12">
      <label class="col-form-label">
        {{ label }}
      </label>
    </div>
    <div v-for="(email, idx) in emails" :key="idx" class="d-flex align-items-center element">
      <div class="col-xl-3">
        <b-form-select v-model="email.kind" :options="options"
          class="form-control form-control-solid h-auto"></b-form-select>
      </div>
      <div class="col-xl-7">
        <validation-observer :ref="`validation-email-${idx}`" tag="div">
          <validation-provider v-slot="{ errors }" :name="$t('Email')" :vid="`email[${idx}]`" :rules="{
            min: 3,
            email: true,
            personal_email_unique: !isFormSubmitted && idx === emails.length - 1 ? computedMailList : false,
            custom_mail: [email],
          }">
            <euro-input v-model="email.email" :placeholder="$t('Email')" type="email" :parent-class="false"
              :error-messages="errors"></euro-input>
          </validation-provider>
        </validation-observer>
        <div v-if="!valid" class="invalid-feedback d-block my-2 px-4" v-text="invalidFeedback"></div>
        <span v-if="hint" class="form-text text-muted px-4">{{ hint }}</span>
      </div>
      <div class="col-xl-2">
        <div class="d-flex justify-content-center align-items-center m-n1">
          <div class="d-flex justify-content-start">
            <i v-if="email.main" v-b-tooltip.hover class="fas fa-circle ml-3" :title="$t('Main')"
              :style="{ color: '#00A9BD' }"></i>
            <div v-else class="ml-7"></div>
          </div>
          <btn-icon class="m-1 py-1 px-2" :tooltip="getTooltipMessage(email)" icon="fas fa-hand-point-up" size="xs"
            :disabled="!email.email" @click="setMain(email)"></btn-icon>
          <btn-icon icon="flaticon2-plus" size="xs" :tooltip="$t('Add new email')" class="m-1 py-1 px-2"
            :disabled="emails.length === 1 && !emails[0].email" @click="addNewEmail"></btn-icon>
          <btn-icon icon="flaticon2-cross" size="xs" :tooltip="$t('Remove email')" class="m-1 py-1 px-2"
            :disabled="emails.length === 1 && !emails[0].email" @click="removeEmail(idx)"></btn-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { backendErrorSwal, deleteSwal } from "@/core/helpers/swal";
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    }
  },

  data() {
    return {
      isFormSubmitted: false,
    };
  },

  computed: {
    computedMailList() {
      const list = this.emails.map((el) => el.email);
      return list.length - 1;
    },
    emails: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:value", v)
      }
    },
  },

  mounted() {
    if (this.value.length) {
      this.emails = this.value;
    } else {
      this.addNewEmail()
    }
  },

  updated() {
    if (this.emails.length == 0) {
      this.addNewEmail()
    }
  },

  methods: {
    getTooltipMessage(mail) {
      const { email } = mail;
      if (!email) {
        return this.$t("Enter a valid mail");
      } else {
        return this.$t("Set this mail as main");
      }
    },

    async setMain(mail) {
      try {
        const exMain = this.emails.find((el) => el.main && el.email !== mail.email);
        if (exMain) {
          exMain.main = false;
        }
        mail.main = true;
        this.$emit("updated");
      } catch (err) {
        console.log(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    addNewEmail() {
      this.emails.push({ kind: "UFF", email: "", main: false });
    },

    removeEmail(idx) {
      deleteSwal().then((res) => {
        if (res.isConfirmed) {
          if (this.emails.length === 1) {
            this.emails.splice(0, 1, { kind: "UFF", email: "", main: true });
          } else {
            this.emails.splice(idx, 1);
            this.emails[0].main = true;
          }
          this.$emit("updated");
        }
      });
    },

    resetValidation() {
      this.isFormSubmitted = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-2 {
  gap: 2rem;
}

.element {
  padding-bottom: 1.25rem;
  width: 100%;
}

.element:last-child {
  padding: 0;
}
</style>
