<template>
  <div class="wizard-step" data-wizard-type="step">
    <div class="wizard-wrapper">
      <div class="wizard-icon">
        <i class="wizard-check ki ki-check"></i>
        <span class="wizard-number">{{ number }}</span>
      </div>
      <div class="wizard-label mr-3">
        <h3 class="wizard-title">{{ title }}</h3>
        <div v-if="desc != ''" class="wizard-desc">{{ desc }}</div>
        <slot></slot>
      </div>
      <span class="svg-icon svg-icon-dark-25">
        <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    number: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-step {
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;

    & span.svg-icon {
      display: none;
    }
  }
}
</style>