import AuthService from "@/core/services/auth.service";

const auth = ({
  next,
  to
}) => {
  const out = {
    next,
    keepGoing: true,
  };
  const storedUser = localStorage.getItem("ep_user");
  const isLoggedIn = !!(storedUser && JSON.parse(storedUser).user);
  let userParsed = null
  if (isLoggedIn) {
    userParsed = JSON.parse(storedUser).user
  }

  if (!isLoggedIn) {
    out.next = () =>
      next({
        name: "login",
        query: {
          redirect: to.fullPath,
        },
      });
    out.keepGoing = false;
  } else if (
    userParsed.is_ap_staff == false &&
    !userParsed.managedcompany_set.find(managedCompany => managedCompany.company == userParsed.selected_company).accepted_condition &&
    to.name != "acceptance-sensitive-data"
  ) {
    out.next = () => {
      next({
        name: "acceptance-sensitive-data",
        query: {
          redirect: to.fullPath,
        },
      });
      // remove page-loading class from body after change focus
      document.body.classList.remove('page-loading');
    }
    out.keepGoing = false;
  }

  return out;
};

const isOnlyMaps = ({
  next,
  to
}) => {
  const out = {
    next,
    keepGoing: true,
  };

  const userAccess = JSON.parse(localStorage.getItem("ep_user"));

  if (!userAccess.hasOwnProperty("user")) {
    AuthService.logout();
    out.next = () =>
      next({
        name: "login",
        query: {
          redirect: to.fullPath,
        },
      });
    out.keepGoing = false;
  } else if (userAccess.user.only_maps && to.name != "risk-map") {
    out.next = () =>
      next({
        name: "risk-map",
      });
    out.keepGoing = false;
  }

  return out;
};

const checkFocusCompany = ({
  next,
  to
}) => {
  const out = {
    next,
    keepGoing: true,
  };

  const localUser = JSON.parse(localStorage.getItem("ep_user"))

  if (to.query.hasOwnProperty('company_focus') && localUser.user.selected_company != to.query.company_focus) {
    if (localUser.user.managedcompany_set.find(manage => manage.company == to.query.company_focus)) {
      out.next = () => next({
        name: "focus-change",
        query: {
          redirect: to.fullPath,
          focus: to.query.company_focus
        }
      });
      out.keepGoing = false;
    }
  }

  return out;
};

export {
  auth,
  isOnlyMaps,
  checkFocusCompany
};