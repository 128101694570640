<template>
  <div>
    <b-form-group :description="hint" :label="label" :valid-feedback="validFeedback" :state="state"
      :invalid-feedback="invalidFeedback" :label-cols-lg="horizontal ? 3 : 12" :label-cols-xl="horizontal ? 3 : 12"
      :content-cols-lg="horizontal ? 9 : 12" :content-cols-xl="horizontal ? 6 : 12">
      <div class="image-input image-input-outline">
        <div class="image-input-wrapper" :style="{ backgroundImage: `url(${avatar})` }"></div>
        <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow avatar-action"
          data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
          <i class="fa fa-pen icon-sm"></i>
          <input ref="inputRef" type="file" name="profile_avatar" accept="image/png, image/jpeg, image/jpg"
            @change="onFileChange($event)" />
          <input type="hidden" name="profile_avatar_remove" :style="{ position: 'absolute' }" />
        </label>
        <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow avatar-action"
          data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
          <i class="ki ki-bold-close icon-xs"></i>
        </span>
      </div>
      <b-modal ref="cropperModal" title="Image Cropper" @hidden="onModalHidden">
        <vue-croppie ref="croppieRef" :enable-orientation="false" :enable-resize="false" :enable-exif="true" :boundary="{
          width: 450,
          height: 450,
        }" :viewport="{
  width: 240,
  height: 240,
  type: 'square',
}"></vue-croppie>
        <template #modal-footer>
          <div class="w-100">
            <b-button block variant="primary" class="float-right" @click="crop"> Crop </b-button>
          </div>
        </template>
      </b-modal>
    </b-form-group>
    <euro-tus-file-input ref="tusInputRef" v-visible="false" accept="image/png, image/jpeg, image/jpg" :style="{ height: 0 }"
      @change="onEuroTusFileInputChange"></euro-tus-file-input>
  </div>
</template>

<script>
import BFormMixin from "@/core/mixins/BFormMixin";
export default {
  mixins: [BFormMixin],

  props: {
    currentAvatar: {
      type: String,
      default: null,
    },
    isLegal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      newAvatar: null,
    };
  },

  computed: {
    placeholder() {
      return this.isLegal ? "/media/logos/logo-placeholder.png" : "/media/users/blank.png";
    },

    avatar() {
      if (this.newAvatar) {
        return this.newAvatar;
      }

      if (this.currentAvatar) {
        return this.currentAvatar;
      }

      return this.placeholder;
    },
  },

  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      const modal = this.$refs.cropperModal;
      modal.show();

      const reader = new FileReader();

      reader.onload = (event) => {
        const croppie = this.$refs.croppieRef;
        croppie.bind({
          url: event.target.result,
        });
      };

      reader.readAsDataURL(file);
    },

    crop() {
      const croppie = this.$refs.croppieRef;
      const modal = this.$refs.cropperModal;
      // get file name
      const file = this.$refs.inputRef.files[0];
      const fileName = file.name;

      croppie.result({}, (output) => {
        this.newAvatar = output;
        this.uploadToTus(output, fileName);
        modal.hide();
      });
    },

    onModalHidden() {
      this.$refs.inputRef.value = null;
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    imageURLtoBase64(imgUrl, callback) {
      var xhr = new XMLHttpRequest();

      xhr.onload = function () {
        const blob = xhr.response;
        const reader = new FileReader();
        reader.onload = (event) => {
          callback(event.target.result);
        };
        reader.readAsDataURL(blob);
      };

      xhr.open("GET", imgUrl);
      xhr.responseType = "blob";
      xhr.send();
    },

    removeCurrentAvatar() {
      this.newAvatar = null;
      this.$emit("change", null);
    },

    onEuroTusFileInputChange(tusId) {
      this.$emit("change", tusId);
    },

    uploadToTus(dataUrl, filename) {
      if (!dataUrl) {
        this.$emit("change", null);
      }

      const file = this.dataURLtoFile(dataUrl, filename);
      this.$refs.tusInputRef.upload(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-action {
  background-color: #fff;
}

.btn.btn-white.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
}
</style>
