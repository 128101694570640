const quickActions = {
  namespaced: true,

  state: {
    loading: false,
    activeComponent: null,
    props: null,
    events: null,
    ref: null,
  },

  mutations: {
    setComponent(state, { component, props = null, events = null }) {
      if(!component) {
        state.activeComponent = null
        return
      }
      state.activeComponent = component;
      state.props = props;
      state.events = events;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    reset: () => {}
  },

  actions: {
    setComponent({ commit }, val) {
      commit("setComponent", val);
      commit("setLoading", false);
    },
  },
};

export default quickActions;
