<template>
  <div :class="{ 'form-group row': parentClass }">
    <label v-if="label" class="col-form-label" :class="{ 'col-xl-3 col-lg-3': horizontal, 'col-12': !horizontal }">{{
      label }}</label>
    <div :class="{ 'col-lg-9 col-xl-6': horizontal, 'col-12': !horizontal }">
      <vue-tel-input v-if="showTelInput" ref="telInput" v-model="vModel" :auto-default-country="false"
        :default-country="defaultCountry" :dropdown-options="dropDownOptions" mode="international"
        class="form-control form-control-solid d-flex"></vue-tel-input>
      <div v-if="!valid" class="invalid-feedback d-block" v-text="invalidFeedback"></div>
      <span v-if="hint" class="form-text text-muted">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
import { BFormMixin } from "@/core/mixins";
export default {
  mixins: [BFormMixin],

  props: {
    value: {
      type: String,
      default: "",
    },
    defaultCountry: {
      type: String,
      default: "it",
    },
    parentClass: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showTelInput: true,
      dropDownOptions: {
        disabled: false,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showSearchBox: true,
        showFlags: true,
        tabindex: 0,
        width: "390px",
      },
    };
  },

  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    defaultCountry() {
      this.showTelInput = false;
      this.$nextTick(() => {
        this.showTelInput = true;
      });
    },
  },
  
};
</script>
