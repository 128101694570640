import i18n from "@/core/plugins/vue-i18n";
import NotificationService from "@/core/services/notification/notification.service";
import NotificationsActivityFeedService from "@/core/services/notification/notifications-activity-feed.service";

async function getNotifications({ perPage, page, sortBy, sortDesc, search, omit }) {
  return await NotificationService.getAll({ perPage, page, sortBy, sortDesc, search, omit });
}

async function getActivityFeedNotifications({ perPage, page, sortBy, sortDesc, search, omit }) {
  return await NotificationsActivityFeedService.getAll({ perPage, page, sortBy, sortDesc, search, omit });
}

async function markNotificationAsRead(notificationId) {
  try {
    return await NotificationService.update({ unread: false }, notificationId);
  } catch (err) {
    throw err?.response?.data?.detail ?? i18n.t("NOTIFICATIONS.MARK_AS_READ_UPDATE_FAIL");
  }
}

async function getNotificationLevels() {
  try {
    const options = await NotificationService.options();
    const choices = options?.data?.actions?.POST?.level?.choices;
    return choices.reduce((acc, curr) => {
      acc[curr.value] = {
        text: curr.display_name,
        variant: curr.value == "error" ? "danger" : curr.value,
      };
      return acc;
    }, {});
  } catch (err) {
    throw err?.response?.data?.detail;
  }
}

export { getNotifications, markNotificationAsRead, getNotificationLevels, getActivityFeedNotifications };
