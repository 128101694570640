<template>
  <div>
    <euro-file-input
      :accept="accept"
      :label="label"
      :error-messages="errorMessages"
      @change="croppie"
    ></euro-file-input>
    <v-dialog v-model="dialog" persistent :width="dialogWidth">
      <v-card>
        <vue-croppie
          ref="croppieRef"
          :enable-orientation="false"
          :enable-resize="false"
          :enable-exif="true"
          :boundary="{
            width: 450,
            height: 450,
          }"
          :viewport="{
            width: 240,
            height: 240,
            type: 'square',
          }"
        ></vue-croppie>
        <v-btn color="primary" block @click="crop">Crop</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    val: {
      type: [Object],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    dialogWidth: {
      type: [String, Number],
      default: 450,
    },
    accept: {
      type: String,
      default: "",
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      cropped: null,
    };
  },

  watch: {
    cropped: function() {
      if (this.cropped) {
        this.$emit("input", this.dataURLtoFile(this.cropped, "file.png"));
        return;
      }
      this.$emit("input", null);
    },
  },

  methods: {
    croppie: async function(file) {
      this.file = file;
      if (!file) {
        this.cropped = null;
        this.$emit("input", null);
        this.dialog = false;
        return;
      }

      this.dialog = true;

      // Give some time for the vuetify dialog to open...
      setTimeout(() => {
        this.readFile(file).then(this.bindCroppier);
      }, 300);
    },

    crop() {
      this.$refs.croppieRef.result({}, output => {
        this.cropped = output;
        this.dialog = false;
      });
    },

    bindCroppier(source) {
      this.$refs.croppieRef.bind({
        url: source,
      });
    },

    readFile(file) {
      return new Promise(resolve => {
        var reader = new FileReader();
        reader.onload = e => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>
